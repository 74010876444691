/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme, Container } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { useLazyGetAddressTypeQuery } from "@api/catalogs";
import { usePostAddressMutation, useLazyGetAddressQuery } from "@api/address";
import { useLazyGetByCpQuery } from "@api/postalCodes";
import { InactivityHandler } from "@components/InactivityHandler";
import { usePostDocumentMutation } from "@api/documents";
import { UUID } from "crypto";
import { useLazyGetFilesCatalogQuery } from "@api/catalogs";
import { useLazyGetDocumentAppIdQuery } from "@api/documents";
import { ADDRESS_CODES, DOCUMENTS_CATALOG, TAX_SYSTEM_TYPE } from "@helpers/constantes";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { AddressData, ApiError, DocumentResponse } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import IconDoor from "@components/Images/iconDoor";
import { Address, CatalogFile } from "@interfaces/catalogs";
import { capitalizeWords } from "@helpers/funciones";
import DocumentsForOcularVisit from "@components/DocumentsForOcularVisit";

interface Colonia {
  id: number;
  postal_code: string;
  name: string;
}

const AgendaVisita: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const applicationData = useSelector((state: RootState) => state.register.application);
  const [triggerPostDocument] = usePostDocumentMutation();
  const [getFilesCatalog] = useLazyGetFilesCatalogQuery();
  const [createAddress] = usePostAddressMutation();
  const [getByCp] = useLazyGetByCpQuery();

  const [fullAddress, setFullAddress] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addressTypeOperative, setAddressTypeOperative] = useState<Address | null>(null);
  const [addressTypeConfirmed, setAddressTypeConfirmed] = useState<Address | null>(null);
  const [addressOperative, setAddressOperative] = useState<AddressData | null>(null);
  const [addressDocumentOperative, setAddressDocumentOperative] = useState<DocumentResponse | null>(null);
  const [fileTypeConfirmed, setFileTypeConfirmed] = useState<CatalogFile | null>(null);

  const [getDocumentsByAppId] = useLazyGetDocumentAppIdQuery();
  const [getAddress] = useLazyGetAddressQuery();
  const [getAddressType] = useLazyGetAddressTypeQuery();

  const handleClickContinue = async () => {
    try {
      setIsLoading(true);

      if (!addressTypeOperative || !addressTypeConfirmed || !addressDocumentOperative || !addressOperative) {
        throw new Error("No se encontraron los datos requeridos.");
      }

      const rawAddressData = await getAddress({
        accountId: applicationData.client_details_id,
      }).unwrap();

      // buscar la direccion confirmada
      const address_confirmed = rawAddressData?.find((item) => item.address_type_id === addressTypeConfirmed?.id);

      if (!address_confirmed) {
        // en caso de que no exista la dirección confirmada se crea una nueva dirección con los datos de la dirección operativa
        const data_address = {
          street: addressOperative.street,
          no_ext: addressOperative.no_ext,
          no_int: addressOperative.no_int,
          colonia_id: addressOperative.colonia_id,
          state_id: addressOperative.state_id,
          city_id: addressOperative.city_id,
          cp: addressOperative.cp,
          client_details_id: addressOperative?.client_details_id,
          country_id: 1,
          address_type_id: addressTypeConfirmed?.id || "",
        };
        await createAddress(data_address).unwrap();

        // crear el documento de comprobante de domicilio apartir de la dirección operativa
        const data_document = {
          loan_application_id: applicationData.id,
          file_category_id: fileTypeConfirmed?.id as UUID,
          client_details_id: applicationData.client_details_id as UUID,
          url: addressDocumentOperative.url,
          file_name: addressDocumentOperative.file_name,
        };
        await triggerPostDocument(data_document).unwrap();
      }

      // navigate("/creditosimple/confirmacion_domicilio");
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickModifyAddress = () => {
    if (applicationData.tax_system_type === TAX_SYSTEM_TYPE.PFAE.code) {
      navigate("/producto/creditosimple/agenda_visita_pfae");
    } else {
      navigate("/producto/creditosimple/agenda_visita_pm");
    }
  };

  const generateFullAddress = async (address: AddressData) => {
    const { street, no_ext, no_int, colonia_id, cp, other_neighborhood } = address;
    const cp_info = await getByCp(cp).unwrap();
    if (!cp_info) {
      throw new Error("No se encontro el codigo postal");
    }
    const colony =
      colonia_id === -1
        ? other_neighborhood
        : cp_info.colonias.find((colonia: Colonia) => colonia.id === colonia_id)?.name || "Desconocida";
    const city = cp_info.cities[0]?.name || "";
    const state = cp_info.states[0]?.name || "";
    return `${street} No. ${no_ext} ${no_int ? `No Int. ${no_int}` : ""}, Col. ${colony}, ${city}, ${cp}, ${state}`;
  };

  const fetchAddress = async () => {
    try {
      setIsLoading(true);
      const [addressTypes, rawAddressData, fileTypes, documents] = await Promise.all([
        getAddressType().unwrap(),
        getAddress({
          accountId: applicationData.client_details_id,
        }).unwrap(),
        getFilesCatalog().unwrap(),
        getDocumentsByAppId({
          loanApplicationId: applicationData.id,
          status: true,
        }).unwrap(),
      ]);

      const { operativo, operativo_confirmado } = ADDRESS_CODES;
      const { comprobante_domicilio, comprobante_domicilio_confirmado } = DOCUMENTS_CATALOG;

      const address_type_operative = addressTypes?.find((item) => item.code === operativo.code);
      const address_type_confirmed = addressTypes?.find((item) => item.code === operativo_confirmado.code);

      if (!address_type_operative || !address_type_confirmed) {
        throw new Error("No se encontraron los tipos de dirección requeridos.");
      }

      // buscar la dirección operativa, en caso de que no exista la dirección operativa mandar un error
      const address_operative = rawAddressData?.find((item) => item.address_type_id === address_type_operative?.id);
      const address_confirmed = rawAddressData?.find((item) => item.address_type_id === address_type_confirmed?.id);
      if (!address_operative) {
        throw new Error("No se encontraron las direcciones requeridas.");
      }

      if (address_confirmed) {
        navigate("/producto/creditosimple/confirmacion_domicilio");
        return;
      }

      // buscar el documento de comprobante de domicilio, en caso de que no exista mandar un error
      const address_document_operative = documents.find((file) => file.file_code === comprobante_domicilio.code);
      if (!address_document_operative) {
        throw new Error("No se encontro el documento de comprobante de domicilio");
      }

      // buscar el tipo de documento de comprobante de domicilio confirmado
      const document_type_confirmed = fileTypes.find((item) => item.code === comprobante_domicilio_confirmado.code);
      if (!document_type_confirmed) {
        throw new Error("No se encontro el tipo de documento de comprobante de domicilio confirmado");
      }

      const full_address = await generateFullAddress(address_confirmed ? address_confirmed : address_operative);

      setFullAddress(capitalizeWords(full_address));
      setAddressTypeConfirmed(address_type_confirmed);
      setAddressTypeOperative(address_type_operative);
      setAddressOperative(address_operative);
      setAddressDocumentOperative(address_document_operative);
      setFileTypeConfirmed(document_type_confirmed);
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  return (
    <Grid container sx={{ height: "calc(100vh - 200px)" }}>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Container maxWidth="lg">
          <Typography
            mt={5}
            variant={isMobileOrTablet ? "h5" : "h4"}
            fontWeight={700}
            color={theme?.palette?.primary?.dark}
            textAlign="center"
          >
            Agenda tu visita ocular
          </Typography>

          <NavBarOcularVisit currentStep={1} />

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={400}
            color={theme?.palette?.primary?.main}
            textAlign="center"
          >
            Bienvenido a los últimos pasos para obtener tu crédito, a continuación realizaremos una visita ocular y
            cotejo de documentos
          </Typography>

          <Box mt={4} display="flex" justifyContent="center">
            <IconDoor width={isMobileOrTablet ? "100" : "150"} />
          </Box>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            fontWeight={600}
            textAlign="center"
          >
            Tu ejecutivo se pondrá en contacto para agendar la visita a tu domicilio operativo
          </Typography>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
            textAlign="center"
          >
            {fullAddress}
          </Typography>

          <Box mt={4} display="flex" justifyContent="center">
            <Button variant="blue_outlined" size="small" onClick={handleClickModifyAddress}>
              Modifica tu domicilio
            </Button>
          </Box>

          <Box my={3}>
            <DocumentsForOcularVisit />
          </Box>

          <Box mt={4} display="flex" justifyContent="center">
            <Button
              variant="contained"
              onClick={handleClickContinue}
              sx={{ width: isMobileOrTablet ? "100%" : "250px" }}
              disabled={isLoading}
            >
              Continuar
            </Button>
          </Box>
        </Container>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
            <CustomRightImage imageName="img_bofu_1" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default AgendaVisita;
