/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useLazyGetScheduleByApplicationIdQuery, useLazyGetLoanApplicationByIdQuery } from "@api/applications";
import { InactivityHandler } from "@components/InactivityHandler";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import CustomRightImage from "@components/CustomRightImage";
import useInterval from "@hooks/useInterval";
import { setLoanStatus } from "@store/slices/registerSlice";
import { LoanStatus } from "@interfaces/catalogs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import DocumentsForOcularVisit from "@components/DocumentsForOcularVisit";

dayjs.extend(utc);
dayjs.extend(timezone);

const SECONDS_TO_FETCH = 120;

const ConfirmacionVisitaAgendada = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const application = useSelector((state: RootState) => state.register.application);
  const loanStatus = useSelector((state: RootState) => state.register.loan_status);

  const [getLoanApplication] = useLazyGetLoanApplicationByIdQuery();
  const [getSchedule] = useLazyGetScheduleByApplicationIdQuery();

  const [visitDate, setVisitDate] = useState<string | null>("03 de abril");
  const [visitTime, setVisitTime] = useState<string | null>("18:00 PM");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getScheduleData = async () => {
    try {
      setIsLoading(true);
      setVisitDate("");
      setVisitTime("");
      const schedule = await getSchedule(application.id).unwrap();
      const utc_date = dayjs.utc(schedule.date);
      const only_date = utc_date.format("DD [de] MMMM");
      const only_time = utc_date.format("HH:mm A");
      setVisitDate(only_date);
      setVisitTime(only_time);
    } catch (error) {
      console.log("Ha ocurrido un error al obtener la programación de la visita ocular", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loanInterval = useInterval(async () => {
    try {
      const loan_application = await getLoanApplication(application.id).unwrap();
      if (loan_application?.status_catalog && loan_application?.status_catalog?.code !== loanStatus) {
        dispatch(setLoanStatus(loan_application.status_catalog.code as LoanStatus));
        navigate(loan_application.status_catalog.url_actual);
      }
    } catch (error) {
      console.error("Ha ocurrido un error al obtener la aplicación");
    }
  }, SECONDS_TO_FETCH);

  useEffect(() => {
    getScheduleData();
    loanInterval.setIsActiveInterval(true);

    return () => {
      loanInterval.setIsActiveInterval(false);
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Container maxWidth="lg">
          <Typography
            mt={5}
            textAlign={"center"}
            variant={isMobileOrTablet ? "h5" : "h4"}
            color={theme?.palette?.primary?.dark}
            fontWeight={700}
          >
            Visita agendada
          </Typography>

          <NavBarOcularVisit currentStep={2} />

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            Te visitaremos el día
          </Typography>

          <Box
            sx={{
              mt: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background: theme?.palette?.primary?.dark,
                borderRadius: "10px",
                width: isMobileOrTablet ? "165px" : "200px",
                height: isMobileOrTablet ? "65px" : "75px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                fontWeight={700}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.primary?.light}
              >
                {isLoading ? "Cargando..." : visitDate}
              </Typography>
              <Typography
                align="center"
                fontWeight={700}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.primary?.light}
              >
                {isLoading ? "" : visitTime}
              </Typography>
            </Box>
          </Box>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            color={theme?.palette?.primary?.dark}
            textAlign="center"
          >
            Recibirás un recordatorio vía WhatsApp una hora antes de tu visita y cuando el ejecutivo de visitas se
            encuentre en el domicilio
          </Typography>

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            En caso de que necesites reagendar tu vista, contacta a tu asesor
          </Typography>

          <Box my={3}>
            <DocumentsForOcularVisit />
          </Box>
        </Container>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
            <CustomRightImage imageName="img_bofu_5" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ConfirmacionVisitaAgendada;
