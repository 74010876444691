import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomRightImage from "@components/CustomRightImage";
import IconPresent from "@components/Images/IconPresent";

const BofuCompletado = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const personalData = useSelector((state: RootState) => state.register.personal_data);

  return (
    <Grid container height="100%">
      <Grid item xs={12} md={8} sx={{ backgroundColor: theme?.palette?.primary?.dark }}>
        <InactivityHandler />
        <Box mx={4}>
          <Typography
            mt={10}
            textAlign={"center"}
            variant={isMobileOrTablet ? "h4" : "h3"}
            color={"#FFFFFF"}
            fontWeight={700}
          >
            ¡Felicidades!
          </Typography>

          <Typography
            mt={2}
            textAlign={"center"}
            variant={isMobileOrTablet ? "h4" : "h3"}
            color="#FFFFFF"
            fontWeight={700}
          >
            {personalData.name} {personalData.first_lastname} {personalData.second_lastname}
          </Typography>

          <Box mt={6} display="flex" justifyContent="center">
            <IconPresent fill={theme.palette.primary.main} />
          </Box>

          <Typography mt={6} textAlign={"center"} variant={isMobileOrTablet ? "body2" : "body1"} color="#FFFFFF">
            Una vez que hayamos revisado que todo este correcto te depositaremos el dinero a tu cuenta, esto puede
            tardar hasta 24 horas
          </Typography>

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={700}
            color="#FFFFFF"
          >
            Nos pondremos en contacto para validar que se realizo tu deposito.
          </Typography>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: theme?.palette?.primary?.light,
            }}
          >
            <CustomRightImage imageName="img_bofu_7" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default BofuCompletado;
