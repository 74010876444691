/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PHONE_NUMBER } from "@utils/expreciones";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { useEffect, useState } from "react";
import { useLazyGetCivilStatusQuery, useLazyGetMatrimonialRegimesQuery } from "@api/catalogs";
import { CivilStatus, MatrimonialRegimesData, PayloadPersonPatch, PersonaStructure } from "@interfaces/index";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useLazyGetPersonsQuery, usePatchPersonMutation } from "@api/personasApi";
import { useNavigate } from "react-router-dom";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";

const InformacionAval = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const applicationData = useSelector((state: RootState) => state.register.application);
  const personAval = useSelector((state: RootState) => state.bofu.aval);

  const [getEstadoCivil] = useLazyGetCivilStatusQuery();
  const [getEstadoMatrimonial] = useLazyGetMatrimonialRegimesQuery();
  const [patchPersonData] = usePatchPersonMutation();
  const [getPersons] = useLazyGetPersonsQuery();

  const [listEdoCivil, setListEdoCivil] = useState<CivilStatus[]>([]);
  const [listMatrimonialRegimen, setListMatrimonialRegimen] = useState<MatrimonialRegimesData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [idEdoCivilCasado, setIdEdoCivilCasado] = useState<string>("");
  const [avalSelected, setAvalSelected] = useState<PersonaStructure | null>(null);

  const formik = useFormik({
    initialValues: {
      ocupacion: "",
      country_code: "+52",
      phone: "",
      estado_civil_id: "",
      matrimonial_regimes_id: "",
    },
    validationSchema: yup.object().shape({
      country_code: yup.string().required("Campo requerido"),
      phone: yup.string().required("Campo requerido").matches(PHONE_NUMBER, "Número de teléfono inválido"),
      ocupacion: yup.string().required("Campo requerido"),
      estado_civil_id: yup.string().required("Campo requerido"),
      matrimonial_regimes_id: yup.string().when("estado_civil_id", {
        is: idEdoCivilCasado,
        then: (schema) => schema.required("Campo requerido"),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!avalSelected) {
          return;
        }

        setSubmitting(true);

        const payload: PayloadPersonPatch = {
          occupation: values.ocupacion,
          code_phone_number: values.country_code,
          phone_number: values.phone,
          civil_status_id: values.estado_civil_id,
          matrimonial_regimes_id: values.estado_civil_id === idEdoCivilCasado ? values.matrimonial_regimes_id : null,
        };

        await patchPersonData({
          personId: avalSelected.id,
          body: payload,
        }).unwrap();

        navigate("/producto/creditosimple/informacion_datos_aval_2");
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setSubmitting(false);
      }
    },
    validateOnMount: true,
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        if (!personAval) {
          navigate("/producto/creditosimple/docs_aval");
          return;
        }

        const [res_avales, res_edo_civil, res_edo_matrimonial] = await Promise.all([
          getPersons({
            loanApplicationId: applicationData.id,
            id: personAval?.id,
          }).unwrap(),
          getEstadoCivil().unwrap(),
          getEstadoMatrimonial().unwrap(),
        ]);

        const aval_selected = res_avales[0];
        if (!aval_selected) {
          navigate("/producto/creditosimple/docs_aval");
          return;
        }

        const edo_civil_selected: CivilStatus | undefined = res_edo_civil.find(
          (item) => item.id === aval_selected.civil_status_id,
        );
        const edo_civil_casado: CivilStatus | undefined = res_edo_civil.find(
          (item) => item.name.toLowerCase() === "casado",
        );
        const edo_matrimonial_selected = res_edo_matrimonial.find(
          (item) => item.id === aval_selected.matrimonial_regimes_id,
        );

        setListEdoCivil(res_edo_civil);
        setListMatrimonialRegimen(res_edo_matrimonial);
        setIdEdoCivilCasado(edo_civil_casado?.id || "NOT_VALID");
        setAvalSelected(aval_selected);

        formik.setFieldValue("country_code", aval_selected.code_phone_number || "+52");
        formik.setFieldValue("phone", aval_selected.phone_number || "");
        formik.setFieldValue("ocupacion", aval_selected.occupation || "");
        formik.setFieldValue("estado_civil_id", edo_civil_selected?.id || "");
        formik.setFieldValue("matrimonial_regimes_id", edo_matrimonial_selected?.id || "");

        setTimeout(() => {
          formik.validateForm();
        }, 200);
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box maxWidth="sm" mx="auto" mt={4} px={2}>
          <Typography
            textAlign="center"
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={700}
            color={theme?.palette?.primary?.dark}
          >
            Información del aval
          </Typography>

          <Typography
            mt={2}
            textAlign="center"
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
          >
            <strong>1</strong> de 2
          </Typography>

          <Typography
            mt={2}
            textAlign="center"
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            {avalSelected?.name} {avalSelected?.last_name} {avalSelected?.last_name_2}
          </Typography>

          <form onSubmit={formik.handleSubmit} noValidate>
            <Grid container spacing={1} mt={1}>
              <Grid item xs={4} sm={4} md={4}>
                <CustomContryCode
                  label="Código"
                  name="country_code"
                  value={formik.values.country_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                  disabled={isLoading || formik.isSubmitting}
                />
              </Grid>

              <Grid item xs={8} sm={8} md={8}>
                <TextField
                  type="text"
                  name="phone"
                  variant="outlined"
                  label="Teléfono"
                  fullWidth
                  required
                  value={formik.values.phone}
                  onChange={(event) => {
                    formik.setFieldValue("phone", event.target.value.replace(/[^0-9]/g, ""));
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && Boolean(formik.errors.phone)}
                  helperText={formik.touched.phone && formik.errors.phone}
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric",
                  }}
                  disabled={isLoading || formik.isSubmitting}
                />
              </Grid>
            </Grid>

            <TextField
              id="ocupacion"
              name="ocupacion"
              label="Ocupación"
              margin="normal"
              required
              fullWidth
              autoComplete="off"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.ocupacion && Boolean(formik.errors.ocupacion)}
              helperText={formik.touched.ocupacion && formik.errors.ocupacion}
              value={formik.values.ocupacion}
              disabled={isLoading || formik.isSubmitting}
            />

            <FormControl
              fullWidth
              required
              sx={{
                ...customStylesAsterisk,
                mt: 2,
              }}
              error={formik.touched.estado_civil_id && Boolean(formik.errors.estado_civil_id)}
            >
              <InputLabel id="estado_civil_id">Estado civil</InputLabel>
              <Select
                labelId="estado_civil_id"
                id="estado_civil_id"
                name="estado_civil_id"
                fullWidth
                required
                label="Estado civil"
                value={formik.values.estado_civil_id}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.estado_civil_id && Boolean(formik.errors.estado_civil_id)}
                disabled={isLoading || formik.isSubmitting}
              >
                <MenuItem value={""}>Selecciona</MenuItem>
                {listEdoCivil.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.estado_civil_id && formik.errors.estado_civil_id && (
                <FormHelperText>{formik.errors.estado_civil_id}</FormHelperText>
              )}
            </FormControl>

            {formik.values.estado_civil_id !== "" && formik.values.estado_civil_id === idEdoCivilCasado && (
              <FormControl
                fullWidth
                required
                sx={{
                  ...customStylesAsterisk,
                  mt: 2,
                }}
                error={formik.touched.matrimonial_regimes_id && Boolean(formik.errors.matrimonial_regimes_id)}
              >
                <InputLabel id="matrimonial_regimes_id">Régimen matrimonial</InputLabel>
                <Select
                  labelId="matrimonial_regimes_id"
                  id="matrimonial_regimes_id"
                  name="matrimonial_regimes_id"
                  fullWidth
                  required
                  label="Régimen matrimonial *"
                  value={formik.values.matrimonial_regimes_id}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.matrimonial_regimes_id && Boolean(formik.errors.matrimonial_regimes_id)}
                  disabled={isLoading}
                >
                  <MenuItem value={""}>Selecciona</MenuItem>
                  {listMatrimonialRegimen.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.matrimonial_regimes_id && formik.errors.matrimonial_regimes_id && (
                  <FormHelperText>{formik.errors.matrimonial_regimes_id}</FormHelperText>
                )}
              </FormControl>
            )}

            <Grid container spacing={1} my={1}>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  variant="blue_outlined"
                  type="button"
                  fullWidth
                  onClick={() => {
                    navigate("/producto/creditosimple/docs_aval");
                  }}
                  disabled={isLoading || formik.isSubmitting}
                  sx={{ padding: "0px", height: "100%" }}
                >
                  Regresar al inicio
                </Button>
              </Grid>

              <Grid item xs={6} sm={6} md={6}>
                <Button variant="default" type="submit" fullWidth disabled={!formik.isValid || formik.isSubmitting}>
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_17" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default InformacionAval;
