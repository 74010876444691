import React, { useEffect } from "react";
import { Box } from "@mui/material";
import useConfigName from "@hooks/useConfigName";

type ImagesTofu =
  | "img_tofu_1"
  | "img_tofu_2"
  | "img_tofu_3"
  | "img_tofu_4"
  | "img_tofu_5"
  | "img_tofu_6"
  | "img_tofu_7"
  | "img_tofu_8"
  | "img_tofu_9"
  | "img_tofu_10"
  | "img_tofu_11"
  | "img_tofu_12"
  | "img_tofu_14";
type ImagesBofu =
  | "img_bofu_1"
  | "img_bofu_2"
  | "img_bofu_3"
  | "img_bofu_4"
  | "img_bofu_5"
  | "img_bofu_6"
  | "img_bofu_7"
  | "img_bofu_8"
  | "img_bofu_9"
  | "img_bofu_10"
  | "img_bofu_11"
  | "img_bofu_12"
  | "img_bofu_14"
  | "img_bofu_15"
  | "img_bofu_17"
  | "img_bofu_18";

interface CustomRemoteImageProps {
  imageName: ImagesTofu | ImagesBofu;
}

const CustomRemoteImage: React.FC<CustomRemoteImageProps> = ({ imageName }) => {
  const config_name = useConfigName();
  const [imageError, setImageError] = React.useState(false);
  const [imageSrc, setImageSrc] = React.useState("");

  useEffect(() => {
    if (!config_name) return;
    const base_url = import.meta.env.VITE_URL_STATIC_RESOURCES;
    const new_image_src = `${base_url}/${config_name}/portal/img/tofu_bofu/${imageName}.svg`;
    setImageSrc(new_image_src);
  }, [config_name]);

  return (
    <div id="div_imagen">
      {imageSrc && !imageError ? (
        <img
          id={`img_right_${imageName}`}
          alt={imageName}
          style={{
            height: "calc(100vh - 78px)",
            margin: "auto",
            width: "100%",
          }}
          src={imageSrc}
          onError={() => {
            setImageError(true);
          }}
        />
      ) : (
        <Box sx={{ height: "calc(100vh - 72px)", margin: "auto", width: "100%" }}></Box>
      )}
    </div>
  );
};

export default CustomRemoteImage;
