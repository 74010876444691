/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TabStepBofuPm } from "@components/index";
import { LoanConditions } from "@components/LoanConditions";
import { useDispatch, useSelector } from "react-redux";
import { setModalQuotationVisible } from "@store/slices/appSlice";
import { RootState } from "@store/store";
import { InactivityHandler } from "@components/InactivityHandler";
import useInterval from "@hooks/useInterval";
import { STATUS_CATALOG } from "@helpers/constantes";
import { useLazyGetLoanApplicationByIdQuery } from "@api/applications";
import CustomRightImage from "@components/CustomRightImage";
import { setLoanStatus } from "@store/slices/registerSlice";

const SECONDS_TO_FETCH = 10;

const ValidandoExpediente: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const application = useSelector((state: RootState) => state.register.application);
  const [getLoanApplication] = useLazyGetLoanApplicationByIdQuery();

  const loanInterval = useInterval(async () => {
    try {
      const loan_response = await getLoanApplication(application.id).unwrap();
      const { expediente_digital_validado, errores_expediente_pm } = STATUS_CATALOG;

      if (loan_response?.status_catalog?.code === expediente_digital_validado.code) {
        dispatch(setLoanStatus(expediente_digital_validado.code));
        navigate(expediente_digital_validado.url);
      } else if (loan_response?.status_catalog?.code === errores_expediente_pm.code) {
        dispatch(setLoanStatus(errores_expediente_pm.code));
        navigate(errores_expediente_pm.url);
      }
    } catch (error) {
      console.error("Ha ocurrido un error al obtener la aplicación");
    }
  }, SECONDS_TO_FETCH);

  const handleOnClickShowQuotation = () => {
    dispatch(setModalQuotationVisible(true));
  };

  useEffect(() => {
    loanInterval.setIsActiveInterval(true);

    return () => {
      loanInterval.setIsActiveInterval(false);
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={2}>
        <Box textAlign={"center"} mx={4}>
          <InactivityHandler />
          <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
            Expediente en revisión
          </Typography>

          <TabStepBofuPm />

          <Typography fontWeight={400} color={theme?.palette?.primary?.dark} mt={3} fontSize={"16px"}>
            Concluiste el llenado de tu expediente digital
          </Typography>

          <Typography fontWeight={300} color={theme?.palette?.primary?.main} mt={3} fontSize={"16px"}>
            ¡Estamos validando tu expediente, volveremos contigo a la brevedad!
          </Typography>

          <Typography fontWeight={600} color={theme?.palette?.primary?.dark} mt={3} fontSize={"16px"}>
            Revisa las condiciones del crédito que estas solicitando
          </Typography>

          <Box mt={3}>
            <LoanConditions />
          </Box>

          <Box sx={{ paddingTop: "50px" }}>
            <Button variant="blue_outlined" size="small" onClick={handleOnClickShowQuotation}>
              Modifica tu cotización
            </Button>
          </Box>

          <Box sx={{ paddingTop: "2em" }}>
            <Typography fontWeight={300} color={theme?.palette?.primary?.dark} mt={2} fontSize={"14px"}>
              <span>
                ¡Despreocúpate!
                <br /> Puedes salir de esta pantalla <br /> <br />
                Si existe la necesidad de modificar o adicionar algún documento o tan pronto el expediente se encuentre
                validado podrás consultarlo aquí mismo y te lo notificaremos vía WhatsApp
              </span>
            </Typography>
          </Box>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_2" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ValidandoExpediente;
