import { Suspense } from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";

import { Navbar } from "../components";
import { ModalErrors } from "@components/ModalErrors";
import { QuotationModal } from "@components/QuotationModal";
import { CustomUploadModalError } from "@components/CustomUploadModalError";

const MainLayout = () => {
  return (
    <>
      <Navbar />
      <CustomUploadModalError />
      <QuotationModal />
      <ModalErrors />
      <Box pt={9} height={"calc(100vh - 76px)"}>
        <Suspense fallback={<></>}>
          <Outlet />
        </Suspense>
      </Box>
    </>
  );
};

export default MainLayout;
