/* eslint-disable @typescript-eslint/no-explicit-any */
import { DocumentResponse, LoanApplicationCompany, PersonaStructure, RealOwnerWithDocs } from "@interfaces/index";
import { UploadedFile } from "@interfaces/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UUID } from "crypto";

export interface TransactionalInfo {
  payment_methods: Array<UUID>;
  details: {
    id: UUID | undefined | string;
    advance_payments: boolean | undefined;
    own_resources: boolean | undefined;
    pep_shareholder: boolean | undefined;
    pep_members: boolean | undefined;
  };
}

export interface BofuSlice {
  digital_file: {
    company: {
      files: Array<UploadedFile>;
      loan_application: {
        transactional_info: TransactionalInfo;
      };
    };
    current_owner_id: string | null;
  };
  isLoading: boolean;
  representante_legal: PersonaStructure | undefined;
  aval: PersonaStructure | undefined;
  propietarios_reales: Array<RealOwnerWithDocs>;
  section_dictamen?: {
    doc_identificacion: DocumentResponse | undefined;
    doc_acta_constitutiva: DocumentResponse | undefined;
    doc_actas_adicionales: Array<DocumentResponse | undefined>;
  };
  section_company?: {
    doc_validacion_identidad: DocumentResponse | undefined;
    doc_solicitud_credito: DocumentResponse | undefined;
    doc_comprobante_domicilio: DocumentResponse | undefined;
  };
  section_aval?: {
    doc_anexo_1: DocumentResponse | undefined;
    doc_validacion_identidad: DocumentResponse | undefined;
    doc_constancia_sf: DocumentResponse | undefined;
    doc_comprobante_domicilio: DocumentResponse | undefined;
  };
}

const initialState: BofuSlice = {
  digital_file: {
    company: {
      files: [],
      // TODO: check if this is the correct structure
      loan_application: {
        transactional_info: {
          payment_methods: [],
          details: {
            id: undefined,
            advance_payments: undefined,
            own_resources: undefined,
            pep_shareholder: undefined,
            pep_members: undefined,
          },
        },
      },
    },
    current_owner_id: null,
  },
  isLoading: false,
  representante_legal: undefined,
  aval: undefined,
  propietarios_reales: [],
  section_dictamen: {
    doc_identificacion: undefined,
    doc_acta_constitutiva: undefined,
    doc_actas_adicionales: [],
  },
  section_company: {
    doc_validacion_identidad: undefined,
    doc_solicitud_credito: undefined,
    doc_comprobante_domicilio: undefined,
  },
  section_aval: {
    doc_anexo_1: undefined,
    doc_validacion_identidad: undefined,
    doc_constancia_sf: undefined,
    doc_comprobante_domicilio: undefined,
  },
};

export const bofuSlice = createSlice({
  name: "bofu",
  initialState,
  reducers: {
    setCompanyDocument: (state, action: PayloadAction<UploadedFile>) => {
      if (!state.digital_file.company.files || !Array.isArray(state.digital_file.company.files)) {
        state.digital_file.company.files = [];
      }

      state.digital_file.company.files.push(action.payload);
    },
    removeCompanyDocument: (state, action: PayloadAction<UUID>) => {
      state.digital_file.company.files = state.digital_file.company.files.filter((docs) => docs.id !== action.payload);
    },
    restoreBofuCompanyData: (
      state,
      action: PayloadAction<{
        loan_application: LoanApplicationCompany;
      }>,
    ) => {
      if (action.payload.loan_application) {
        if (action.payload.loan_application.transactional_info) {
          state.digital_file.company.loan_application.transactional_info =
            action.payload.loan_application.transactional_info;
        }
      }
    },
    setCurrentOwnerId: (state, action: PayloadAction<string>) => {
      state.digital_file.current_owner_id = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setRepresentanteLegal: (state, action: PayloadAction<PersonaStructure>) => {
      state.representante_legal = action.payload;
    },
    setAval: (state, action: PayloadAction<PersonaStructure>) => {
      state.aval = action.payload;
    },
    setPropietariosReales: (state, action: PayloadAction<Array<RealOwnerWithDocs>>) => {
      state.propietarios_reales = action.payload;
    },
    setPropietarioRealDocument: (
      state,
      action: PayloadAction<{
        index: number;
        name: "constancia" | "identificacion";
        file: DocumentResponse | undefined;
      }>,
    ) => {
      const { index, name, file } = action.payload;
      state.propietarios_reales[index][name] = file;
    },
    setSectionDictamen: (state, action: PayloadAction<BofuSlice["section_dictamen"]>) => {
      state.section_dictamen = action.payload;
    },
    setSectionDictamenDocument(
      state,
      action: PayloadAction<{
        name: "doc_identificacion" | "doc_acta_constitutiva";
        file: DocumentResponse | undefined;
      }>,
    ) {
      if (state.section_dictamen) {
        state.section_dictamen[action.payload.name] = action.payload.file;
      }
    },
    setDocumentActasAdicionales: (state, action: PayloadAction<Array<DocumentResponse | undefined>>) => {
      if (state.section_dictamen) {
        state.section_dictamen.doc_actas_adicionales = action.payload;
      }
    },
    setSectionCompany: (state, action: PayloadAction<BofuSlice["section_company"]>) => {
      state.section_company = action.payload;
    },
    setSectionCompanyDocumentComprobanteDomicilio: (state, action: PayloadAction<DocumentResponse | undefined>) => {
      if (state.section_company) {
        state.section_company.doc_comprobante_domicilio = action.payload;
      }
    },
    setSectionAval: (state, action: PayloadAction<BofuSlice["section_aval"]>) => {
      state.section_aval = action.payload;
    },
    setSectionAvalDocument(
      state,
      action: PayloadAction<{
        name: "doc_constancia_sf" | "doc_comprobante_domicilio";
        file: DocumentResponse | undefined;
      }>,
    ) {
      if (state.section_aval) {
        state.section_aval[action.payload.name] = action.payload.file;
      }
    },
  },
});

export const {
  setCompanyDocument,
  removeCompanyDocument,
  restoreBofuCompanyData,
  setCurrentOwnerId,
  setIsLoading,
  setRepresentanteLegal,
  setAval,
  setPropietariosReales,
  setPropietarioRealDocument,
  setSectionDictamen,
  setSectionDictamenDocument,
  setSectionCompany,
  setSectionCompanyDocumentComprobanteDomicilio,
  setSectionAval,
  setSectionAvalDocument,
  setDocumentActasAdicionales,
} = bofuSlice.actions;
export default bofuSlice.reducer;
