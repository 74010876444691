/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { CustomUploadFile } from "@components/CustomUploadFile";
import { UploadedFile } from "@interfaces/store";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { UUID } from "crypto";
import { useGetApplicationByApplicationIdQuery } from "@api/applications";
import { useLazyGetDocumentAppIdQuery, usePostDocumentGenarateMutation } from "@api/documents";
import { removePfaeDocument, setPfaeCompleted, setPfaeDocument } from "@store/slices/bofuSlicePfae";
import { Spinner } from "@components/index";
import { usePostValidateNextStageMutation } from "@api/applications";
import { InactivityHandler } from "@components/InactivityHandler";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";

interface PropsButtoms {
  isFilled: boolean;
  buttonLabel: string;
  backgroundColor: string;
  color?: string;
}

const AvanceExpedienteDigitalPFAE: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const tofuSection = useSelector((state: RootState) => state.register);

  const pfaeBofu = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);

  const pfaeBofuFiles = pfaeBofu.files || [];

  const [validations, setValidations] = useState<yup.ObjectShape>({});

  const [indetidadCompleted, setIndetidadCompleted] = useState(null);
  const [bankCompleted, setBankCompleted] = useState(null);
  const [referencesCompleted, setReferencesCompleted] = useState(null);
  const [applicationCompleted, setApplicationCompleted] = useState(null);

  const [visible, setVisible] = useState(false);

  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [solicitudCreditoProps, setSolicitudCreditoProps] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.otherColors?.main,
  });
  const [referenciasProps, setReferenciasPropss] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.otherColors?.main,
  });
  const [bancariaProps, setBancariaProps] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.otherColors?.main,
  });
  const [indentidadProps, setIndentidadProps] = useState<PropsButtoms>({
    isFilled: false,
    buttonLabel: "Llenar",
    backgroundColor: theme?.palette?.primary?.main,
    color: theme?.palette?.otherColors?.main,
  });

  const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState(false);

  useEffect(() => {
    const preloadedFile = pfaeBofuFiles.find((uploadedFile) => uploadedFile.file_code === "DCD");
    if (preloadedFile) {
      setUploadedFile(preloadedFile);
    }
  }, [pfaeBofu]);

  useEffect(() => {
    const shouldEnableButton =
      solicitudCreditoProps.isFilled &&
      referenciasProps.isFilled &&
      bancariaProps.isFilled &&
      indentidadProps.isFilled &&
      uploadedFile !== null;

    setIsContinueButtonEnabled(shouldEnableButton);
  }, [solicitudCreditoProps, referenciasProps, bancariaProps, indentidadProps, uploadedFile]);

  const [documentValidate, setDocumentValidate] = useState<string | null>(null);

  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const {
    data: applicationData,
    isSuccess,
    isLoading: loadingData,
    isError: isErrorApplication,
    error: errorAplication,
  } = useGetApplicationByApplicationIdQuery(application.id);

  const [getDocumentByApplicationClient, { data: dataDocuments, isLoading: loadingDocumentData }] =
    useLazyGetDocumentAppIdQuery();

  const [createDocument, { isLoading: loadingDocument }] = usePostDocumentGenarateMutation();

  useEffect(() => {
    if (application.id) {
      try {
        getDocumentByApplicationClient({ loanApplicationId: application.id, clientDetailsId: clientDetails.id });
      } catch (error: any) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      }
    }
  }, [application]);

  const handleError = (error: any) => {
    dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
  };

  const handleApplicationError = () => {
    if (isErrorApplication) {
      handleError(errorAplication);
    }
  };

  useEffect(() => {
    handleApplicationError;
  }, [isErrorApplication]);

  useEffect(() => {
    if (applicationData) {
      setIndetidadCompleted(applicationData.data[0]?.identity_validation_completed);
      setBankCompleted(applicationData.data[0]?.bank_information_completed);
      setReferencesCompleted(applicationData.data[0]?.references_completed);
      setApplicationCompleted(applicationData.data[0]?.loan_application_completed);

      if (
        applicationData.data[0]?.identity_validation_completed &&
        applicationData.data[0]?.bank_information_completed &&
        applicationData.data[0]?.references_completed &&
        applicationData.data[0]?.loan_application_completed
      ) {
        formik.setFieldValue("isAllFilled", true);
        dispatch(setPfaeCompleted());
      }
    }
  }, [applicationData, isSuccess]);

  const registerInternalFormik = (name: string, validationSchema: yup.Schema) => {
    validations[name] = validationSchema;
    setValidations(validations);
  };

  const formik = useFormik({
    initialValues: {
      isAllFilled: false,
    },
    validationSchema: yup.object().shape(validations),
    onSubmit: async () => {
      //CSEDC
      try {
        await triggerPostNextStage({
          applicationId: application.id,
          statusCode: "CSEDC",
          username: `BOFU - ${accountUser.email}`,
        }).unwrap();
        navigate("/producto/creditosimple/validando_expediente_pfae");
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      }
    },
  });

  const handleUpdateStore = (action: any, _: number, uploadedFile: UploadedFile | null, fileInternalId: UUID) => {
    if (action === "ADD" && uploadedFile !== null) {
      dispatch(setPfaeDocument(uploadedFile));
      setUploadedFile(uploadedFile);
    } else if (action === "DEL") {
      dispatch(removePfaeDocument(fileInternalId));
      setUploadedFile(null);
    }
  };

  const getButtonProps = (status: boolean | any, document: string | any) => {
    if (document === "null") {
      if (status === null) {
        return {
          isFilled: false,
          buttonLabel: "Llenar",
          backgroundColor: theme?.palette?.primary?.main,
          color: theme?.palette?.otherColors?.main,
        };
      }

      if (!status) {
        return {
          isFilled: false,
          buttonLabel: "Editar",
          backgroundColor: "#F2704F",
          color: theme?.palette?.otherColors?.main,
        };
      }

      if (status) {
        return {
          isFilled: true,
          buttonLabel: "Completado",
          backgroundColor: theme?.palette?.primary?.dark,
          color: theme?.palette?.primary?.light,
        };
      }
    }

    if (document === "false") {
      return {
        isFilled: false,
        buttonLabel: "Editar",
        backgroundColor: "#F2704F",
        color: theme?.palette?.otherColors?.main,
      };
    }

    if (document === "true" && status) {
      return {
        isFilled: true,
        buttonLabel: "Validado",
        backgroundColor: theme?.palette?.primary?.light,
        color: theme?.palette?.primary?.main,
      };
    }

    return {
      isFilled: false,
      buttonLabel: "Llenar",
      backgroundColor: theme?.palette?.primary?.main,
      color: theme?.palette?.otherColors?.main,
    };
  };

  useEffect(() => {
    if (!dataDocuments) return;

    const filteredFiles = dataDocuments.filter((file) => file.file_name.includes("solicitud_credito"));
    const validatedFile = filteredFiles.find((item) => item.status);

    if (filteredFiles.length === 0 || validatedFile === null || validatedFile === undefined) {
      setDocumentValidate("null");
    } else {
      setDocumentValidate(`${validatedFile?.validated}`);
    }

    const allCompleted = applicationCompleted && referencesCompleted && bankCompleted;

    if (allCompleted) {
      if (filteredFiles.length === 0) {
        generateSolicitudDocument();
      }
    }
  }, [dataDocuments, applicationCompleted, referencesCompleted, bankCompleted]);

  useEffect(() => {
    if (!loadingData && !loadingDocumentData && isSuccess) {
      setSolicitudCreditoProps(getButtonProps(applicationCompleted, documentValidate));
      setReferenciasPropss(getButtonProps(referencesCompleted, documentValidate));
      setBancariaProps(getButtonProps(bankCompleted, documentValidate));
      setIndentidadProps(getButtonProps(indetidadCompleted, documentValidate));
    }
  }, [
    applicationCompleted,
    referencesCompleted,
    bankCompleted,
    indetidadCompleted,
    documentValidate,
    loadingData,
    loadingDocumentData,
    isSuccess,
  ]);

  useEffect(() => {
    if (!loadingData && !loadingDocumentData && isSuccess) {
      setVisible(true);
    }
  }, [loadingData, loadingDocumentData, isSuccess]);

  const generateSolicitudDocument = async () => {
    try {
      const data = {
        loan_application_id: String(application.id),
        document_type: "solicitud_credito",
      };

      const documents: any = await createDocument(data);

      if (documents?.error?.status === 500 || documents?.error?.status === 404) {
        throw new Error(JSON.stringify({ status: 500 }));
      }

      if (documents?.error?.status === 400) {
        throw new Error(JSON.stringify({ status: 400 }));
      }
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  return (
    <Grid container>
      <Spinner open={loadingDocument} />
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={4}>
          <Typography
            mt={4}
            mb={3}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            sx={{ color: theme?.palette?.primary?.dark }}
          >
            Expediente digital
          </Typography>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            sx={{ fontWeight: "bold", fontSize: "16px" }}
          ></Typography>
          <Typography
            mt={2}
            variant={isMobileOrTablet ? "body2" : "body1"}
            sx={{ color: theme?.palette?.primary?.main, fontSize: "16px", fontWeight: 300 }}
          >
            {tofuSection.company.legal_name ?? "Nombre del Cliente"}
          </Typography>

          {!visible ? (
            <>
              <Skeleton variant="text" animation="wave" width={"100%"} />
              <Skeleton variant="text" animation="wave" width={"100%"} />
              <Skeleton variant="text" animation="wave" width={"100%"} />
              <Skeleton variant="text" animation="wave" width={"100%"} />
            </>
          ) : (
            <Box>
              <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" sx={{ color: theme?.palette?.primary?.dark, flex: 1, textAlign: "left" }}>
                  Solicitud de crédito
                </Typography>
                <Button
                  variant="expediente"
                  size={isMobileOrTablet ? "small" : "medium"}
                  onClick={() => navigate("/producto/creditosimple/solicitud_credito_1PFAE")}
                  disabled={solicitudCreditoProps?.isFilled}
                  sx={{
                    backgroundColor: solicitudCreditoProps?.backgroundColor,
                    color: solicitudCreditoProps?.color,
                    "&:hover": {
                      backgroundColor: solicitudCreditoProps?.backgroundColor,
                    },
                    "&.Mui-disabled": {
                      backgroundColor: solicitudCreditoProps?.backgroundColor,
                      color: solicitudCreditoProps?.color,
                      opacity: 1,
                    },
                  }}
                >
                  {solicitudCreditoProps?.buttonLabel}
                </Button>
              </Box>

              <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" sx={{ color: theme?.palette?.primary?.dark, flex: 1, textAlign: "left" }}>
                  Referencias
                </Typography>
                <Button
                  variant="expediente"
                  size={isMobileOrTablet ? "small" : "medium"}
                  onClick={() => navigate("/producto/creditosimple/referencias_clientes_pfae")}
                  disabled={referenciasProps?.isFilled}
                  sx={{
                    backgroundColor: referenciasProps?.backgroundColor,
                    color: referenciasProps?.color,
                    "&:hover": {
                      backgroundColor: referenciasProps?.backgroundColor,
                    },
                    "&.Mui-disabled": {
                      backgroundColor: referenciasProps?.backgroundColor,
                      color: referenciasProps?.color,
                      opacity: 1,
                    },
                  }}
                >
                  {referenciasProps?.buttonLabel}
                </Button>
              </Box>

              <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" sx={{ color: theme?.palette?.primary?.dark, flex: 1, textAlign: "left" }}>
                  Información bancaria
                </Typography>
                <Button
                  variant="expediente"
                  size={isMobileOrTablet ? "small" : "medium"}
                  onClick={() => navigate("/producto/creditosimple/info_bancaria_pfae")}
                  disabled={bancariaProps?.isFilled}
                  sx={{
                    backgroundColor: bancariaProps?.backgroundColor,
                    color: bancariaProps?.color,
                    "&:hover": {
                      backgroundColor: bancariaProps?.backgroundColor,
                    },
                    "&.Mui-disabled": {
                      backgroundColor: bancariaProps?.backgroundColor,
                      color: bancariaProps?.color,
                      opacity: 1,
                    },
                  }}
                >
                  {bancariaProps?.buttonLabel}
                </Button>
              </Box>

              <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1" sx={{ color: theme?.palette?.primary?.dark, flex: 1, textAlign: "left" }}>
                  Validación de identidad
                </Typography>
                <Button
                  variant="expediente"
                  size={isMobileOrTablet ? "small" : "medium"}
                  onClick={() => navigate("/producto/creditosimple/validacion_identidad_pfae")}
                  disabled={indentidadProps?.isFilled}
                  sx={{
                    backgroundColor: indentidadProps?.backgroundColor,
                    color: indentidadProps?.color,
                    "&:hover": {
                      backgroundColor: indentidadProps?.backgroundColor,
                    },
                    "&.Mui-disabled": {
                      backgroundColor: indentidadProps?.backgroundColor,
                      color: indentidadProps?.color,
                      opacity: 1,
                    },
                  }}
                >
                  {indentidadProps?.buttonLabel}
                </Button>
              </Box>
            </Box>
          )}

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            sx={{ color: theme?.palette?.primary?.main }}
          >
            Para validar la identidad necesitaras tener la identificación a la mano y tomar un par de selfies
          </Typography>
          <Typography
            mt={2}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            sx={{ color: theme?.palette?.primary?.dark }}
          >
            Ahora carga el siguiente documento de tu negocio
          </Typography>
          <Box mt={2} textAlign="left">
            <form onSubmit={formik.handleSubmit} noValidate>
              {/* TODO: cambiar a v2 */}
              <CustomUploadFile
                py={2}
                updateStore={handleUpdateStore}
                optionalName="Comprobante de domicilio"
                uploadedFile={pfaeBofuFiles.find((uploadedFile) => uploadedFile.file_code === "DCD")}
                optionalDescription="No deberá ser mayor a 3 meses"
                setFieldValue={formik.setFieldValue}
                registerFormik={registerInternalFormik}
                errors={formik.errors}
                required
                metadata={{
                  taxCode: "",
                  fileCode: "DCD",
                  uploadType: "client",
                  loan_application_id: application.id,
                  regimen: tofuSection.application.type,
                  clientDetailsId: tofuSection.personal_data.id,
                }}
              />
              <Box mb={3} textAlign={"center"}>
                <Button variant="default" type="submit" disabled={!isContinueButtonEnabled}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={8} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_2" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default AvanceExpedienteDigitalPFAE;
