/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Button, Divider, Grid, Link, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { RootState, useAppDispatch } from "@store/store";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import { usePostValidateNextStageMutation } from "@api/applications";
import { TabStepBofuPm } from "@components/index";
import { InactivityHandler } from "@components/InactivityHandler";
import { usePatchDocumentMutation } from "@api/documents";
import { DOCUMENTS_CATALOG, STATUS_CATALOG } from "@helpers/constantes";
import { ModalExpediente } from "@components/CustomModalExpediente";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import { setSectionDictamenDocument, setDocumentActasAdicionales } from "@store/slices/bofuSlice";

const DocsEmpresa = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const application = useSelector((state: RootState) => state.register.application);
  const sectionDictamen = useSelector((state: RootState) => state.bofu.section_dictamen);
  const bufuLoading = useSelector((state: RootState) => state.bofu.isLoading);

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [triggerPatchDocument] = usePatchDocumentMutation();

  const [showModalCompanyNotAvailable, setShowModalCompanyNotAvailable] = useState(false);

  const handleOnClickAddActa = () => {
    if (application.decision_validated === true) return;

    const current_value = sectionDictamen?.doc_actas_adicionales || [];

    // validar que el último elemento no sea undefined
    if (!current_value[current_value.length - 1]) return;

    const copy = [...current_value];
    copy.push(undefined);

    dispatch(setDocumentActasAdicionales(copy));
  };

  const handleClickRemoveActa = async (index: number) => {
    try {
      if (application.decision_validated === true) return;

      const current_value = sectionDictamen?.doc_actas_adicionales || [];
      const item_to_remove = current_value[index];
      if (item_to_remove?.id) {
        if (item_to_remove.validated === true) return;
        await triggerPatchDocument({
          documentId: item_to_remove.id,
          body: { status: false },
        }).unwrap();
      }

      const copy = [...current_value.filter((_, i) => i !== index)];
      dispatch(setDocumentActasAdicionales(copy));
    } catch (error) {
      console.error("Ha ocurrido un error al eliminar el documento.");
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  const handleClickContinue = async () => {
    try {
      const { documentos_dictamen, dictamen_juridico, avance_expediente_digital } = STATUS_CATALOG;

      // validar que todos los documentos estén cargados
      if (!sectionDictamen?.doc_identificacion || !sectionDictamen?.doc_acta_constitutiva) {
        setShowModalCompanyNotAvailable(true);
        return;
      }

      // validar que el estatus de la solicitud sea documentos_dictamen
      if (application.status_catalog?.code === documentos_dictamen.code) {
        await triggerPostNextStage({
          applicationId: application.id,
          statusCode: dictamen_juridico.code,
          username: `BOFU - ${accountUser.email}`,
        }).unwrap();
      }

      navigate(avance_expediente_digital.url);
    } catch (error) {
      console.error("Ha ocurrido un error al continuar con la solicitud.");
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={2}>
        <Box textAlign={"center"} mx={4}>
          <InactivityHandler />
          <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
            Expediente digital
          </Typography>

          <TabStepBofuPm />

          <Typography
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={400}
            color={theme?.palette?.primary?.main}
            mt={4}
            mb={4}
          >
            Comenzaremos con el dictamen de tu empresa, para lo que necesitaremos los siguientes documentos
          </Typography>

          {bufuLoading ? (
            <Box mt={3}>
              <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
            </Box>
          ) : (
            <Box>
              <CustomUploadFileV2
                py={2}
                inputName="identificacion_representante"
                optionalDescription="Deberá contar con su registro público de la propiedad"
                uploadedFile={sectionDictamen?.doc_identificacion}
                setUploadedFile={(newValue) => {
                  dispatch(
                    setSectionDictamenDocument({
                      name: "doc_identificacion",
                      file: newValue,
                    }),
                  );
                }}
                metadata={{
                  fileCode: DOCUMENTS_CATALOG.identificacion_representante_legal.code,
                  uploadType: "client",
                  clientDetailsId: personalData.id,
                }}
                required
              />

              <CustomUploadFileV2
                py={2}
                inputName="acta_constitutiva"
                optionalDescription="Deberá contar con su registro público de la propiedad"
                uploadedFile={sectionDictamen?.doc_acta_constitutiva}
                setUploadedFile={(newValue) => {
                  dispatch(
                    setSectionDictamenDocument({
                      name: "doc_acta_constitutiva",
                      file: newValue,
                    }),
                  );
                }}
                metadata={{
                  fileCode: DOCUMENTS_CATALOG.acta_constitutiva.code,
                  uploadType: "client",
                  clientDetailsId: personalData.id,
                }}
                required
              />

              {sectionDictamen?.doc_actas_adicionales.map((actaAdicional, index) => (
                <Box key={index}>
                  <CustomUploadFileV2
                    mt={2}
                    key={actaAdicional?.id || index}
                    inputName={`acta_adicional_${index}`}
                    uploadedFile={actaAdicional}
                    setUploadedFile={(newValue) => {
                      if (newValue?.id) {
                        const current_value = sectionDictamen?.doc_actas_adicionales || [];
                        const copy = [...current_value];
                        copy[index] = newValue;
                        dispatch(setDocumentActasAdicionales(copy));
                      } else {
                        const current_value = sectionDictamen?.doc_actas_adicionales || [];
                        const copy = [...current_value.filter((_, i) => i !== index)];
                        dispatch(setDocumentActasAdicionales(copy));
                      }
                    }}
                    metadata={{
                      fileCode: DOCUMENTS_CATALOG.acta_adicional.code,
                      uploadType: "client",
                      clientDetailsId: personalData.id,
                    }}
                    hiddeDivider
                  />
                  <Box textAlign={"right"}>
                    {index !== 0 && (
                      <Link
                        onClick={() => handleClickRemoveActa(index)}
                        sx={{
                          cursor:
                            application.decision_validated === true ||
                            sectionDictamen?.doc_actas_adicionales[index]?.validated === true
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        <Typography
                          variant={isMobileOrTablet ? "body2" : "body1"}
                          color={theme?.palette?.primary?.main}
                        >
                          - Eliminar acta adicional
                        </Typography>
                      </Link>
                    )}
                  </Box>

                  <Divider sx={{ backgroundColor: "#A3D4E8", my: 2 }} />
                </Box>
              ))}

              <Box textAlign={"right"} mt={2}>
                <Link
                  onClick={handleOnClickAddActa}
                  sx={{
                    cursor:
                      application.decision_validated === true ||
                      !sectionDictamen?.doc_actas_adicionales[sectionDictamen?.doc_actas_adicionales.length - 1]
                        ? "not-allowed"
                        : "pointer",
                  }}
                >
                  <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={theme?.palette?.primary?.main}>
                    + Agregar acta adicional
                  </Typography>
                </Link>
              </Box>

              <Box mt={2}>
                <Button
                  onClick={handleClickContinue}
                  variant={
                    sectionDictamen?.doc_acta_constitutiva && sectionDictamen.doc_identificacion
                      ? "default"
                      : "default_disabled"
                  }
                  type="button"
                >
                  Continuar
                </Button>
              </Box>
            </Box>
          )}

          <ModalExpediente
            type="company_not_available"
            openModal={showModalCompanyNotAvailable}
            closeModal={() => setShowModalCompanyNotAvailable(false)}
          />
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_2" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DocsEmpresa;
