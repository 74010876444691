/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { InactivityHandler } from "@components/index";

import * as yup from "yup";
import { useFormik } from "formik";
import { usePostValidateNextStageMutation } from "@api/applications";
import { DocumentResponse } from "@interfaces/response";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import { DOCUMENTS_CATALOG, STATUS_CATALOG } from "@helpers/constantes";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";
import { useLazyGetDocumentAppIdQuery } from "@api/documents";
import CustomRightImage from "@components/CustomRightImage";

interface AnotherDocumentProps {
  id: string | undefined;
  financial_statements_id: string;
}

const ZonaGris = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const emptyAnotherDocument: AnotherDocumentProps = {
    id: "",
    financial_statements_id: "",
  };

  const applicationData = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const companySection = useSelector((state: RootState) => state.register.company);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [getDocumentsByAppId] = useLazyGetDocumentAppIdQuery();
  const [listDocuments, setListDocuments] = useState<DocumentResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      annual_closing_id: "",
      last_partial_closing_id: "",
      another_document: [],
    },
    validationSchema: yup.object().shape({
      annual_closing_id: yup.string().required("Campo requerido."),
      last_partial_closing_id: yup.string().required("Campo requerido."),

      another_document: yup.array().of(
        yup.object().shape({
          financial_statements_id: yup.string(),
        }),
      ),
    }),
    onSubmit: async (_, { setSubmitting }) => {
      try {
        setSubmitting(true);

        const next_status = STATUS_CATALOG.waiting_room_revision.code;
        await triggerPostNextStage({
          applicationId: applicationData.id,
          statusCode: next_status,
          username: `TOFU - ${accountUser.email}`,
        }).unwrap();

        handleNextPage();
      } catch (error) {
        console.log("Error al guardar la información:", error);
      } finally {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const handleOnAddAnotherDocument = () => {
    if (formik.values.another_document.length >= 3) {
      setIsDisabled(true);
      return;
    }
    formik.setFieldValue("another_document", [...formik.values.another_document, { ...emptyAnotherDocument }]);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        let documents: DocumentResponse[] = [];
        documents = await getDocumentsByAppId({
          loanApplicationId: applicationData.id,
          clientDetailsId: personalData.id,
          status: true,
        }).unwrap();

        const cierre_anual =
          documents.find((file) => file.file_code === DOCUMENTS_CATALOG.cierre_anual.code)?.id || null;

        const ultimo_cierre_parcial =
          documents.find((file) => file.file_code === DOCUMENTS_CATALOG.ultimo_cierre_parcial.code)?.id || null;

        const estados_finacieros = documents
          .filter((file) => file.file_code === DOCUMENTS_CATALOG.estados_finacieros.code)
          .map((file) => file.id);

        const anotherDocument: AnotherDocumentProps[] = [];
        for (let index = 0; index < estados_finacieros.length; index++) {
          anotherDocument.push({
            id: `${index}`,
            financial_statements_id: estados_finacieros[index],
          });
        }
        setListDocuments(documents);
        formik.setFieldValue("annual_closing_id", cierre_anual);
        formik.setFieldValue("last_partial_closing_id", ultimo_cierre_parcial);

        if (anotherDocument.length) {
          formik.setFieldValue("another_document", anotherDocument);
        }
      } catch (error) {
        console.log("Error al obtener la información bancaria:", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const handleNextPage = () => {
    navigate("/producto/creditosimple/waiting_room_revision");
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <Box mt={4} mb={2}>
            <Typography textAlign={"center"} color={theme?.palette?.primary?.dark} fontSize={22} fontWeight={500}>
              ¡Hola!{" "}
              <span>
                {" "}
                <br />
                {companySection.legal_name ?? "Nombre del Cliente"}
              </span>
            </Typography>
          </Box>
          <InactivityHandler />
          <Box mt={3} mb={2}>
            <Typography
              textAlign={"center"}
              color={theme?.palette?.primary?.main}
              variant={!isMobileOrTablet ? "body1" : "body2"}
              fontWeight={600}
            >
              <span>
                Te informamos que no pudimos autorizar tu solicitud con nuestro proceso automatizado, <br />
                por lo que será necesaria una revisión adicional.
              </span>
            </Typography>
          </Box>

          <Box mt={3} mb={2}>
            <Typography
              textAlign={"center"}
              color={theme?.palette?.primary?.dark}
              variant={!isMobileOrTablet ? "body1" : "body2"}
              fontWeight={300}
            >
              <span>
                No te preocupes, solo necesitamos algunos documentos para continuar con el proceso de evaluación. <br />{" "}
                Estos serán revisados en un plazo máximo de 24 horas hábiles.
              </span>
            </Typography>
          </Box>

          <Box mr={3} ml={3} mt={3} mb={2}>
            <form onSubmit={formik.handleSubmit}>
              <Box mt={3} mb={2}>
                <Typography
                  textAlign={"center"}
                  color={theme?.palette?.primary?.dark}
                  variant={!isMobileOrTablet ? "body1" : "body2"}
                  fontWeight={600}
                >
                  Estados Financieros
                </Typography>
              </Box>
              <CustomUploadFileV2
                mt={2}
                inputName={"annual_closing_id"}
                uploadedFile={listDocuments.find((item) => item.id === formik.values.annual_closing_id) || undefined}
                setUploadedFile={(newUpload) => {
                  formik.setFieldValue(`annual_closing_id`, newUpload?.id || "");

                  if (newUpload) {
                    setListDocuments((prev) => [...prev, newUpload]);
                  }
                }}
                optionalName="Cierre Anual"
                optionalDescription=""
                metadata={{
                  fileCode: DOCUMENTS_CATALOG.cierre_anual.code,
                  uploadType: "client",
                  clientDetailsId: personalData.id,
                }}
                disabled={isLoading || formik.isSubmitting}
                required
              />

              <CustomUploadFileV2
                mt={2}
                inputName={"last_partial_closing_id"}
                uploadedFile={
                  listDocuments.find((item) => item.id === formik.values.last_partial_closing_id) || undefined
                }
                setUploadedFile={(newUpload) => {
                  formik.setFieldValue(`last_partial_closing_id`, newUpload?.id || "");

                  if (newUpload) {
                    setListDocuments((prev) => [...prev, newUpload]);
                  }
                }}
                optionalName="Ultimo Cierre Parcial"
                optionalDescription=""
                metadata={{
                  fileCode: DOCUMENTS_CATALOG.ultimo_cierre_parcial.code,
                  uploadType: "client",
                  clientDetailsId: personalData.id,
                }}
                disabled={isLoading || formik.isSubmitting}
                required
              />
              {formik.values.another_document.map((document: AnotherDocumentProps, index: number) => (
                <Box key={index}>
                  <CustomUploadFileV2
                    mt={2}
                    inputName={`another_document[${index}].financial_statements_id`}
                    uploadedFile={
                      listDocuments.find((item) => item.id === document.financial_statements_id) || undefined
                    }
                    setUploadedFile={(newUpload) => {
                      if (newUpload) {
                        setListDocuments((prev) => [...prev, newUpload]);
                      } else {
                        setListDocuments((prev) => prev.filter((item) => item.id !== document.financial_statements_id));
                      }
                      formik.setFieldValue(`another_document[${index}].financial_statements_id`, newUpload?.id);
                    }}
                    optionalName="Estados Financieros"
                    optionalDescription=""
                    metadata={{
                      fileCode: DOCUMENTS_CATALOG.estados_finacieros.code,
                      uploadType: "client",
                      clientDetailsId: personalData.id,
                    }}
                    disabled={isLoading || formik.isSubmitting}
                  />
                </Box>
              ))}

              <Box textAlign={"left"} my={2}>
                <Typography
                  variant="body1"
                  fontWeight={400}
                  onClick={handleOnAddAnotherDocument}
                  sx={{ cursor: isDisabled ? "not-allowed" : "pointer", textDecoration: "underline" }}
                  color={theme?.palette?.primary?.main}
                >
                  + Agregar otro documento
                </Typography>
              </Box>

              <Box mt={2} mb={2}>
                <Typography textAlign={"center"} variant="body2" fontWeight={500} color={theme?.palette?.primary?.dark}>
                  El ultimo cierre parcial no podrá tener mas de 3 meses de antigüedad.
                </Typography>
              </Box>

              <Box mt={4} justifyContent={"center"} display="flex">
                <Button type="submit" variant="default" disabled={isLoading || !formik.isValid || formik.isSubmitting}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <CustomRightImage imageName="img_tofu_8" />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ZonaGris;
