/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { setCurrentOwnerId, setPropietarioRealDocument } from "@store/slices/bofuSlice";
import { TabStepBofuPm } from "@components/index";
import { PersonaStructure } from "@interfaces/index";
import { usePostDocumentGenarateMutation } from "@api/documents";
import { usePostValidateNextStageMutation } from "@api/applications";
import { InactivityHandler } from "@components/InactivityHandler";
import { UUID } from "crypto";
import { DOCUMENTS_CATALOG, STATUS_CATALOG } from "@helpers/constantes";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import CustomButtonExpediente from "@components/CustomButtonExpediente";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import { setLoanStatus } from "@store/slices/registerSlice";

const PropietarioRealPM: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const application = useSelector((state: RootState) => state.register.application);
  const bufuLoading = useSelector((state: RootState) => state.bofu.isLoading);
  const personAval = useSelector((state: RootState) => state.bofu.aval);
  const realOwners = useSelector((state: RootState) => state.bofu.propietarios_reales);
  const sectionCompany = useSelector((state: RootState) => state.bofu.section_company);
  const sectionAval = useSelector((state: RootState) => state.bofu.section_aval);

  const [createDocument] = usePostDocumentGenarateMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [allFilled, setAllFilled] = useState<boolean>(false);

  const handleClickButtonExpedient = (propietario: PersonaStructure) => {
    dispatch(setCurrentOwnerId(propietario.id));
    setTimeout(() => {
      navigate("/producto/creditosimple/datos_propietario_real_1");
    }, 200);
  };

  const validateCompany = () => {
    const company_forms =
      application?.bank_information_completed &&
      application?.references_completed &&
      application?.loan_application_completed &&
      application?.identity_validation_completed
        ? true
        : false;
    if (!company_forms) {
      return false;
    }

    if (
      sectionCompany?.doc_comprobante_domicilio?.validated === undefined ||
      sectionCompany?.doc_comprobante_domicilio?.validated === false
    ) {
      return false;
    }

    return true;
  };

  const validateAval = () => {
    const aval_forms =
      personAval?.information_person_completed &&
      personAval?.identity_validation_completed &&
      personAval?.bank_references_completed
        ? true
        : false;

    if (!aval_forms) {
      return false;
    }

    if (
      sectionAval?.doc_constancia_sf?.validated === undefined ||
      sectionAval?.doc_constancia_sf?.validated === false
    ) {
      return false;
    }

    if (
      sectionAval.doc_comprobante_domicilio?.validated === undefined ||
      sectionAval.doc_comprobante_domicilio?.validated === false
    ) {
      return false;
    }

    personAval?.information_person_completed;
    personAval?.bank_references_completed;
    if (personAval?.information_person_completed !== true) {
      return false;
    }

    if (personAval?.bank_references_completed !== true) {
      return false;
    }

    if (personAval.bank_references_completed !== true) {
      return false;
    }

    return true;
  };

  const validateRealOwners = () => {
    for (let index = 0; index < realOwners.length; index++) {
      const element = realOwners[index];
      if (element.owner.information_person_completed !== true) {
        return false;
      }

      if (element.identificacion?.validated === undefined || element.identificacion?.validated === false) {
        return false;
      }

      if (element.constancia?.validated === undefined || element.constancia?.validated === false) {
        return false;
      }
    }

    return true;
  };

  const handleClickContinue = async () => {
    try {
      setIsLoading(true);

      if (!validateCompany() || !validateAval() || !validateRealOwners()) {
        return;
      }

      const { code: next_status, url: next_url } = STATUS_CATALOG.validando_expediente_pm;

      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: next_status,
        username: `BOFU - ${accountUser.email}`,
      }).unwrap();

      dispatch(setLoanStatus(next_status));

      navigate(next_url);
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    } finally {
      setIsLoading(false);
    }
  };

  const generateAnexo2 = async (personId: string) => {
    try {
      const data = {
        loan_application_id: application.id,
        document_type: "anexo_2",
        person_id: personId,
      };

      await createDocument(data).unwrap();
    } catch (error: any) {
      console.error("Ha ocurrido un error al generar el anexo 2");
    }
  };

  const validateLoanApplicationExpedient = async () => {
    for (let index = 0; index < realOwners.length; index++) {
      const propietario = realOwners[index];

      if (propietario.owner.information_person_completed) {
        if (!propietario.anexo_2 || propietario.anexo_2.validated === false) {
          generateAnexo2(propietario.owner.id as string);
        }
      }
    }

    const application_completed =
      application.bank_information_completed &&
      application.references_completed &&
      application.loan_application_completed &&
      application.identity_validation_completed
        ? true
        : false;
    const aval_completed =
      personAval?.information_person_completed && personAval?.bank_references_completed ? true : false;
    const owners_completed = realOwners.every((item) => {
      if (item.owner.information_person_completed) {
        if (item.identificacion && item.constancia && item.anexo_2) {
          return true;
        }
      }
      return false;
    });

    if (application_completed && aval_completed && owners_completed) {
      setAllFilled(true);
    }
  };

  useEffect(() => {
    validateLoanApplicationExpedient();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={4}>
          <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
            Expediente digital
          </Typography>

          <TabStepBofuPm />

          <Typography
            mt={4}
            variant="body1"
            sx={{ fontWeight: "bold", fontSize: "16px" }}
            color={theme?.palette?.primary?.dark}
          >
            Ahora llena los formatos relacionados al o los propietarios reales
          </Typography>

          {bufuLoading ? (
            <Box mt={3}>
              <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
            </Box>
          ) : (
            <Box>
              {realOwners.length > 0 ? (
                <>
                  {realOwners.map((propietario, index) => (
                    <Box key={index} mt={3}>
                      <Typography color={theme?.palette?.primary?.dark} fontSize={"15px"} fontWeight={400}>
                        Propietario real {index + 1}:{" "}
                        {[
                          propietario.owner.name || "",
                          propietario.owner.last_name || "",
                          propietario.owner.last_name_2 || "",
                        ].join(" ")}
                      </Typography>

                      <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography
                          variant="body1"
                          sx={{ flex: 1, textAlign: "left" }}
                          color={theme?.palette?.primary?.dark}
                        >
                          Información del propietario real
                        </Typography>

                        <CustomButtonExpediente
                          onClick={() => handleClickButtonExpedient(propietario.owner)}
                          formStatus={propietario.owner.information_person_completed}
                          documentStatus={propietario.anexo_2?.validated}
                        />
                      </Box>

                      <CustomUploadFileV2
                        mt={2}
                        inputName={`identificacion_oficial_${index}`}
                        uploadedFile={propietario.identificacion}
                        setUploadedFile={(newUpload) => {
                          dispatch(
                            setPropietarioRealDocument({
                              index: index,
                              name: "identificacion",
                              file: newUpload,
                            }),
                          );
                        }}
                        optionalDescription="Vigente, puede ser INE, Pasaporte o FM2"
                        metadata={{
                          fileCode: DOCUMENTS_CATALOG.identificacion_oficial.code,
                          uploadType: "person",
                          personId: propietario.owner.id as UUID,
                          clientDetailsId: clientDetails.id,
                          personType: "propietario_real",
                        }}
                        disabled={isLoading}
                        required
                      />

                      <CustomUploadFileV2
                        mt={2}
                        inputName={`constancia_situacion_fiscal${index}`}
                        uploadedFile={propietario.constancia}
                        setUploadedFile={(newUpload) => {
                          dispatch(
                            setPropietarioRealDocument({
                              index: index,
                              name: "constancia",
                              file: newUpload,
                            }),
                          );
                        }}
                        optionalDescription="Deberá estar actualizada"
                        metadata={{
                          fileCode: DOCUMENTS_CATALOG.constancia_situacion_fiscal.code,
                          uploadType: "person",
                          personId: propietario.owner.id as UUID,
                          clientDetailsId: clientDetails.id,
                          personType: "propietario_real",
                        }}
                        disabled={isLoading}
                        required
                      />
                    </Box>
                  ))}

                  <Box mt={3}>
                    <Button
                      variant="default"
                      type="button"
                      sx={{ mt: 4, width: "180px", padding: "12px", mb: 5 }}
                      onClick={handleClickContinue}
                      disabled={isLoading || !allFilled}
                    >
                      Continuar
                    </Button>
                  </Box>
                </>
              ) : (
                <Typography
                  color={theme?.palette?.primary?.dark}
                  fontSize={"20px"}
                  textAlign={"center"}
                  fontWeight={600}
                >
                  No se encontraron propietarios registrados
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_8" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default PropietarioRealPM;
