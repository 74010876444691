import { LoanStatusCatalog } from "@interfaces/catalogs";

export const STATUS_CATALOG: LoanStatusCatalog = {
  // tofu pm
  registro_datos_personales: {
    code: "CSRUS",
    url: "/producto/creditosimple/registro_datos_personales",
  },
  validacion_telefono: {
    code: "CSVTE",
    url: "/producto/creditosimple/validacion_telefono",
  },
  datos_empresa_pm: {
    code: "CSDEM",
    url: "/producto/creditosimple/datos_empresa_pm",
  },
  consulta_ciec: {
    code: "CSAEM",
    url: "/producto/creditosimple/consulta_ciec",
  },
  uso_de_credito: {
    code: "CSUDC",
    url: "/producto/creditosimple/uso_de_credito",
  },
  presencia_digital: {
    code: "CSPDI",
    url: "/producto/creditosimple/presencia_digital",
  },
  datos_accionista: {
    code: "CSDAL",
    url: "/producto/creditosimple/datos_accionista",
  },
  rfc_curp: {
    code: "CSCDA",
    url: "/producto/creditosimple/rfc_curp",
  },
  domicilio_accionista: {
    code: "CSDAV",
    url: "/producto/creditosimple/domicilio_accionista",
  },
  consulta_buro: {
    code: "CSABA",
    url: "/producto/creditosimple/consulta_buro",
  },
  // tofu pfae
  datos_empresa_pfae: {
    code: "CSDEM",
    url: "/producto/creditosimple/datos_empresa_pfae",
  },
  domicilio_pfae: {
    code: "CSDOE",
    url: "/producto/creditosimple/domicilio_pfae",
  },
  presencia_digital_pfae: {
    code: "CSPDI",
    url: "/producto/creditosimple/presencia_digital_pfae",
  },
  consulta_buro_pfae: {
    code: "CSABA",
    url: "/producto/creditosimple/consulta_buro_pfae",
  },
  // waiting room
  waiting_room: {
    code: "CSCOF",
    url: "/producto/creditosimple/waiting_room",
  },
  oferta: {
    code: "CSAAO",
    url: "/producto/creditosimple/oferta",
  },
  waiting_room_revision: {
    code: "CREVC",
    url: "/producto/creditosimple/waiting_room_revision",
  },
  // bofu pm
  check_list: {
    code: "CSBEX",
    url: "/producto/creditosimple/check_list",
  },
  documentos_dictamen: {
    code: "CSDDI",
    url: "/producto/creditosimple/docs_empresa",
  },
  dictamen_juridico: {
    code: "CSDIC",
    url: "/producto/creditosimple/docs_empresa",
  },
  avance_expediente_digital: {
    code: "CSAED",
    url: "/producto/creditosimple/avance_expediente_digital",
  },
  validando_expediente_pm: {
    code: "CSEDC",
    url: "/producto/creditosimple/validando_expediente_pm",
  },
  errores_expediente_pm: {
    code: "CSECI",
    url: "/producto/creditosimple/errores_expediente_pm",
  },
  expediente_digital_validado: {
    code: "CSVCC",
    url: "/producto/creditosimple/completado",
  },
  // este estatus tiene 3 pantallas diferentes:
  //    agenda_visita - pantalla inicial
  //    agenda_visita_pm - pantalla para agregar nueva direccion
  //    confirmacion_domicilio - pantalla si ya hay una direccion confirmada
  agendar_visita: {
    code: "CSVCL",
    url: "/producto/creditosimple/agenda_visita",
  },
  // visita agendada
  visita_agendada: {
    code: "CSAVC",
    url: "/producto/creditosimple/confirmacion_visita_agendada",
  },
  // estos 5 estatus comparten la misma pantalla: /visita_ocular_concluida
  en_espera_documento_visita: {
    code: "CSVDV",
    url: "/producto/creditosimple/visita_ocular_concluida",
  },
  validacion_Visita: {
    code: "CSVYC",
    url: "/producto/creditosimple/visita_ocular_concluida",
  },
  revision_control: {
    code: "CSRIC",
    url: "/producto/creditosimple/visita_ocular_concluida",
  },
  emision_documentos_valor: {
    code: "CSEVD",
    url: "/producto/creditosimple/visita_ocular_concluida",
  },
  firma_cliente: {
    code: "CSFIR",
    url: "/producto/creditosimple/visita_ocular_concluida",
  },
  //
  // estos 3 estatus comparten la misma pantalla: /bofu_completado
  dispersion: {
    code: "CSDIS",
    url: "/producto/creditosimple/bofu_completado",
  },
  firma_ext_envio_exp: {
    code: "CSFIE",
    url: "/producto/creditosimple/bofu_completado",
  },
  cliente_completo: {
    code: "CSCLI",
    url: "/producto/creditosimple/bofu_completado",
  },
  // -----
  actas_identificacion: {
    code: "CSIYA",
    url: "/producto/creditosimple/actas_identificacion",
  },
  // bofu pfae
  informacion_documentos_pfae: {
    code: "CSBEX",
    url: "/producto/creditosimple/informacion_documentos_pfae",
  },
  avance_expediente_digitalPFAE: {
    code: "CSAED",
    url: "/producto/creditosimple/avance_expediente_digitalPFAE",
  },
  validando_expediente_pfae: {
    code: "CSEDC",
    url: "/producto/creditosimple/validando_expediente_pfae",
  },
  errores_expediente_pfae: {
    code: "CSECI",
    url: "/producto/creditosimple/errores_expediente_pfae",
  },
  // Rechazado onboarding
  rechazado_modelo: {
    code: "CSREC",
    url: "/producto/creditosimple/credito_rechazado",
  },
  rechazado_revision: {
    code: "CSRER",
    url: "/producto/creditosimple/credito_rechazado",
  },
  // Rechazado Underwritting
  rechazo_expediente: {
    code: "CSRDO",
    url: "/producto/creditosimple/rechazado_documentacion",
  },
  rechazo_formalizacion: {
    code: "CSRFO",
    url: "/producto/creditosimple/rechazado_documentacion",
  },
  rechazo_contratacion: {
    code: "CSRCO",
    url: "/producto/creditosimple/rechazado_documentacion",
  },
};

export const STATUS_TOFU = [
  STATUS_CATALOG.registro_datos_personales.code,
  STATUS_CATALOG.validacion_telefono.code,
  STATUS_CATALOG.datos_empresa_pfae.code,
  STATUS_CATALOG.domicilio_pfae.code,
  STATUS_CATALOG.consulta_ciec.code,
  STATUS_CATALOG.uso_de_credito.code,
  STATUS_CATALOG.presencia_digital_pfae.code,
  STATUS_CATALOG.consulta_buro_pfae.code,
  STATUS_CATALOG.datos_empresa_pm.code,
  STATUS_CATALOG.presencia_digital.code,
  STATUS_CATALOG.datos_accionista.code,
  STATUS_CATALOG.rfc_curp.code,
  STATUS_CATALOG.domicilio_accionista.code,
  STATUS_CATALOG.consulta_buro.code,
];

export const STATUS_TOFU_BY_SCREEN_PFAE = {
  registro_datos_personales: [
    STATUS_CATALOG.registro_datos_personales.code,
    STATUS_CATALOG.validacion_telefono.code,
    STATUS_CATALOG.datos_empresa_pfae.code,
    STATUS_CATALOG.domicilio_pfae.code,
    STATUS_CATALOG.consulta_ciec.code,
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital_pfae.code,
    STATUS_CATALOG.consulta_buro_pfae.code,
  ],
  validacion_telefono: [
    STATUS_CATALOG.validacion_telefono.code,
    STATUS_CATALOG.datos_empresa_pfae.code,
    STATUS_CATALOG.domicilio_pfae.code,
    STATUS_CATALOG.consulta_ciec.code,
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital_pfae.code,
    STATUS_CATALOG.consulta_buro_pfae.code,
  ],
  datos_empresa_pfae: [
    STATUS_CATALOG.datos_empresa_pfae.code,
    STATUS_CATALOG.domicilio_pfae.code,
    STATUS_CATALOG.consulta_ciec.code,
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital_pfae.code,
    STATUS_CATALOG.consulta_buro_pfae.code,
  ],
  domicilio_pfae: [
    STATUS_CATALOG.domicilio_pfae.code,
    STATUS_CATALOG.consulta_ciec.code,
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital_pfae.code,
    STATUS_CATALOG.consulta_buro_pfae.code,
  ],
  consulta_ciec: [
    STATUS_CATALOG.consulta_ciec.code,
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital_pfae.code,
    STATUS_CATALOG.consulta_buro_pfae.code,
  ],
  uso_de_credito: [
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital_pfae.code,
    STATUS_CATALOG.consulta_buro_pfae.code,
  ],
  presencia_digital_pfae: [STATUS_CATALOG.presencia_digital_pfae.code, STATUS_CATALOG.consulta_buro_pfae.code],
  consulta_buro_pfae: [STATUS_CATALOG.consulta_buro_pfae.code],
};

export const STATUS_TOFU_BY_SCREEN_PM = {
  registro_datos_personales: [
    STATUS_CATALOG.registro_datos_personales.code,
    STATUS_CATALOG.validacion_telefono.code,
    STATUS_CATALOG.datos_empresa_pm.code,
    STATUS_CATALOG.consulta_ciec.code,
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital.code,
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  validacion_telefono: [
    STATUS_CATALOG.validacion_telefono.code,
    STATUS_CATALOG.datos_empresa_pm.code,
    STATUS_CATALOG.consulta_ciec.code,
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital.code,
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  datos_empresa_pm: [
    STATUS_CATALOG.datos_empresa_pm.code,
    STATUS_CATALOG.consulta_ciec.code,
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital.code,
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  consulta_ciec: [
    STATUS_CATALOG.consulta_ciec.code,
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital.code,
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  uso_de_credito: [
    STATUS_CATALOG.uso_de_credito.code,
    STATUS_CATALOG.presencia_digital.code,
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  presencia_digital: [
    STATUS_CATALOG.presencia_digital.code,
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  datos_accionista: [
    STATUS_CATALOG.datos_accionista.code,
    STATUS_CATALOG.rfc_curp.code,
    STATUS_CATALOG.domicilio_accionista.code,
    STATUS_CATALOG.consulta_buro.code,
  ],
  rfc_curp: [STATUS_CATALOG.rfc_curp.code, STATUS_CATALOG.domicilio_accionista.code, STATUS_CATALOG.consulta_buro.code],
  domicilio_accionista: [STATUS_CATALOG.domicilio_accionista.code, STATUS_CATALOG.consulta_buro.code],
  consulta_buro: [STATUS_CATALOG.consulta_buro.code],
};

export const STATUS_BOFU_PM = {
  waiting_room: [STATUS_CATALOG.waiting_room.code],
  check_list: [STATUS_CATALOG.check_list.code],
  dictamen: [
    STATUS_CATALOG.documentos_dictamen.code,
    STATUS_CATALOG.dictamen_juridico.code,
    STATUS_CATALOG.avance_expediente_digital.code,
    STATUS_CATALOG.errores_expediente_pm.code,
  ],
  llenado_expediente: [
    STATUS_CATALOG.documentos_dictamen.code,
    STATUS_CATALOG.avance_expediente_digital.code,
    STATUS_CATALOG.errores_expediente_pm.code,
    STATUS_CATALOG.dictamen_juridico.code,
  ],
  validando_expediente: [STATUS_CATALOG.validando_expediente_pm.code],
  errores_expediente_pm: [STATUS_CATALOG.errores_expediente_pm.code],
  completado: [STATUS_CATALOG.expediente_digital_validado.code],
  agendar_visita: [STATUS_CATALOG.agendar_visita.code],
  agendando_visita: [], // STATUS_CATALOG.confirmacion_domicilio.code
  visita_agendada: [STATUS_CATALOG.visita_agendada.code],
  visita_concluida: [
    STATUS_CATALOG.en_espera_documento_visita.code,
    STATUS_CATALOG.validacion_Visita.code,
    STATUS_CATALOG.revision_control.code,
    STATUS_CATALOG.emision_documentos_valor.code,
    STATUS_CATALOG.firma_cliente.code,
  ],
  bofu_completado: [
    STATUS_CATALOG.dispersion.code,
    STATUS_CATALOG.firma_ext_envio_exp.code,
    STATUS_CATALOG.cliente_completo.code,
  ],
  emision_documentos_valor: [],
  firma_documentos: [STATUS_CATALOG.firma_cliente.code],
  rechazado_onboarding: [STATUS_CATALOG.rechazado_modelo.code, STATUS_CATALOG.rechazado_revision.code],
  rechazado_underwritting: [
    STATUS_CATALOG.rechazo_expediente.code,
    STATUS_CATALOG.rechazo_formalizacion.code,
    STATUS_CATALOG.rechazo_contratacion.code,
  ],
};

export const TAX_SYSTEMS = {
  PM: {
    code: "CS001",
  },
  PFAE: {
    code: "CS002",
  },
};

export const TAX_SYSTEM_TYPE = {
  PM: {
    code: "PM",
  },
  PFAE: {
    code: "PFAE",
  },
};

export const CLIENT_RFC_NOT_VALID = ["XAXX010101000"];

export const DOCUMENTS_CATALOG = {
  acta_constitutiva: {
    code: "DAC",
  },
  identificacion_representante_legal: {
    code: "DIORL",
  },
  acta_adicional: {
    code: "DAA",
  },
  estado_cuenta_bancario: {
    code: "DECB",
  },
  constancia_situacion_fiscal: {
    code: "DCDF",
  },
  comprobante_domicilio: {
    code: "DCD",
  },
  comprobante_domicilio_confirmado: {
    code: "DCDC",
  },
  anexo_1: {
    code: "ANX1",
  },
  anexo_2: {
    code: "ANX2",
  },
  solicitud_credito: {
    code: "SDC",
  },
  identificacion_oficial: {
    code: "DIO",
  },
  validacion_identidad: {
    code: "VIDME",
  },
  cierre_anual: {
    code: "EFCA",
  },
  ultimo_cierre_parcial: {
    code: "EFUCP",
  },
  estados_finacieros: {
    code: "EFDEF",
  },
  estado_cuenta_bancario_zona_gris: {
    code: "DECZG",
  },
};

export const PERSON_CATALOG = {
  propietario_real: {
    code: "PTPR",
  },
  representante_legal: {
    code: "PTRL",
  },
  accionista_mayoritario: {
    code: "PTAM",
  },
  representante_legal_accionista_mayoritario: {
    code: "PRLAM",
  },
  representante_legal_propietario_real: {
    code: "PRLPR",
  },
  accionista_mayoritario_propietario_real: {
    code: "PAMPR",
  },
  representante_legal_accionista_mayoritario_propietario_real: {
    code: "PRLAP",
  },
};

export const PERSON_CODES_LEGAL_REPRESENTATIVE = [
  PERSON_CATALOG.representante_legal.code,
  PERSON_CATALOG.representante_legal_accionista_mayoritario.code,
  PERSON_CATALOG.representante_legal_propietario_real.code,
  PERSON_CATALOG.representante_legal_accionista_mayoritario_propietario_real.code,
];

export const PERSON_CODES_AVAL = [
  PERSON_CATALOG.representante_legal_accionista_mayoritario.code,
  PERSON_CATALOG.accionista_mayoritario_propietario_real.code,
  PERSON_CATALOG.representante_legal_accionista_mayoritario_propietario_real.code,
  PERSON_CATALOG.accionista_mayoritario.code,
];

export const PERSON_CODES_PROPIETARIO_REAL = [
  PERSON_CATALOG.propietario_real.code,
  PERSON_CATALOG.representante_legal_propietario_real.code,
];

export const PEP_CODES = {
  propio: {
    code: "CPEPP",
    name: "Propio",
  },
  familiar: {
    code: "CPEPF",
    name: "Familiar",
  },
};

export const DEFAULT_HOST_VALUE = "lendia";

export const HOSTNAMES = [
  {
    host: "dev-clientes.lenditgroup.com",
    value: "lendia",
  },
  {
    host: "dev-clientes-finbe.lenditgroup.com",
    value: "finbe",
  },
  {
    host: "clientes.lendia.mx",
    value: "lendia",
  },
  {
    host: "clientes-pyme.finbeabc.com.mx",
    value: "finbe",
  },
  {
    host: "localhost",
    value: "finbe",
  },
];

export const ADDRESS_CODES = {
  particular: {
    code: "CDPA",
  },
  operativo: {
    code: "CDOP",
  },
  operativo_confirmado: {
    code: "CDOC",
  },
};

export const REFERENCE_CODES = {
  cliente: {
    code: "CRTCL",
  },
  proveedor: {
    code: "CRTPR",
  },
};
