import { HOSTNAMES } from "@helpers/constantes";
import { obtenerFechaHoy } from "@helpers/funciones";
import { Link, Typography, useTheme } from "@mui/material";
import { RootState } from "@store/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface TypeText {
  type:
    | "modalCiec"
    | "textCiec"
    | "textBuro"
    | "modalBuro"
    | "avisoTerminosTextCiec"
    | "avisoTerminosTextCiec2"
    | "avisoTerminosTextBuro";
}

export const CustomTextCiecBuro = ({ type }: TypeText) => {
  const theme = useTheme();
  const [company, setCompany] = useState("");
  const companyData = useSelector((state: RootState) => state.register.company);

  useEffect(() => {
    const hostname = window.location.hostname;
    const foundHost = HOSTNAMES.find(({ host }) => host === hostname);
    setCompany(String(foundHost?.value));
  }, []);

  const getTextCiec = (typeCompany: string) => {
    if (typeCompany === "lendia") {
      return (
        <Typography textAlign={"center"} color={theme?.palette?.primary?.dark}>
          <span>
            <small>
              Realizaremos una evaluación que nos determinará la factibilidad de poder acceder a financiamiento. Al
              continuar, el solicitante forma una relación jurídica como cliente de Lendit Group SAPI de CV y las
              autoriza a realizar consultas y evaluar información por mecanismos digitales.
            </small>
          </span>
        </Typography>
      );
    }

    if (typeCompany === "finbe") {
      return (
        <Typography textAlign={"center"} color={theme?.palette?.primary?.dark}>
          <span>
            <small>
              Realizaremos una evaluación que nos determinará la factibilidad de poder acceder a financiamiento. Al
              continuar, el solicitante forma una relación jurídica como cliente de Financiera Bepensa SA DE CV SOFOM
              ER, Bepensa Leasing, SA DE CV, AB&C Leasing de México, SAPI DE CV y Lendit Group SAPI de CV y las autoriza
              a realizar consultas y evaluar información por mecanismos digitales.
            </small>
          </span>
        </Typography>
      );
    }
  };

  const getTextModalCiec = (typeCompany: string) => {
    if (typeCompany === "lendia") {
      return (
        <>
          <Typography textAlign={"center"} variant="body2" color={theme?.palette?.primary?.dark}>
            Por este conducto autorizo expresamente a Lendit Group SAPI de CV y a {companyData.legal_name}, para que por
            conducto de sus funcionarios facultados lleve a cabo investigaciones sobre mi comportamiento crediticio en
            las Sociedades de Información Crediticia que estime conveniente.
          </Typography>
          <Typography textAlign={"center"} variant="body2" color={theme?.palette?.primary?.dark} mt={2}>
            Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que
            Lendit Group SAPI de CV hará de tal información y de que ésta podrá realizar consultas periódicas de mi
            historial crediticio, consintiendo que esta autorización se encuentre vigente por un periodo de 3 años
            contados a partir de la fecha de su expedición y en todo caso durante el tiempo que mantengamos relación
            jurídica.
          </Typography>
          <Typography textAlign={"center"} variant="body2" color={theme?.palette?.primary?.dark} mt={2} mb={3}>
            Declaro bajo protesta de decir verdad ser Representante Legal de la empresa mencionada en esta autorización;
            manifestando que a la fecha de la presente autorización los poderes no me han sido revocados, ni limitados
            en forma alguna.
          </Typography>
        </>
      );
    }

    if (typeCompany === "finbe") {
      return (
        <>
          <Typography textAlign={"center"} variant="body2" color={theme?.palette?.primary?.dark}>
            Por este conducto autorizo expresamente a Financiera Bepensa SA DE CV SOFOM ER, Bepensa Leasing, SA DE CV,
            AB&C Leasing de México, SAPI DE CV y a Lendit Group SAPI de CV para que por conducto de sus funcionarios
            facultados lleve a cabo investigaciones sobre mi comportamiento crediticio en las Sociedades de Información
            Crediticia que estime conveniente.
          </Typography>
          <Typography textAlign={"center"} variant="body2" color={theme?.palette?.primary?.dark} mt={2}>
            Así mismo, declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que
            Financiera Bepensa SA DE CV SOFOM ER, Bepensa Leasing, SA DE CV, AB&C Leasing de México, SAPI DE CV y Lendit
            Group SAPI hará de tal información y de que ésta podrá realizar consultas periódicas de mi historial
            crediticio, consintiendo que esta autorización se encuentre vigente por un periodo de 3 años contados a
            partir de la fecha de su expedición y en todo caso durante el tiempo que mantengamos relación jurídica.
          </Typography>
          <Typography textAlign={"center"} variant="body2" color={theme?.palette?.primary?.dark} mt={2} mb={3}>
            Declaro bajo protesta de decir verdad ser Representante Legal de la empresa mencionada en esta autorización;
            manifestando que a la fecha de la presente autorización los poderes no me han sido revocados, ni limitados
            en forma alguna.
          </Typography>
        </>
      );
    }
  };

  const getTextAvisoPrivacidadTerminos = (typeCompany: string) => {
    if (typeCompany === "lendia") {
      return (
        <>
          <Typography variant={"body2"} color={theme?.palette?.primary?.dark}>
            Autorizo a Lendit Group SAPI de CV a consultar mi información ante <br /> Buró de crédito.
          </Typography>
        </>
      );
    }

    if (typeCompany === "finbe") {
      return (
        <>
          <Typography variant={"body2"} color={theme?.palette?.primary?.dark}>
            Autorizo a Financiera Bepensa SA DE CV SOFOM ER, Bepensa Leasing, SA DE CV, AB&C Leasing de México, SAPI DE
            CV y a Lendit Group SAPI de CV a consultar mi información ante Buró de crédito.
          </Typography>
        </>
      );
    }
  };

  const getTextAvisoPrivacidadTerminos2 = (typeCompany: string) => {
    if (typeCompany === "lendia") {
      return (
        <>
          <Typography variant={"body2"} color={theme?.palette?.primary?.dark}>
            Autorizo a la consulta de mi información ante SAT bajo los{" "}
            <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
              términos y condiciones
            </Link>{" "}
            y el{" "}
            <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
              aviso de privacidad
            </Link>{" "}
          </Typography>
        </>
      );
    }

    if (typeCompany === "finbe") {
      return (
        <>
          <Typography variant={"body2"} color={theme?.palette?.primary?.dark}>
            Autorizo a la consulta de mi información ante SAT bajo los{" "}
            <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
              términos y condiciones
            </Link>{" "}
            y el{" "}
            <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
              aviso de privacidad
            </Link>{" "}
          </Typography>
        </>
      );
    }
  };

  const getTextModalBuro = (typeCompany: string) => {
    if (typeCompany === "lendia") {
      return (
        <>
          <Typography textAlign={"center"} variant="body2" color={theme.palette.primary.dark}>
            Hoy siendo {obtenerFechaHoy()}, Autoriza a Lendit Group SA de CV a consultar sus antecedentes crediticios
            por única ocasión ante las Sociedades de Información Crediticia que estime conveniente, declarando que
            conoce la naturaleza, alcance y uso que Razón Social hará de tal información.
          </Typography>
        </>
      );
    }

    if (typeCompany === "finbe") {
      return (
        <>
          <Typography textAlign={"center"} variant="body2" color={theme.palette.primary.dark}>
            Hoy siendo {obtenerFechaHoy()}, Autoriza a Financiera Bepensa SA DE CV SOFOM ER, Bepensa Leasing, SA DE CV,
            AB&C Leasing de México, SAPI DE CV y a Lendit Group SAPI de CV a consultar sus antecedentes crediticios por
            única ocasión ante las Sociedades de Información Crediticia que estime conveniente, declarando que conoce la
            naturaleza, alcance y uso que Razón Social hará de tal información.
          </Typography>
        </>
      );
    }
  };

  const getTextAvisoPrivacidadTerminosBuro = (typeCompany: string) => {
    if (typeCompany === "lendia") {
      return (
        <>
          <Typography variant={"body2"} color={theme?.palette?.primary?.dark}>
            Autorizo a Lendit group a consultar mi información ante Buró de crédito. Consulta{" "}
            <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES} target="_blank">
              términos y condiciones
            </Link>{" "}
            y{" "}
            <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD} target="_blank">
              aviso de privacidad
            </Link>{" "}
          </Typography>
        </>
      );
    }

    if (typeCompany === "finbe") {
      return (
        <>
          <Typography variant={"body2"} color={theme?.palette?.primary?.dark}>
            Autorizo a Financiera Bepensa SA DE CV SOFOM ER, Bepensa Leasing, SA DE CV, AB&C Leasing de México, SAPI DE
            CV y a Lendit Group SAPI de CV a consultar mi información ante Buró de crédito.
          </Typography>
        </>
      );
    }
  };

  const getTextAvisoPrivacidadTerminosBuro2 = (typeCompany: string) => {
    if (typeCompany === "lendia") {
      return <></>;
    }

    if (typeCompany === "finbe") {
      return (
        <>
          <Typography variant={"body2"} color={theme?.palette?.primary?.dark}>
            Consulta{" "}
            <Link href={import.meta.env.VITE_URL_TERM_CONDICIONES_FINBE} target="_blank">
              términos y condiciones
            </Link>{" "}
            y{" "}
            <Link href={import.meta.env.VITE_URL_AVISO_PRIVACIDAD_FINBE} target="_blank">
              aviso de privacidad
            </Link>{" "}
          </Typography>
        </>
      );
    }
  };

  return (
    <>
      {type === "textCiec" && <>{getTextCiec(company)}</>}
      {type === "modalCiec" && <>{getTextModalCiec(company)}</>}
      {type === "avisoTerminosTextCiec" && <>{getTextAvisoPrivacidadTerminos(company)}</>}
      {type === "avisoTerminosTextCiec2" && <>{getTextAvisoPrivacidadTerminos2(company)}</>}

      {type === "textBuro" && <>{getTextAvisoPrivacidadTerminosBuro(company)}</>}
      {type === "modalBuro" && <>{getTextModalBuro(company)}</>}
      {type === "avisoTerminosTextBuro" && <>{getTextAvisoPrivacidadTerminosBuro2(company)}</>}
    </>
  );
};
