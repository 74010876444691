/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { usePutApplicationCompletedMutation } from "@api/applications";
import { usePostDocumentGenarateMutation } from "@api/documents";
import { TabStepBofuPm } from "@components/index";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomButtonExpediente from "@components/CustomButtonExpediente";
import { DOCUMENTS_CATALOG } from "@helpers/constantes";
import { DocumentResponse } from "@interfaces/response";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import { ModalExpediente } from "@components/CustomModalExpediente";
import CustomRightImage from "@components/CustomRightImage";
import { setSectionCompanyDocumentComprobanteDomicilio } from "@store/slices/bofuSlice";

const AvanceExpedienteDigital: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const companySection = useSelector((state: RootState) => state.register.company);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const bufuLoading = useSelector((state: RootState) => state.bofu.isLoading);
  const application = useSelector((state: RootState) => state.register.application);
  const legalRepresentative = useSelector((state: RootState) => state.bofu.representante_legal);
  const sectionCompany = useSelector((state: RootState) => state.bofu.section_company);

  const [openModalValidacionIndentidad, setOpenModalValidacionIdentidad] = useState(false);
  const [openModalSolicitudCredito, setOpenModalSolicitudCredito] = useState(false);
  const [openModalEmpresa, setOpenModalEmpresa] = useState(false);

  const [createDocument] = usePostDocumentGenarateMutation();
  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();

  const generateSolicitudCredito = async () => {
    try {
      const { doc_solicitud_credito } = sectionCompany ?? {};
      const all_forms_completed =
        application.loan_application_completed &&
        application.references_completed &&
        application.bank_information_completed;

      // descartar si no se han completado todos los formularios
      if (!all_forms_completed) {
        return;
      }

      // descartar si ya se ha validado
      if (doc_solicitud_credito?.validated === true || doc_solicitud_credito?.validated === null) {
        return;
      }

      const payload_document = {
        loan_application_id: application.id,
        document_type: "solicitud_credito",
      };

      await createDocument(payload_document).unwrap();

      const payload_loan = {
        completed: true,
      };

      await putAapplicationCompleted({ id_sol: application.id, body: payload_loan });
    } catch (error: any) {
      console.log("Error al generar el documento de solicitud de crédito", error);
    }
  };

  const handleClickSolicitudCredito = () => {
    if (application.decision_validated && legalRepresentative) {
      navigate("/producto/creditosimple/solicitud_credito_1");
    } else {
      setOpenModalSolicitudCredito(true);
    }
  };

  const handleClickValidacionIdentidad = () => {
    if (application.decision_validated && legalRepresentative) {
      navigate("/producto/creditosimple/validacion_representante_legal");
    } else {
      setOpenModalValidacionIdentidad(true);
    }
  };

  const handleClickContinue = () => {
    if (!application.decision_validated) {
      setOpenModalEmpresa(true);
      return;
    }
    navigate("/producto/creditosimple/docs_aval");
  };

  useEffect(() => {
    generateSolicitudCredito();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={2}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
              Expediente digital
            </Typography>

            <TabStepBofuPm />

            <Typography
              mt={4}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={700}
              color={theme?.palette?.primary?.dark}
            >
              Muy bien, ahora llena los formatos relacionados a la empresa
            </Typography>

            {bufuLoading ? (
              <Box mt={3}>
                <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
              </Box>
            ) : (
              <Box>
                <Typography mt={2} variant={isMobileOrTablet ? "body2" : "body1"} color={theme?.palette?.primary?.main}>
                  {companySection.legal_name ?? "Nombre del Cliente"}
                </Typography>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }} color={theme?.palette?.primary?.dark}>
                    Solicitud de crédito
                  </Typography>

                  <CustomButtonExpediente
                    onClick={handleClickSolicitudCredito}
                    formStatus={application?.loan_application_completed}
                    documentStatus={sectionCompany?.doc_solicitud_credito?.validated}
                    fakeDisabled={!application.decision_validated || !legalRepresentative}
                  />
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }} color={theme?.palette?.primary?.dark}>
                    Referencias
                  </Typography>

                  <CustomButtonExpediente
                    onClick={() => navigate("/producto/creditosimple/referencias_clientes")}
                    formStatus={application?.references_completed}
                    documentStatus={sectionCompany?.doc_solicitud_credito?.validated}
                  />
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }} color={theme?.palette?.primary?.dark}>
                    Información bancaria
                  </Typography>

                  <CustomButtonExpediente
                    onClick={() => navigate("/producto/creditosimple/info_bancaria_empresa")}
                    formStatus={application?.bank_information_completed}
                    documentStatus={sectionCompany?.doc_solicitud_credito?.validated}
                  />
                </Box>

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1" sx={{ flex: 1, textAlign: "left" }} color={theme?.palette?.primary?.dark}>
                    Validación de identidad
                  </Typography>

                  <CustomButtonExpediente
                    onClick={handleClickValidacionIdentidad}
                    formStatus={application?.identity_validation_completed}
                    documentStatus={sectionCompany?.doc_validacion_identidad?.validated}
                    type="validation"
                    fakeDisabled={!application.decision_validated || !legalRepresentative}
                  />
                </Box>

                <Typography mt={4} variant={isMobileOrTablet ? "body2" : "body1"} color={theme?.palette?.primary?.dark}>
                  Necesitarás tener la identificación a la mano y tomar un par de selfies
                </Typography>

                <Typography
                  mt={2}
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  fontWeight={600}
                  color={theme?.palette?.primary?.dark}
                >
                  Ahora carga los documentos relacionados a la empresa
                </Typography>

                <Box mt={2} textAlign="left">
                  <CustomUploadFileV2
                    mt={2}
                    inputName={"comprobante_domicilio"}
                    uploadedFile={sectionCompany?.doc_comprobante_domicilio}
                    setUploadedFile={(newUpload: DocumentResponse | undefined) => {
                      dispatch(setSectionCompanyDocumentComprobanteDomicilio(newUpload));
                    }}
                    optionalDescription="No deberá ser mayor a 3 meses"
                    metadata={{
                      fileCode: DOCUMENTS_CATALOG.comprobante_domicilio.code,
                      uploadType: "client",
                      clientDetailsId: clientDetails.id,
                    }}
                    required
                  />

                  <Box mt={3} mb={3} textAlign={"center"}>
                    <Button
                      variant={application.decision_validated ? "default" : "default_disabled"}
                      onClick={handleClickContinue}
                    >
                      Continuar
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <CustomRightImage imageName="img_bofu_3" />
            </Box>
          </Grid>
        )}
      </Grid>

      <ModalExpediente
        type="solicitud_credito"
        openModal={openModalSolicitudCredito}
        closeModal={() => setOpenModalSolicitudCredito(false)}
      />

      <ModalExpediente
        type="identity_validation_aval"
        openModal={openModalValidacionIndentidad}
        closeModal={() => setOpenModalValidacionIdentidad(false)}
      />

      <ModalExpediente
        type="dictamen_not_available"
        openModal={openModalEmpresa}
        closeModal={() => setOpenModalEmpresa(false)}
      />
    </>
  );
};

export default AvanceExpedienteDigital;
