/* eslint-disable @typescript-eslint/no-explicit-any */
import { baseApi } from "./baseApi";
import {
  PayloadRequestPhoneCode,
  PayloadValidatePhoneCode,
  PayloadRegisterUser,
  Response,
  RegisterUserResponse,
  PayloadSaveShareholderData,
  ResponseSaveShareholderData,
  PayloadSaveLeadData,
  CurpResponse,
  CurpQuery,
} from "../types";

const onboardingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    registerUser: build.mutation<RegisterUserResponse, any>({
      query: (data: PayloadRegisterUser) => ({
        url: `usuarios`,
        method: "POST",
        body: data,
      }),
    }),
    requestPhoneCode: build.mutation<Response, any>({
      query: (data: PayloadRequestPhoneCode) => ({
        url: `telefono/enviar_codigo`,
        method: "POST",
        body: data,
      }),
    }),
    validatePhoneCode: build.mutation<Response, any>({
      query: (data: PayloadValidatePhoneCode) => ({
        url: `telefono/validar_codigo`,
        method: "POST",
        body: data,
      }),
    }),
    saveShareholderData: build.mutation<ResponseSaveShareholderData, any>({
      query: (data: PayloadSaveShareholderData) => ({
        url: `personas`,
        method: "POST",
        body: data,
      }),
    }),
    saveLeadData: build.mutation<Response, any>({
      query: (data: PayloadSaveLeadData) => ({
        url: `leads`,
        method: "POST",
        body: data,
      }),
    }),
    getCurp: build.query<CurpResponse, CurpQuery>({
      query: (data) => ({
        url: "curp",
        params: data,
      }),
    }),
    requestPasswordChange: build.mutation<Response, { username: string }>({
      query: (data) => ({
        url: `user_password_change_request/request`,
        method: "POST",
        body: data,
      }),
    }),
    updatePasswordChange: build.mutation<Response, { request_id: string; password: string }>({
      query: (data) => ({
        url: `user_password_change_request/update_password`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useRegisterUserMutation,
  useRequestPhoneCodeMutation,
  useValidatePhoneCodeMutation,
  useSaveShareholderDataMutation,
  useSaveLeadDataMutation,
  useLazyGetCurpQuery,
  useRequestPasswordChangeMutation,
  useUpdatePasswordChangeMutation,
} = onboardingApi;
