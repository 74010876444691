/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomRightImage from "@components/CustomRightImage";
import IconRejected from "@components/Images/IconRejected";

const FirmaDocumentos: React.FC = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container sx={{ height: "calc(100vh - 80px)" }}>
      <Grid item xs={12} md={8}>
        <InactivityHandler />

        <Container maxWidth="lg">
          <Typography
            mt={5}
            variant={isMobileOrTablet ? "h5" : "h4"}
            fontWeight={700}
            color={theme?.palette?.primary?.dark}
            textAlign="center"
          >
            Firma de documentos
          </Typography>

          <NavBarOcularVisit currentStep={4} />

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            Te encuentras en el ultimo paso para obtener tu crédito, a continuación un asesor se pondrá en contacto
            contigo para coordinar la firma del contrato
          </Typography>

          <Box display="flex" justifyContent="center" mt={4}>
            <IconRejected width={isMobileOrTablet ? "150" : "250"} fill={theme.palette.primary.light} />
          </Box>

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
          >
            ¡Despreocúpate!
          </Typography>

          <Typography
            mt={1}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
          >
            Puedes salir de esta pantalla, te contactaremos.
          </Typography>
        </Container>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_10" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default FirmaDocumentos;
