/* eslint-disable react-hooks/exhaustive-deps */
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { TAX_SYSTEM_TYPE } from "@helpers/constantes";

const DocumentsForOcularVisit = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const application = useSelector((state: RootState) => state.register.application);

  const [documentsRequired, setDocumentsRequired] = useState<string[]>([]);

  useEffect(() => {
    if (application.tax_system_type === TAX_SYSTEM_TYPE.PFAE.code) {
      setDocumentsRequired([
        "Comprobante de domicilio operativo",
        "Estados de cuenta bancarios",
        "Identificación oficial vigente del representante legal",
      ]);
    } else {
      setDocumentsRequired([
        "Caratula",
        "Acta constitutiva",
        "Actas adicionales",
        "Comprobante de domicilio operativo",
        "Estados de cuenta bancarios",
        "Identificación oficial vigente del representante legal",
      ]);
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: theme?.palette?.phoneInput?.main,
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <Typography fontWeight={600} color={theme?.palette?.primary?.dark} fontSize={"14px"} align="center">
        Recuerda que el día de la visita deberás presentar los siguientes documentos
      </Typography>

      <List>
        {documentsRequired.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemIcon sx={{ minWidth: "15px" }}>
              <Box width={12} height={12} mr={1} sx={{ backgroundColor: theme?.palette?.primary?.main }}></Box>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant={isMobileOrTablet ? "body2" : "body1"}>{item}</Typography>}
              sx={{
                color: theme?.palette?.primary?.dark,
                fontWeight: 300,
              }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DocumentsForOcularVisit;
