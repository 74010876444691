/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { RootState, useAppDispatch } from "@store/store";
import { LoanConditions } from "@components/LoanConditions";
import { setModalQuotationVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomRightImage from "@components/CustomRightImage";
import IconSpeed from "@components/Images/IconSpeed";
import useInterval from "@hooks/useInterval";
import { useLazyGetLoanApplicationByIdQuery } from "@api/applications";
import { setLoanStatus } from "@store/slices/registerSlice";
import { LoanStatus } from "@interfaces/catalogs";

const SECONDS_TO_FETCH = 120;

const ExpedienteDigitalCompleto = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const application = useSelector((state: RootState) => state.register.application);

  const [getLoanApplication] = useLazyGetLoanApplicationByIdQuery();

  const loanInterval = useInterval(async () => {
    try {
      const loan_application = await getLoanApplication(application.id).unwrap();
      if (loan_application?.status_catalog) {
        dispatch(setLoanStatus(loan_application.status_catalog.code as LoanStatus));
        navigate(loan_application.status_catalog.url_actual);
      }
    } catch (error) {
      console.error("Ha ocurrido un error al obtener la aplicación");
    }
  }, SECONDS_TO_FETCH);

  const handleOnClickShowQuotation = () => {
    dispatch(setModalQuotationVisible(true));
  };

  useEffect(() => {
    loanInterval.setIsActiveInterval(true);

    return () => {
      loanInterval.setIsActiveInterval(false);
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box mx={4} textAlign={"center"}>
          <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
            ¡Felicidades {personalData.name.concat(" ", personalData.first_lastname, " ", personalData.second_lastname)}
            !
          </Typography>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={400}
            color={theme?.palette?.primary?.main}
          >
            Tu expediente digital ha sido autorizado
          </Typography>

          <Box sx={{ mt: 2, mb: 2 }}>
            <IconSpeed fill={theme.palette.primary.dark} />
          </Box>

          <Box display={"flex"} justifyContent={"center"}>
            <Typography
              mt={4}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={400}
              color={theme?.palette?.primary?.dark}
              width={isMobileOrTablet ? "100%" : "60%"}
            >
              Cada vez estas más cerca de obtener tu crédito, avanzaras automáticamente al proceso de visita ocular una
              vez que se realice la ratificación crediticia y legal.
            </Typography>
          </Box>

          <Typography
            mt={4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            color={theme?.palette?.primary?.dark}
          >
            Revisa las condiciones del crédito que estas solicitando
          </Typography>

          <LoanConditions />

          <Box display={"flex"} justifyContent={"center"} mt={4}>
            <Button variant="blue_outlined" size="small" onClick={handleOnClickShowQuotation}>
              Modifica tu cotización
            </Button>
          </Box>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_12" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ExpedienteDigitalCompleto;
