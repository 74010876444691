import { Typography } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { TAX_SYSTEM_TYPE } from "@helpers/constantes";

interface ProgressBarProps {
  progress: number;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
  const theme = useTheme();

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#d9d9d9",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme?.palette?.primary?.main,
    },
    boxShadow: "inset 0 1px 3px rgba(0, 0, 0, 0.2)",
  }));

  const applicationData = useSelector((state: RootState) => state.register.application);

  const normalizedProgress = (progress / (applicationData.tax_system_type === TAX_SYSTEM_TYPE.PM.code ? 10 : 8)) * 100;

  return (
    <>
      <Typography align="right" variant="body2" color={theme?.palette?.primary?.dark}>
        {progress} de {applicationData.tax_system_type === TAX_SYSTEM_TYPE.PM.code ? 10 : 8}
      </Typography>
      <BorderLinearProgress variant="determinate" value={normalizedProgress} />
    </>
  );
};
