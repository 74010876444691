/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, Typography, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { QuotationOferta } from "@components/index";
import { formatCurrency } from "@helpers/funciones";
import {
  usePostValidateNextStageMutation,
  useLazyGetApplicationByApplicationIdQuery,
  useLazyGetConditionsByIdQuery,
  usePostConditionMutation,
} from "@api/applications";
import { setLoanStatus, setNewConditionLoan } from "@store/slices/registerSlice";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { useLazyGetTaxSystemsQuery } from "@api/catalogs";
import { LoanCondiciones } from "@interfaces/condiciones";
import { TaxSystem } from "@interfaces/catalogs";
import { STATUS_CATALOG, TAX_SYSTEM_TYPE } from "@helpers/constantes";
import { ApiError } from "@interfaces/response";

interface NewLoanCondition {
  has_change: boolean;
  amount: number;
  term: number;
  monthlyPayment: number;
}

const Oferta = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const application = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [getConditions] = useLazyGetConditionsByIdQuery();
  const [getTaxSystems] = useLazyGetTaxSystemsQuery();
  const [getLoanApplication] = useLazyGetApplicationByApplicationIdQuery();
  const [postNewConditions] = usePostConditionMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [condition, setCondition] = useState<LoanCondiciones | null>(null);
  const [taxSystems, setTaxSystems] = useState<TaxSystem | null>(null);
  const [loanApplication, setApplicationData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newConditions, setNewConditions] = useState<NewLoanCondition>({
    has_change: false,
    amount: 0,
    term: 0,
    monthlyPayment: 0,
  });

  const handleOnClickUpdateConditionLoan = async () => {
    if (!condition || !loanApplication) return;

    try {
      setIsLoading(true);
      const dataPost = {
        loan_application: application.id,
        loan_amount: newConditions.amount,
        requested_term: newConditions.term,
      };

      await postNewConditions(dataPost).unwrap();

      dispatch(
        setNewConditionLoan({
          approved_amount: condition.approved_amount,
          approved_term: condition.approved_term,
          interest_rate: condition.interest_rate,
          loanAmount: newConditions.amount,
          loanTerm: newConditions.term,
          amountMonthly: newConditions.monthlyPayment,
        }),
      );

      const { check_list, informacion_documentos_pfae } = STATUS_CATALOG;

      await triggerPostNextStage({
        applicationId: application.id,
        statusCode: check_list.code,
        username: `TOFU - ${accountUser.email}`,
      }).unwrap();

      dispatch(setLoanStatus(check_list.code));

      if (application.tax_system_type == TAX_SYSTEM_TYPE.PM.code) {
        navigate(check_list.url);
      } else {
        navigate(informacion_documentos_pfae.url);
      }
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnUpdateValues = (amount: number, term: number, monthlyRate: number) => {
    if (!condition) return;

    const has_change_amount = condition.loan_amount !== amount;
    const has_change_term = condition.requested_term !== term;
    setNewConditions({
      has_change: has_change_amount || has_change_term,
      amount: amount,
      term: term,
      monthlyPayment: monthlyRate,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const [conditions, taxSystems, loanApplication] = await Promise.all([
          getConditions(application.id).unwrap(),
          getTaxSystems(application.type).unwrap(),
          getLoanApplication(application.id).unwrap(),
        ]);

        const activeCondition = conditions.find((item) => item.active);
        if (activeCondition) {
          setCondition(activeCondition);
          setTaxSystems(taxSystems);
          setApplicationData(loanApplication.data[0]);
        }
      } catch (error) {
        console.log("Ha ocurrido un error", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12} mt={5} sx={{ position: "relative" }}>
          <InactivityHandler />
          <Container maxWidth={"md"}>
            <Box
              sx={{
                my: 0,
                mx: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box sx={{ mt: 0, mb: 2 }}>
                <Typography
                  color={theme?.palette?.primary?.main}
                  fontWeight={theme?.palette?.fontWeights?.medium}
                  align="center"
                  fontSize={"22px"}
                >
                  ¡Felicidades{" "}
                  {personalData.name.concat(" ", personalData.first_lastname, " ", personalData.second_lastname)}!
                </Typography>
              </Box>

              <Box sx={{ mt: 1, mb: 1 }}>
                <Typography
                  color={theme?.palette?.primary?.dark}
                  fontWeight={theme?.palette?.fontWeights?.light}
                  align="center"
                  fontSize={"15px"}
                >
                  Tienes un crédito preautorizado de hasta:
                </Typography>

                <Typography
                  color={theme?.palette?.primary?.main}
                  fontWeight={theme?.palette?.fontWeights?.semibold}
                  align="center"
                  fontSize={"22px"}
                >
                  {formatCurrency(condition?.approved_amount || 0)} MXN
                </Typography>
              </Box>

              <Grid container>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Typography
                      color={theme?.palette?.primary?.dark}
                      fontWeight={theme?.palette?.fontWeights?.light}
                      align="center"
                      fontSize={"15px"}
                    >
                      Con un plazo de hasta:
                    </Typography>

                    <Typography
                      color={theme?.palette?.primary?.dark}
                      fontWeight={theme?.palette?.fontWeights?.semibold}
                      align="center"
                      fontSize={"15px"}
                    >
                      {condition?.approved_term || 0} meses
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{ mt: 1, mb: 1 }}>
                    <Typography
                      color={theme?.palette?.primary?.dark}
                      fontWeight={theme?.palette?.fontWeights?.light}
                      align="center"
                      fontSize={"15px"}
                    >
                      Y una tasa de:
                    </Typography>

                    <Typography
                      color={theme?.palette?.primary?.dark}
                      fontWeight={theme?.palette?.fontWeights?.semibold}
                      align="center"
                      fontSize={"15px"}
                    >
                      {condition?.interest_rate || 0}%
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <QuotationOferta condition={condition} taxSystems={taxSystems} onUpdateValues={handleOnUpdateValues} />

              <Box sx={{ mt: 1, mb: 1 }}>
                <Button variant="default" onClick={handleOnClickUpdateConditionLoan} disabled={isLoading}>
                  Continuar
                </Button>
              </Box>
            </Box>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};

export default Oferta;
