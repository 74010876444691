import { formatCurrency } from "@helpers/funciones";
import { Grid, Typography, Divider, useTheme, useMediaQuery } from "@mui/material";
import { RootState } from "@store/store";
import { useSelector } from "react-redux";

export const LoanConditions = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const applicationData = useSelector((state: RootState) => state.register.application);

  return (
    <Grid container flexDirection={"row"} mt={4}>
      <Grid item xs={6}>
        <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={400} textAlign={"center"}>
          Monto del crédito
        </Typography>
        <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={500} textAlign={"center"}>
          {formatCurrency(applicationData.loan_amount)}{" "}
          <Typography variant="body3" fontWeight={500}>
            MXN
          </Typography>
        </Typography>
      </Grid>

      <Divider orientation="vertical" flexItem sx={{ mr: "-2px", border: "1px solid #F2704F" }} />

      <Grid item xs={6}>
        <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={400} textAlign={"center"}>
          Plazo
        </Typography>
        <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={500} textAlign={"center"}>
          {applicationData.loan_term || 0} meses
        </Typography>
      </Grid>
    </Grid>
  );
};
