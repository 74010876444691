/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Grid,
  Box,
  TextField,
  useMediaQuery,
  useTheme,
  Typography,
  Container,
  Autocomplete,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { RootState, useAppDispatch } from "@store/store";
import {
  setAccountUser,
  setConditionLoan,
  setTaxProductId,
  setLoanStatus,
  setTaxSystemType,
} from "@store/slices/registerSlice";
import { useSaveLeadDataMutation } from "@api/onboarding";
import { PayloadSaveLeadData } from "@interfaces/request";
import { calculateAmountMonthly } from "@helpers/funciones";
import { TerminosCondiciones } from "@components/TerminosCondiciones";
import { EMAIL, PASSWORD, HAS_SPECIAL_CHARACTERS, HAS_NUMBERS, HAS_UPPERCASE, HAS_LOWERCASE } from "@utils/expreciones";
import { signOut } from "aws-amplify/auth";
import { UUID } from "crypto";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";

const domainOptions = ["gmail.com", "outlook.com", "hotmail.com"];

const CrearCuenta = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();
  const location = useLocation();

  const application = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [saveLeadData] = useSaveLeadDataMutation();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: accountUser.email || "",
      password: accountUser.password || "",
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required("Campo requerido")
        .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
      password: yup.string().required("Campo requerido").matches(PASSWORD, "Formato Incorrecto"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        const payloadSaveLeadData: PayloadSaveLeadData = {
          email: values.email,
          amount: application.loan_amount,
          product_id: application.product_id,
          tax_system_id: application.tax_system_id,
          term: application.loan_term,
        };
        await saveLeadData(payloadSaveLeadData).unwrap();
        await signOut();
        dispatch(setLoanStatus("CSRUS"));
        dispatch(
          setAccountUser({
            id: 0,
            email: values.email,
            password: values.password,
          }),
        );

        console.log("redirect to registro_datos_personales");
        navigate("/producto/creditosimple/registro_datos_personales");
      } catch (error) {
        const api_error = error as ApiError;
        if (api_error.data) {
          if (api_error.data.code === "001") {
            formik.setFieldError("email", "Este correo ya esta registrado, intenta con otro o inicia sesión");
            return;
          }
        }

        dispatch(setModalErrorVisible({ open: true, error: api_error }));
      } finally {
        setSubmitting(false);
      }
    },
  });

  const isLengthValid = formik.values.password.length >= 8 && formik.values.password.length <= 20;
  const hasNumber = HAS_NUMBERS.test(formik.values.password);
  const hasSpecialChar = HAS_SPECIAL_CHARACTERS.test(formik.values.password);
  const hasUpperCase = HAS_UPPERCASE.test(formik.values.password);
  const hasLowerCase = HAS_LOWERCASE.test(formik.values.password);

  const getDomainSuggestions = (value: string): string[] => {
    const parts = value.split("@");
    if (parts.length > 1) {
      const enteredDomain = parts[1].toLowerCase();
      return domainOptions
        .filter((option) => option.startsWith(enteredDomain))
        .map((domain) => `${parts[0]}@${domain}`);
    }
    return [];
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const getQueryParams = () => {
      try {
        const searchParams = new URLSearchParams(location.search);
        const tax_system_id = searchParams.get("tax_system_id") as UUID;
        const product_id = searchParams.get("product_id") as UUID;
        const taxSystemType = searchParams.get("tax_system_type");
        const loanAmount = Number(searchParams.get("loan_amount"));
        const loanTerm = Number(searchParams.get("loan_term"));
        const sourceCode = String(searchParams.get("source_code") || "");
        const amountMonthly = calculateAmountMonthly(loanAmount, loanTerm);

        if (!tax_system_id || !product_id || !taxSystemType || !loanAmount || !loanTerm) {
          navigate("/login");
          return;
        }

        if (taxSystemType !== "PM" && taxSystemType !== "PFAE") {
          navigate("/login");
          return;
        }

        localStorage.removeItem("isLoggedOut");

        dispatch({ type: "CLEAN_STORE" });
        dispatch(setTaxSystemType(taxSystemType));
        dispatch(
          setTaxProductId({
            tax_system_id,
            product_id,
            source_code: sourceCode,
          }),
        );
        dispatch(
          setConditionLoan({
            loanAmount,
            loanTerm,
            amountMonthly,
          }),
        );
      } catch (error) {
        console.log("Ha ocurrido un error", error);
      }
    };

    getQueryParams();
  }, []);

  return (
    <Grid container height={"100%"}>
      <Grid item xs={12} md={8} mt={4}>
        <Box
          sx={{
            mt: isMobileOrTablet ? 1 : 2,
            mx: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isMobileOrTablet ? "h4" : "h3"}
            fontWeight={theme?.palette?.fontWeights?.bold}
            textAlign="center"
            color={theme?.palette?.primary?.dark}
          >
            Te damos la bienvenida
          </Typography>

          <Typography
            mt={isMobileOrTablet ? 2 : 3}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
          >
            Ingresa los datos para crear tu cuenta
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Box>
                <Autocomplete
                  freeSolo
                  options={getDomainSuggestions(formik.values.email)}
                  inputValue={formik.values.email}
                  onInputChange={(_e, newInputValue) => {
                    formik.setFieldValue("email", newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="email"
                      name="email"
                      label="Correo electrónico"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  )}
                  disabled={formik.isSubmitting}
                />
                <TextField
                  id="password"
                  name="password"
                  label="Contraseña"
                  type={showPassword ? "text" : "password"}
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                          sx={{ color: theme?.palette?.primary?.main }}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={formik.isSubmitting}
                />
              </Box>
              <Box mt={isMobileOrTablet ? 2 : 3}>
                <Typography variant={isMobileOrTablet ? "body2" : "body1"} fontWeight={500}>
                  Tu contraseña debe contener:
                </Typography>

                <Typography
                  mt={1}
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={isLengthValid ? "green" : "red"}
                  display="flex"
                  alignItems="center"
                >
                  {isLengthValid ? <CheckIcon /> : <CloseIcon />} &nbsp;Entre 8 y 20 caracteres
                </Typography>

                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={hasNumber ? "green" : "red"}
                  display="flex"
                  alignItems="center"
                >
                  {hasNumber ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter numérico
                </Typography>

                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={hasSpecialChar ? "green" : "red"}
                  display="flex"
                  alignItems="center"
                >
                  {hasSpecialChar ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter especial
                </Typography>

                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={hasUpperCase ? "green" : "red"}
                  display="flex"
                  alignItems="center"
                >
                  {hasUpperCase ? <CheckIcon /> : <CloseIcon />} &nbsp;Una mayúscula
                </Typography>

                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={hasLowerCase ? "green" : "red"}
                  display="flex"
                  alignItems="center"
                >
                  {hasLowerCase ? <CheckIcon /> : <CloseIcon />} &nbsp;Una minúscula
                </Typography>
              </Box>

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Box>
            </form>
            <Box sx={{ mt: 4, mb: 2 }}>
              <TerminosCondiciones />
            </Box>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.backgroundRight?.light }}>
            <CustomRightImage imageName="img_tofu_2" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default CrearCuenta;
