import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  useMediaQuery,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Hub } from "aws-amplify/utils";
import { signOut } from "aws-amplify/auth";
import { fetchAuthSession } from "aws-amplify/auth";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import iconInicioSession from "@assets/img/icon_menu_inicio_session.svg";
import iconCerrarSession from "@assets/img/icon_menu_cerrar_session.svg";
import iconCotizador from "@assets/img/icon_menu_cotizador.svg";
import iconPhone from "@assets/img/icon_menu_phone.svg";
import { useDispatch } from "react-redux";
import { setModalQuotationVisible } from "@store/slices/appSlice";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { getDynamicUrl } from "@helpers/funciones";
import { CustomReturnArrow } from "./CustomReturnArrow";

export const Navbar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const logo = getDynamicUrl("Logo.svg");
  const logoMenu = getDynamicUrl("icon_logo_menu.svg");

  const dispatch = useDispatch();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const channel = new BroadcastChannel("session");

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  Hub.listen("auth", (data) => {
    switch (data.payload.event) {
      case "signedIn":
        setAuthenticated(true);
        break;
    }
  });

  const handleOnClicSignInOut = async () => {
    if (authenticated) {
      await signOut();
      sessionStorage.clear();
      channel.postMessage("logout");
      document.body.style.backgroundColor = "";
    } else {
      document.body.style.backgroundColor = "";
      navigate("/login");
    }
  };

  const handleOnClick = () => {
    if (location.pathname === "/login") {
      window.location.href = "https://dev-landing.lenditgroup.com/";
    }
  };

  useEffect(() => {
    fetchAuthSession().then((value) => {
      setAuthenticated(value.tokens !== undefined);
    });
  }, []);

  const handleOnClickShowQuotation = () => {
    dispatch(setModalQuotationVisible(true));
  };

9

  channel.onmessage = async (event) => {
    if (event.data === "logout") {
      navigate("/login");
    }
  };

  return (
    <AppBar position="fixed" sx={{ bgcolor: "#fff", py: "5px" }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box>
          <CustomReturnArrow/>
          <Box component="img" onClick={handleOnClick} src={logo} alt="logo" sx={{ height: 25 }} />
        </Box>

        {location.pathname !== "/login" ? (
          isMobileOrTablet ? (
            <>
              <IconButton
                size="large"
                edge="end"
                color="default"
                aria-label="menu"
                sx={{ color: theme?.palette?.primary?.main, ml: 2 }}
                onClick={() => toggleDrawer(true)}
              >
                <MenuIcon sx={{ fontSize: 35, fontWeight: "bold" }} />
              </IconButton>

              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={() => toggleDrawer(false)}
                PaperProps={{
                  sx: {
                    width: "230px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    backgroundColor: "#fff",
                    borderBottomLeftRadius: "25px",
                    borderTopLeftRadius: "25px",
                  },
                }}
              >
                <IconButton onClick={() => toggleDrawer(false)} sx={{ alignSelf: "flex-end", mt: 1 }}>
                  <CloseIcon sx={{ color: theme?.palette?.primary?.light }} />
                </IconButton>
                <Box sx={{ textAlign: "left", marginBottom: "20px", marginLeft: "10px" }}>
                  <Box component="img" src={logoMenu} alt="logo" sx={{ height: 40, marginBottom: "5px" }} />
                </Box>
                <List
                  sx={{
                    textAlign: "center",
                    marginTop: "10px",
                    color: "#000",
                    fontWeight: theme?.palette?.fontWeights?.light,
                    fontSize: "16px",
                  }}
                >
                  {authenticated ? (
                    <ListItem
                      onClick={handleOnClicSignInOut}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                          color: theme?.palette?.primary?.main,
                        },
                      }}
                    >
                      <ListItemIcon>
                        <Box component="img" src={iconCerrarSession} alt="logo" />
                      </ListItemIcon>
                      <ListItemText primary="Cerrar sesión" />
                    </ListItem>
                  ) : (
                    <ListItem
                      onClick={() => navigate("/login")}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                          color: theme?.palette?.primary?.main,
                        },
                      }}
                    >
                      <ListItemIcon>
                        <Box component="img" src={iconInicioSession} alt="logo" />
                      </ListItemIcon>
                      <ListItemText primary="Iniciar sesión" />
                    </ListItem>
                  )}

                  {!authenticated && (
                    <ListItem
                      onClick={() => {
                        window.location.href = `${import.meta.env.VITE_URL_COTIZADOR_HOME}/#cotizador`;
                      }}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                          color: theme?.palette?.primary?.main,
                        },
                      }}
                    >
                      <ListItemIcon>
                        <Box component="img" src={iconCotizador} alt="logo" />
                      </ListItemIcon>
                      <ListItemText primary="Cotizar" />
                    </ListItem>
                  )}

                  <ListItem
                    onClick={() => navigate("/contacto")}
                    sx={{
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "#f0f0f0",
                        color: theme?.palette?.primary?.main,
                      },
                    }}
                  >
                    <ListItemIcon>
                      <Box component="img" src={iconPhone} alt="logo" />
                    </ListItemIcon>
                    <ListItemText primary="Contactanos" />
                  </ListItem>

                  {authenticated && (
                    <ListItem
                      onClick={handleOnClickShowQuotation}
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#f0f0f0",
                          color: theme?.palette?.primary?.main,
                        },
                      }}
                    >
                      <ListItemIcon>
                        <AttachMoneyIcon sx={{ color: "#000", fontSize: "30px" }} />
                      </ListItemIcon>
                      <ListItemText primary=" Modifica tu cotización" />
                    </ListItem>
                  )}
                </List>
              </Drawer>
            </>
          ) : (
            <Box sx={{ display: "flex", ml: "auto", gap: 2 }}>
              {authenticated && (
                <Button
                  variant="blue_outlined"
                  onClick={handleOnClickShowQuotation}
                  sx={{ fontSize: "13px", fontWeight: theme?.palette?.fontWeights?.semibold }}
                >
                  Modifica tu cotización
                </Button>
              )}

              <Button onClick={handleOnClicSignInOut} variant="default">
                {authenticated ? "Cerrar sesión" : "Iniciar sesión"}
              </Button>
            </Box>
          )
        ) : (
          <></>
        )}
      </Toolbar>
    </AppBar>
  );
};
