/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Checkbox,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
  TextField,
} from "@mui/material";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  useLazyGetPaymentDetailsQuery,
  useLazyGetPaymentMethodByIdQuery,
  usePostPaymentDetailsMutation,
  usePostPaymentMethodMutation,
  usePutPaymentDetailsMutation,
} from "@api/applications";
import { RootState } from "@store/store";
import { useGetPaymentMethodQuery } from "@api/catalogs";
import CustomRadioGroup from "@components/CustomRadioGroup";
import { InactivityHandler } from "@components/InactivityHandler";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";

const InformacionTransaccional = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const companyData = useSelector((state: RootState) => state.bofu.digital_file.company);
  const applicationData = useSelector((state: RootState) => state.register.application);

  const paymentsMethodCatalog = useGetPaymentMethodQuery();
  const [triggerPaymentMethod] = usePostPaymentMethodMutation();
  const [triggerPaymentDetails] = usePostPaymentDetailsMutation();
  const [triggerPutPaymentDetails] = usePutPaymentDetailsMutation();
  const [getDetalles, { data, isSuccess }] = useLazyGetPaymentDetailsQuery();
  const [getMetodoByApplication, { data: dataMetodoAplication, isSuccess: isSuccessByApp }] =
    useLazyGetPaymentMethodByIdQuery();

  const [showOtherPaymentField, setShowOtherPaymentField] = useState(false);
  const [idOther, setIdOther] = useState("");
  const [tercero, setTercero] = useState(null);
  const [pagos, setPagos] = useState(null);
  const [idCPIOT, setIdCPIOT] = useState("");
  const [idDetalle, setIdDetalle] = useState("");

  const formik = useFormik({
    initialValues: {
      paymentMethods: [] as string[],
      otherPaymentMethod: "",
      advancePayments: "",
      resourcesTypes: "",
      otro: false,
    },
    validationSchema: yup.object().shape({
      paymentMethods: yup.array().min(1).of(yup.string().required()).required(),
      otro: yup.boolean(),
      otherPaymentMethod: yup.string().when("otro", {
        is: true,
        then: (schema) => schema.required("Este campo es requerido cuando la opción otro esta seleccionada"),
        otherwise: (schema) => schema.notRequired(),
      }),
      advancePayments: yup.string().required("Campo requerido"),
      resourcesTypes: yup.string().required("Campo requerido"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await triggerPaymentMethod({
          solicitudId: applicationData.id,
          body: {
            payment_methods: values.paymentMethods.map((id) => ({
              payment_method_id: id,
              other_payment_method: id === idOther ? values.otherPaymentMethod : null,
            })),
          },
        }).unwrap();

        const body = {
          advance_payments: values.advancePayments === "Si",
          own_account: false,
          own_resources: values.resourcesTypes === "Propios",
        };

        if (idDetalle) {
          await triggerPutPaymentDetails({
            solicitudId: applicationData.id,
            detailId: idDetalle,
            body,
          }).unwrap();
        } else {
          await triggerPaymentDetails({
            solicitudId: applicationData?.id,
            body,
          }).unwrap();
        }
        navigate("/producto/creditosimple/solicitud_credito_3");
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    getDetalles({ solicitudId: String(applicationData.id) });
  }, [applicationData.id]);

  useEffect(() => {
    if (data && isSuccess) {
      setIdDetalle(String(data?.data?.id));
      setTercero(data?.data?.own_resources);
      setPagos(data?.data?.advance_payments);
    }
  }, [data, isSuccess]);

  useEffect(() => {
    if (applicationData.id) {
      getMetodoByApplication({ solicitudId: applicationData.id });
    }
  }, [applicationData]);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        formik.setFieldValue("resourcesTypes", tercero ? "Propios" : "Tercero");
        formik.setFieldValue("advancePayments", pagos ? "Si" : "No");
      }, 1000);
    }
  }, [pagos, tercero, isSuccess]);

  useEffect(() => {
    if (paymentsMethodCatalog.isSuccess) {
      const cpiotPaymentMethod = paymentsMethodCatalog.data?.find((item) => item.code === "CPIOT");
      setIdCPIOT(String(cpiotPaymentMethod?.id));
    }
  }, [paymentsMethodCatalog]);

  useEffect(() => {
    if (idCPIOT) {
      setIdOther(idCPIOT);
      setShowOtherPaymentField(formik.values.paymentMethods.includes(idCPIOT));
    }
  }, [formik.values.paymentMethods]);

  useEffect(() => {
    formik.setFieldValue("otro", showOtherPaymentField);
  }, [showOtherPaymentField]);

  useEffect(() => {
    if (!formik.values.otro) {
      formik.setFieldValue("otherPaymentMethod", "");
    }
  }, [formik.values.otro]);

  useEffect(() => {
    const transactionalInfo = companyData?.loan_application?.transactional_info;
    const details = transactionalInfo?.details;

    formik.setFieldValue(
      "advancePayments",
      details?.advance_payments === undefined ? "" : details.advance_payments ? "Si" : "No",
    );
    formik.setFieldValue(
      "resourcesTypes",
      details?.own_resources === undefined ? "" : details.own_resources ? "Propios" : "Tercero",
    );
  }, [companyData]);

  useEffect(() => {
    if (isSuccessByApp && dataMetodoAplication?.data.length > 0 && isSuccess) {
      const paymentMethodIds = dataMetodoAplication?.data?.map((item) => item.payment_method?.id) ?? [];
      const paymentMethodInput = dataMetodoAplication?.data?.find((item) => item.payment_method?.id === idCPIOT);
      formik.setFieldValue("paymentMethods", paymentMethodIds);
      formik.setFieldValue(
        "otherPaymentMethod",
        paymentMethodInput?.other_payment_method !== "" ? paymentMethodInput?.other_payment_method : "",
      );
    }
  }, [isSuccess, isSuccessByApp, dataMetodoAplication, idCPIOT]);

  const handleOnClickRestart = () => {
    navigate("/producto/creditosimple/avance_expediente_digital");
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box maxWidth="sm" mx="auto" mt={4} px={2}>
          <Typography
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={700}
            color={theme?.palette?.primary?.dark}
          >
            Solicitud de crédito
          </Typography>

          <Typography
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            mt={2}
          >
            <strong>2</strong> de 3
          </Typography>

          <Typography
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            fontWeight={600}
            mt={3}
          >
            Información transaccional
          </Typography>

          <form onSubmit={formik.handleSubmit} noValidate>
            <Box textAlign={"left"} my={2}>
              <Typography variant="body1" color={theme?.palette?.primary?.dark}>
                ¿De qué forma realizarás tus pagos?
              </Typography>
              <FormGroup>
                {paymentsMethodCatalog.isSuccess && paymentsMethodCatalog.data ? (
                  paymentsMethodCatalog.data.map((paymentMethod, index) => (
                    <FormControlLabel
                      key={index}
                      color={theme?.palette?.primary?.dark}
                      control={
                        <Checkbox
                          key={paymentMethod.id}
                          name="paymentMethods"
                          checked={formik.values.paymentMethods.includes(
                            paymentMethod.id as `${string}-${string}-${string}-${string}-${string}`,
                          )}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={paymentMethod.id}
                        />
                      }
                      label={paymentMethod.description}
                    />
                  ))
                ) : (
                  <>
                    <Skeleton variant="text" width={300} height={40}></Skeleton>
                    <Skeleton variant="text" width={300} height={40}></Skeleton>
                    <Skeleton variant="text" width={300} height={40}></Skeleton>
                    <Skeleton variant="text" width={300} height={40}></Skeleton>
                  </>
                )}
              </FormGroup>
              {/* Mostrar campo de texto si el método de pago "CPIOT" está seleccionado */}
              {showOtherPaymentField && (
                <TextField
                  fullWidth
                  id="otherPaymentMethod"
                  name="otherPaymentMethod"
                  label="Especifica el método de pago"
                  value={formik.values.otherPaymentMethod}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.otherPaymentMethod && Boolean(formik.errors.otherPaymentMethod)}
                  helperText={formik.touched.otherPaymentMethod && formik.errors.otherPaymentMethod}
                  margin="normal"
                />
              )}
            </Box>
            <Box textAlign={"left"} my={2}>
              <CustomRadioGroup
                label={"¿Realizarás pagos anticipados?"}
                name={"advancePayments"}
                value={formik.values.advancePayments}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.advancePayments && Boolean(formik.errors.advancePayments)}
                helperText={formik.touched.advancePayments && formik.errors.advancePayments}
                options={[
                  {
                    label: "Si",
                    value: "Si",
                  },
                  {
                    label: "No",
                    value: "No",
                  },
                ]}
              />
            </Box>
            <Box textAlign={"left"} my={2}>
              <CustomRadioGroup
                label={"¿Los recursos son propios o de un tercero?"}
                name={"resourcesTypes"}
                value={formik.values.resourcesTypes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.resourcesTypes && Boolean(formik.errors.resourcesTypes)}
                helperText={formik.touched.resourcesTypes && formik.errors.resourcesTypes}
                options={[
                  {
                    label: "Propios",
                    value: "Propios",
                  },
                  {
                    label: "De un tercero",
                    value: "Tercero",
                  },
                ]}
              />
            </Box>

            <Grid container columnSpacing={1} my={4}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="blue_outlined"
                  sx={{ padding: "0", height: "100%" }}
                  onClick={handleOnClickRestart}
                  disabled={formik.isSubmitting}
                >
                  Regresar al inicio
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  type="submit"
                  variant="default"
                  disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_6" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default InformacionTransaccional;
