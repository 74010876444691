import { ApiError } from "@interfaces/response";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UploadModalError {
  visible: boolean;
  file_name: string;
}

export interface QuotationModal {
  visible: boolean;
}

export interface ErrosModal {
  visible: boolean;
  type: string;
}

export interface AppSlice {
  process_page: string;
  upload_modal_error: UploadModalError;
  modal_quotation: QuotationModal;
  modal_error: ErrosModal;
}

const initialState: AppSlice = {
  process_page: "",
  upload_modal_error: {
    visible: false,
    file_name: "",
  },
  modal_quotation: {
    visible: false,
  },
  modal_error: {
    visible: false,
    type: "",
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUploadModalErrorVisible: (state, action: PayloadAction<UploadModalError>) => {
      state.upload_modal_error = action.payload;
    },
    setModalQuotationVisible: (state, action: PayloadAction<boolean>) => {
      state.modal_quotation.visible = action.payload;
    },
    setProcessPage: (state, action: PayloadAction<string>) => {
      state.process_page = action.payload;
    },
    setModalErrorVisible: (state, action: PayloadAction<{ open: boolean; error: ApiError | null }>) => {
      state.modal_error.visible = action.payload.open;
      state.modal_error.type = (action.payload.error?.status || 500).toString();
    },
  },
});

export const { setUploadModalErrorVisible, setModalQuotationVisible, setProcessPage, setModalErrorVisible } =
  appSlice.actions;

export default appSlice.reducer;
