/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, Link, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { RootState, useAppDispatch } from "@store/store";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import { useLazyGetBanksByCodeQuery } from "@api/catalogs";
import {
  useDeleteBankAccountMutation,
  useLazyGetBankAccountsQuery,
  usePostBankAccountsMutation,
  usePutApplicationCompletedMutation,
  usePutBankAccountMutation,
} from "@api/applications";
import { useLazyGetDocumentAppIdQuery, usePatchDocumentMutation } from "@api/documents";
import { CustomDialog } from "@components/index";
import { InactivityHandler } from "@components/InactivityHandler";
import { UUID } from "crypto";
import { BankAccount, DocumentResponse, PersonaStructure } from "@interfaces/response";
import { PayloadBankAccount } from "@interfaces/request";
import { ApiError } from "@interfaces/response";
import { usePatchPersonMutation, useLazyGetPersonsQuery } from "@api/personasApi";
import { DOCUMENTS_CATALOG, PERSON_CODES_AVAL } from "@helpers/constantes";
import CustomRightImage from "@components/CustomRightImage";
import { setModalErrorVisible } from "@store/slices/appSlice";


const MAX_ACCOUNTS_ALLOWED = 5;

interface InformacionBancariaProps {
  type: "EMPRESA" | "AVAL";
}

interface BankAccountProps {
  id: string | undefined;
  clabe: string;
  bank_id: UUID | string;
  bank_name: string;
  bank_statment_first_documents_id: string;
  bank_statment_second_documents_id: string;
  bank_statment_third_documents_id: string;
}

const InformacionBancaria = (props: InformacionBancariaProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const applicationData = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);

  const [listDocuments, setListDocuments] = useState<DocumentResponse[]>([]);
  const [acountToDelete, setAcountToDelete] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [aval, setAval] = useState<PersonaStructure | null>(null);
  const emptyBankAccount: BankAccountProps = {
    id: "",
    clabe: "",
    bank_id: "",
    bank_name: "",
    bank_statment_first_documents_id: "",
    bank_statment_second_documents_id: "",
    bank_statment_third_documents_id: "",
  };

  const handleOnClickRestart = () => {
    if (props.type === "EMPRESA") {
      navigate("/producto/creditosimple/avance_expediente_digital");
    } else {
      navigate("/producto/creditosimple/docs_aval");
    }
  };

  const [triggerGetBanks] = useLazyGetBanksByCodeQuery();
  const [getBankAccounts] = useLazyGetBankAccountsQuery();
  const [deleteBankAccount] = useDeleteBankAccountMutation();
  const [getDocumentsByAppId] = useLazyGetDocumentAppIdQuery();
  const [triggerPutBankAccount] = usePutBankAccountMutation();
  const [triggerPostBankAccount] = usePostBankAccountsMutation();
  const [triggerPatchDocument] = usePatchDocumentMutation();
  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();
  const [patchPersonData] = usePatchPersonMutation();
  const [getPersons] = useLazyGetPersonsQuery();

  const formik = useFormik({
    initialValues: {
      accounts: [{ ...emptyBankAccount }],
    },
    validationSchema: yup.object().shape({
      accounts: yup.array().of(
        yup.object().shape({
          clabe: yup
            .string()
            .min(18, "Formato incorrecto, debe tener 18 dígitos")
            .required("Campo requerido.")
            .test("unique", "La cuenta bancaria ya se encuentra registrada.", function (value) {
              const num_results: number = formik.values.accounts.filter((item: any) => item.clabe === value).length;
              return num_results > 1 ? false : true;
            }),
          bank_id: yup.string().required("Clabe bancaria incorrecta, validar nuevamente."),
          bank_statment_first_documents_id: yup.string().required("Campo requerido."),
          bank_statment_second_documents_id:
            props.type === "EMPRESA" ? yup.string().required("Campo requerido") : yup.string(),
          bank_statment_third_documents_id:
            props.type === "EMPRESA" ? yup.string().required("Campo requerido") : yup.string(),
        }),
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        for (let index = 0; index < values.accounts.length; index++) {
          const account = values.accounts[index];
          const payload: PayloadBankAccount = {
            loan_application_id: applicationData.id,
            client_details_id: props.type === "EMPRESA" ? personalData.id : null,
            person_id: props.type === "EMPRESA" ? null : aval?.id,
            account: 0,
            account_holder: "",
            clabe: account.clabe,
            bank_id: account.bank_id as UUID,
            bank_statment_first_documents_id: account.bank_statment_first_documents_id,
            bank_statment_second_documents_id: props.type === "EMPRESA" ? account.bank_statment_second_documents_id : null,
            bank_statment_third_documents_id: props.type === "EMPRESA" ? account.bank_statment_third_documents_id : null,
          };

          if (account.id) {
            await triggerPutBankAccount({
              solicitudId: applicationData.id,
              bankAccountId: account.id,
              body: payload,
            }).unwrap();
          } else {
            await triggerPostBankAccount({
              solicitudId: applicationData.id,
              body: payload,
            }).unwrap();
          }
        }

        if (props.type === "EMPRESA") {
          await putAapplicationCompleted({
            id_sol: applicationData.id,
            body: {
              bank_information_completed: true,
            },
          }).unwrap();
        } else {
          await patchPersonData({
            personId: aval?.id as UUID,
            body: {
              bank_references_completed: true,
            },
          }).unwrap();
        }

        handleOnClickRestart();
      } catch (error) {
        console.log("Error al guardar la información bancaria:", error);
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setSubmitting(false);
      }
    },
    enableReinitialize: true,
    validateOnMount: true,
  });

  const handleOnAddAccountBank = () => {
    if (formik.values.accounts.length >= MAX_ACCOUNTS_ALLOWED) return;
    formik.setFieldValue("accounts", [...formik.values.accounts, { ...emptyBankAccount }]);
  };

  const getBankNameByCode = useCallback(
    async (bank_code: string) => {
      try {
        const bank_info = await triggerGetBanks(bank_code).unwrap();
        if (bank_info) {
          return {
            id: bank_info.id,
            name: bank_info.name,
          };
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error al obtener el banco:", error);
        return null;
      }
    },
    [listDocuments],
  );

  const handleChangeCLABE = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    const only_numbers = value.replace(/[^0-9]/g, "");
    formik.setFieldValue(name, only_numbers);

    const bank_code = only_numbers.substring(0, 3);
    const bank_info = bank_code.length === 3 ? await getBankNameByCode(bank_code) : null;
    if (bank_info) {
      formik.setFieldValue(`accounts[${index}].bank_id`, bank_info?.id || "");
      formik.setFieldValue(`accounts[${index}].bank_name`, bank_info?.name || "");
    } else {
      formik.setFieldValue(`accounts[${index}].bank_id`, "");
      formik.setFieldValue(`accounts[${index}].bank_name`, "");
    }
  };

  const handleOnOpenModal = (index: number) => {
    setAcountToDelete(index);
  };

  const handleOnCloseModal = () => {
    setAcountToDelete(undefined);
  };

  const handleDeleteAccount = async () => {
    try {
      setIsDeleting(true);
      if (acountToDelete === undefined) return;

      const account_to_delete = formik.values.accounts[acountToDelete];

      if (!account_to_delete) {
        setAcountToDelete(undefined);
        return;
      }

      const delete_promises: Promise<any>[] = [];

      if (account_to_delete.bank_statment_first_documents_id) {
        delete_promises.push(
          triggerPatchDocument({
            documentId: account_to_delete.bank_statment_first_documents_id as UUID,
            body: {
              status: false,
            },
          }),
        );
      }

      if (account_to_delete.bank_statment_second_documents_id) {
        delete_promises.push(
          triggerPatchDocument({
            documentId: account_to_delete.bank_statment_second_documents_id as UUID,
            body: {
              status: false,
            },
          }),
        );
      }

      if (account_to_delete.bank_statment_third_documents_id) {
        delete_promises.push(
          triggerPatchDocument({
            documentId: account_to_delete.bank_statment_third_documents_id as UUID,
            body: {
              status: false,
            },
          }),
        );
      }

      if (account_to_delete.id) {
        delete_promises.push(
          deleteBankAccount({
            solicitudId: applicationData.id,
            accountId: account_to_delete.id,
          }),
        );
      }

      await Promise.all(delete_promises);

      const new_accounts = formik.values.accounts.filter((_, index) => index !== acountToDelete);
      formik.setFieldValue("accounts", new_accounts);
      setAcountToDelete(undefined);
    } catch (error) {
      console.log("Error al eliminar la cuenta bancaria:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const list_bank_accounts: BankAccount[] = await getBankAccounts(applicationData.id).unwrap();
        let bank_accounts: BankAccount[] = [];
        let documents: DocumentResponse[] = [];

        if (props.type === "EMPRESA") {
          bank_accounts = list_bank_accounts.filter((item) => item.client_details_id === personalData.id);
          documents = await getDocumentsByAppId({
            loanApplicationId: applicationData.id,
            clientDetailsId: personalData.id,
            status: true,
          }).unwrap();
        } else {
          const res_avales = await getPersons({
            loanApplicationId: applicationData.id,
            personTypeCode: PERSON_CODES_AVAL,
          }).unwrap();

          const aval_selected = res_avales[0];
          if (!aval_selected) {
            navigate("/producto/creditosimple/docs_aval");
            return;
          }

          bank_accounts = list_bank_accounts.filter((item) => item.person_id === aval_selected.id);

          documents = await getDocumentsByAppId({
            loanApplicationId: applicationData.id,
            personId: aval_selected.id,
            status: true,
          }).unwrap();

          setAval(aval_selected);
        }

        const accounts: BankAccountProps[] = [];

        for (let index = 0; index < bank_accounts.length; index++) {
          const cuenta_clabe = (bank_accounts[index].clabe || "").toString().padStart(18, "0");
          const bank_code = cuenta_clabe.toString().substring(0, 3);
          const bank_info = await getBankNameByCode(bank_code);

          accounts.push({
            id: bank_accounts[index].id,
            clabe: cuenta_clabe,
            bank_id: bank_info?.id || "",
            bank_name: bank_info?.name || "",
            bank_statment_first_documents_id: bank_accounts[index].bank_statment_first_documents_id,
            bank_statment_second_documents_id: bank_accounts[index].bank_statment_second_documents_id,
            bank_statment_third_documents_id: bank_accounts[index].bank_statment_third_documents_id,
          });
        }

        setListDocuments(documents);

        if (accounts.length) {
          formik.setFieldValue("accounts", accounts);
        }
      } catch (error) {
        console.log("Error al obtener la información bancaria:", error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box maxWidth="sm" mx="auto" mt={4} px={2}>
            <Typography
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={700}
              color={theme?.palette?.primary?.dark}
            >
              Información bancaria
            </Typography>

            <Typography
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              color={theme?.palette?.primary?.dark}
              mt={2}
            >
              <strong>1</strong> de 1
            </Typography>

            <Typography
              textAlign={"center"}
              color={theme?.palette?.primary?.main}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={600}
              mt={2}
            >
              {props.type == "EMPRESA"
                ? "Ingresa los datos de la cuenta en dónde concentras la mayor facturación de tu negocio, a esta cuenta depositaremos tu crédito"
                : "Ingresa los datos de tu cuenta principal, dónde concentras la mayoría de tu actividad bancaría."}
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              {formik.values.accounts.map((account: any, index: number) => (
                <Box key={index} mt={index > 0 ? 4 : 0}>
                  <TextField
                    name={`accounts[${index}].clabe`}
                    label="Cuenta CLABE"
                    margin="normal"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeCLABE(event, index)}
                    value={formik.values.accounts[index].clabe}
                    error={
                      formik.touched.accounts?.[index]?.clabe &&
                      Boolean((formik.errors.accounts as any)?.[index]?.clabe)
                    }
                    helperText={
                      formik.touched.accounts?.[index]?.clabe
                        ? (formik.errors.accounts as any)?.[index]?.clabe ||
                          (formik.errors.accounts as any)?.[index]?.bank_id
                        : ""
                    }
                    inputProps={{ maxLength: 18 }}
                    disabled={isLoading || formik.isSubmitting}
                  />

                  <TextField
                    name={`accounts[${index}].bank_name`}
                    label="Banco"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.accounts[index].bank_name}
                    error={
                      formik.touched.accounts?.[index]?.bank_name &&
                      Boolean((formik.errors.accounts as any)?.[index]?.bank_name)
                    }
                    helperText={
                      formik.touched.accounts?.[index]?.bank_name
                        ? (formik.errors.accounts as any)?.[index]?.bank_name
                        : ""
                    }
                    disabled={true}
                  />

                  {props.type === "AVAL" ? (
                    <CustomUploadFileV2
                      mt={2}
                      inputName={`accounts[${index}].bank_statment_first_documents_id`}
                      uploadedFile={
                        listDocuments.find((item) => item.id === account.bank_statment_first_documents_id) || undefined
                      }
                      setUploadedFile={(newUpload) => {
                        formik.setFieldValue(
                          `accounts[${index}].bank_statment_first_documents_id`,
                          newUpload?.id || "",
                        );

                        if (newUpload) {
                          setListDocuments((prev) => [...prev, newUpload]);
                        }
                      }}
                      optionalName="Estado de cuenta bancario"
                      optionalDescription="Debes cargar un estado de cuenta bancarios de los últimos 3 meses"
                      metadata={{
                        fileCode: DOCUMENTS_CATALOG.estado_cuenta_bancario.code,
                        uploadType: "person",
                        personId: aval?.id as UUID,
                        clientDetailsId: personalData.id,
                        personType: "accionista_mayoritario",
                      }}
                      disabled={isLoading || formik.isSubmitting}
                      required
                    />
                  ) : (
                    <>
                      <CustomUploadFileV2
                        mt={2}
                        inputName={`accounts[${index}].bank_statment_first_documents_id`}
                        uploadedFile={
                          listDocuments.find((item) => item.id === account.bank_statment_first_documents_id) ||
                          undefined
                        }
                        setUploadedFile={(newUpload) => {
                          formik.setFieldValue(
                            `accounts[${index}].bank_statment_first_documents_id`,
                            newUpload?.id || "",
                          );

                          if (newUpload) {
                            setListDocuments((prev) => [...prev, newUpload]);
                          }
                        }}
                        optionalName="1er estado de cuenta bancario"
                        optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
                        metadata={{
                          fileCode: DOCUMENTS_CATALOG.estado_cuenta_bancario.code,
                          uploadType: "client",
                          clientDetailsId: personalData.id,
                        }}
                        disabled={isLoading || formik.isSubmitting}
                        required
                      />

                      <CustomUploadFileV2
                        mt={2}
                        inputName={`accounts[${index}].bank_statment_second_documents_id`}
                        uploadedFile={
                          listDocuments.find((item) => item.id === account.bank_statment_second_documents_id) ||
                          undefined
                        }
                        setUploadedFile={(newUpload) => {
                          if (newUpload) {
                            setListDocuments((prev) => [...prev, newUpload]);
                          } else {
                            setListDocuments((prev) =>
                              prev.filter((item) => item.id !== account.bank_statment_second_documents_id),
                            );
                          }
                          formik.setFieldValue(`accounts[${index}].bank_statment_second_documents_id`, newUpload?.id);
                        }}
                        optionalName="2do estado de cuenta bancario"
                        optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
                        metadata={{
                          fileCode: DOCUMENTS_CATALOG.estado_cuenta_bancario.code,
                          uploadType: "client",
                          clientDetailsId: personalData.id,
                        }}
                        disabled={isLoading || formik.isSubmitting}
                        required
                      />

                      <CustomUploadFileV2
                        mt={2}
                        inputName={`accounts[${index}].bank_statment_third_documents_id`}
                        uploadedFile={
                          listDocuments.find((item) => item.id === account.bank_statment_third_documents_id) ||
                          undefined
                        }
                        setUploadedFile={(newUpload) => {
                          if (newUpload) {
                            setListDocuments((prev) => [...prev, newUpload]);
                          } else {
                            setListDocuments((prev) =>
                              prev.filter((item) => item.id !== account.bank_statment_third_documents_id),
                            );
                          }
                          formik.setFieldValue(`accounts[${index}].bank_statment_third_documents_id`, newUpload?.id);
                        }}
                        optionalName="3er estado de cuenta bancario"
                        optionalDescription="Debes cargar los estados de cuenta bancarios de los últimos 3 meses"
                        metadata={{
                          fileCode: DOCUMENTS_CATALOG.estado_cuenta_bancario.code,
                          uploadType: "client",
                          clientDetailsId: personalData.id,
                        }}
                        disabled={isLoading || formik.isSubmitting}
                        required
                      />
                    </>
                  )}

                  {index > 0 && (
                    <Box textAlign={"left"} my={2} mt={2}>
                      <Link onClick={() => handleOnOpenModal(index)} sx={{ cursor: "pointer" }}>
                        <Typography variant="body1" fontWeight={400}>
                          - Eliminar cuenta bancaria
                        </Typography>
                      </Link>
                    </Box>
                  )}
                </Box>
              ))}

              {props.type === "EMPRESA" && (
                <Box textAlign={"left"} my={2}>
                  <Link
                    onClick={handleOnAddAccountBank}
                    sx={{
                      cursor: formik.values.accounts.length >= MAX_ACCOUNTS_ALLOWED ? "not-allowed" : "pointer",
                      opacity: formik.values.accounts.length >= MAX_ACCOUNTS_ALLOWED ? 0.5 : 1,
                    }}
                  >
                    <Typography variant="body1" fontWeight={400}>
                      + Agregar cuenta bancaria
                    </Typography>
                  </Link>
                </Box>
              )}

              {props.type === "EMPRESA" && (
                <Box my={3} px={3}>
                  <Typography
                    variant={isMobileOrTablet ? "body3" : "body2"}
                    fontWeight={600}
                    color={theme?.palette?.primary?.dark}
                    textAlign={"center"}
                  >
                    Si la cuenta refleja menos del 50% de los ingresos agrega otra cuenta bancaria hasta llegar a este
                    porcentaje
                  </Typography>
                </Box>
              )}

              <Grid container columnSpacing={1} my={4}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="blue_outlined"
                    sx={{ padding: "0px", height: "100%" }}
                    onClick={handleOnClickRestart}
                    disabled={isLoading || formik.isSubmitting}
                  >
                    Regresar al inicio
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="default"
                    disabled={isLoading || !formik.isValid || formik.isSubmitting}
                  >
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <CustomRightImage imageName="img_bofu_14" />
            </Box>
          </Grid>
        )}
      </Grid>

      <CustomDialog
        colorClose={theme?.palette?.primary?.light}
        open={acountToDelete !== undefined}
        onClose={handleOnCloseModal}
        itemTitle={<></>}
        itemContent={
          <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            <Typography
              textAlign={"center"}
              color={theme?.palette?.primary?.dark}
              fontWeight={600}
              variant={isMobileOrTablet ? "h5" : "h6"}
            >
              ¿Estás seguro de eliminar la cuenta bancaria?
            </Typography>
            <Box display={"flex"} columnGap={2} justifyContent={"center"} mt={3}>
              <Button
                variant="blue_outlined"
                sx={{ py: 0, px: 1, width: "110px" }}
                onClick={handleOnCloseModal}
                disabled={isDeleting}
              >
                Regresar
              </Button>

              <Button
                variant="default"
                sx={{ width: "110px" }}
                onClick={() => handleDeleteAccount()}
                disabled={isDeleting}
              >
                Eliminar
              </Button>
            </Box>
          </Box>
        }
      />
    </>
  );
};

export default InformacionBancaria;
