import { useMemo } from "react";
import { getConfigName } from "@helpers/funciones";

const useBaseDomain = (url?: string): string | null => {
  return useMemo(() => {
    return getConfigName(url || window.location.href);
  }, [url]);
};

export default useBaseDomain;
