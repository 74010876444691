/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useLazyGetByCpQuery } from "@api/postalCodes";

import { useLazyGetPersonByIdQuery, usePatchPersonMutation } from "@api/personasApi";
import { useLazyGetAddressQuery, usePatchAddressMutation, usePostAddressMutation } from "@api/address";
import {
  useLazyGetAddressTypeQuery,
  useLazyGetStatesQuery,
  useLazyGetCivilStatusQuery,
  useLazyGetMatrimonialRegimesQuery,
} from "@api/catalogs";
import dayjs from "dayjs";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { AddressData, CivilStatus, MatrimonialRegimesData } from "@interfaces/index";
import { CODIGO_POSTAL } from "@utils/expreciones";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomDatePicker from "@components/CustomDatePicker";
import { ADDRESS_CODES } from "@helpers/constantes";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";

const DatosPropietarioRealPart1: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const ownerId = useSelector((state: RootState) => state.bofu.digital_file.current_owner_id);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const [getPersona] = useLazyGetPersonByIdQuery();
  const [getAddressType] = useLazyGetAddressTypeQuery();
  const [patchPerson] = usePatchPersonMutation();
  const [getAddressByPerson] = useLazyGetAddressQuery();
  const [postAddress] = usePostAddressMutation();
  const [patchAddress] = usePatchAddressMutation();
  const [getRegimenMatrimonial] = useLazyGetMatrimonialRegimesQuery();
  const [getEstadoCivil] = useLazyGetCivilStatusQuery();
  const [getEstados] = useLazyGetStatesQuery();
  const [getByCp] = useLazyGetByCpQuery();

  const [address, setAddress] = useState<AddressData | undefined>(undefined);
  const [addressTypeParticularId, setAddressTypeParticularId] = useState("");
  const [estadoCivilCasadoId, setEstadoCivilCasadoId] = useState<string>("");
  const [listaRegimenMatrimonial, setListaRegimenMatrimonial] = useState<MatrimonialRegimesData[]>([]);
  const [listaEstados, setListaEstados] = useState<any[]>([]);
  const [listaEstadoCivil, setListaEstadoCivil] = useState<CivilStatus[]>([]);
  const [colonias, setColonias] = useState<{ id: number; name: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      fechaNacimiento: "",
      lugarNacimiento: "",
      sexo: "",
      country_code: "+52",
      telefono: "",
      estadoCivil: "",
      regimenMatrimonial: "",
      email: "",
      codigoPostal: "",
      calle: "",
      noInterior: "",
      noExterior: "",
      estadoId: "",
      ciudadId: "",
      municipioId: "",
      coloniaId: "",
      estado: "",
      ciudad: "",
      municipio: "",
      otra_colonia: "",
    },
    validationSchema: yup.object({
      fechaNacimiento: yup
        .string()
        .required("Campo requerido")
        .test("isAdult", "Debes ser mayor de 18 años", function (value) {
          if (!value) return false;
          const age_in_years = dayjs().diff(dayjs(value), "years");
          return age_in_years >= 18 ? true : false;
        }),
      lugarNacimiento: yup.string().required("Requerido"),
      sexo: yup.string().required("Requerido"),
      country_code: yup.string().required("Requerido"),
      telefono: yup
        .string()
        .required("Requerido")
        .matches(/^[0-9]{10}$/, "Debe ser un número de 10 dígitos"),
      estadoCivil: yup.string().required("Requerido"),
      regimenMatrimonial: yup.string().when("estadoCivil", {
        is: "Casado",
        then: (schema) => schema.required("Requerido"),
        otherwise: (schema) => schema.notRequired(),
      }),
      email: yup
        .string()
        .required("Campo requerido")
        .email("Asegúrate de incluir el @ y los puntos necesarios (.com .mx)"),
      codigoPostal: yup.string().required("Campo requerido").matches(CODIGO_POSTAL, "Código Postal inválido"),
      calle: yup.string().required("Requerido"),
      noExterior: yup.string().required("Requerido"),
      noInterior: yup.string(),
      estadoId: yup.string().required("Requerido"),
      ciudadId: yup.string().required("Requerido"),
      municipioId: yup.string().required("Requerido"),
      coloniaId: yup.string().required("Requerido"),
      otra_colonia: yup.string().when("coloniaId", {
        is: "-1",
        then: (schema) => schema.required("Campo requerido"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!ownerId) {
          navigate("/producto/creditosimple/propietario_real_pm");
          return;
        }

        setSubmitting(true);

        await patchPerson({
          personId: ownerId,
          body: {
            birth_date: values.fechaNacimiento,
            birth_state: +values.lugarNacimiento,
            sex: values.sexo,
            code_phone_number: values.country_code,
            phone_number: values.telefono,
            civil_status_id: values.estadoCivil,
            matrimonial_regimes_id: values.estadoCivil === estadoCivilCasadoId ? values.regimenMatrimonial : null,
            email: values.email,
          },
        }).unwrap();

        const direccionPayload = {
          client_details_id: clientDetails.id,
          person_id: ownerId,
          address_type_id: addressTypeParticularId,
          cp: values.codigoPostal,
          street: values.calle,
          no_ext: values.noExterior,
          no_int: values.noInterior,
          state_id: +values.estadoId,
          city_id: +values.ciudadId,
          country_id: 1,
          colonia_id: +values.coloniaId === -1 ? null : +values.coloniaId,
          other_neighborhood: +values.coloniaId === -1 ? values.otra_colonia : null,
        };

        if (address?.id) {
          await patchAddress({
            addressId: address.id,
            data: direccionPayload,
          }).unwrap();
        } else {
          await postAddress(direccionPayload).unwrap();
        }
        navigate("/producto/creditosimple/datos_propietario_real_2");
      } catch (error) {
        const api_error = error as ApiError;

        if (api_error.status === 409) {
          formik.setFieldError("email", "El correo electrónico ya se encuentra registrado");
          return;
        }
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setSubmitting(false);
      }
    },
  });

  const fetchLocationData = async (codigoPostal: string, coloniaId?: number) => {
    try {
      if (codigoPostal.length === 5) {
        const value = await getByCp(codigoPostal).unwrap();
        const { colonias, cities, states } = value;
        setColonias(colonias);

        formik.setFieldValue("estadoId", states[0]?.id || "");
        formik.setFieldValue("estado", states[0]?.name || "");
        formik.setFieldValue("ciudadId", cities[0]?.id || "");
        formik.setFieldValue("ciudad", cities[0]?.name || "");
        formik.setFieldValue("municipioId", cities[0]?.id || "");
        formik.setFieldValue("municipio", cities[0]?.name || "");

        if (coloniaId) {
          const coloniaSelected = colonias.find((item: any) => item.id == coloniaId);
          formik.setFieldValue("coloniaId", coloniaSelected?.id || "");
        } else {
          formik.setFieldValue("coloniaId", colonias[0]?.id || "");
        }

        setTimeout(() => {
          formik.validateForm();
        }, 200);
      } else {
        formik.setFieldValue("estadoId", "");
        formik.setFieldValue("estado", "");
        formik.setFieldValue("ciudadId", "");
        formik.setFieldValue("ciudad", "");
        formik.setFieldValue("municipioId", "");
        formik.setFieldValue("municipio", "");
        formik.setFieldValue("coloniaId", "");
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (!ownerId) {
          navigate("/producto/creditosimple/propietario_real_pm");
          return;
        }

        setIsLoading(true);

        const [res_estado_civil, res_regimen_matrimonial, res_estados, res_address_type, personData, addressData] =
          await Promise.all([
            getEstadoCivil().unwrap(),
            getRegimenMatrimonial().unwrap(),
            getEstados().unwrap(),
            getAddressType().unwrap(),
            getPersona(ownerId).unwrap(),
            getAddressByPerson({
              personId: ownerId,
            }).unwrap(),
          ]);

        const edo_civil_casado: CivilStatus | undefined = res_estado_civil.find(
          (item) => item.name.toLowerCase() === "casado",
        );
        const addres_type = res_address_type.find((item) => item.code === ADDRESS_CODES.particular.code);

        setListaEstadoCivil(res_estado_civil);
        setListaRegimenMatrimonial(res_regimen_matrimonial);
        setListaEstados(res_estados);
        setAddressTypeParticularId(addres_type?.id || "");
        setEstadoCivilCasadoId(edo_civil_casado?.id || "");

        formik.setFieldValue("nombre", personData.name || "");
        formik.setFieldValue("apellidoPaterno", personData.last_name || "");
        formik.setFieldValue("apellidoMaterno", personData.last_name_2 || "");
        formik.setFieldValue("fechaNacimiento", personData.birth_date ? dayjs(personData.birth_date) : "");
        formik.setFieldValue("lugarNacimiento", personData.birth_state || "");
        formik.setFieldValue("sexo", personData.sex || "");
        formik.setFieldValue("country_code", personData.code_phone_number || "+52");
        formik.setFieldValue("telefono", personData.phone_number || "");
        formik.setFieldValue("estadoCivil", personData.civil_status_id || "");
        formik.setFieldValue("regimenMatrimonial", personData.matrimonial_regimes_id || "");
        formik.setFieldValue("email", personData.email || "");

        if (addressData.length) {
          const addressSelected = addressData[0];
          formik.setFieldValue("codigoPostal", addressSelected?.cp);
          formik.setFieldValue("calle", addressSelected?.street);
          formik.setFieldValue("noExterior", addressSelected?.no_ext);
          formik.setFieldValue("noInterior", addressSelected?.no_int);

          let colonia_id = addressSelected?.colonia_id;
          let otra_colonia = addressSelected?.other_neighborhood;

          if (addressSelected.colonia_id === null && addressSelected.other_neighborhood) {
            colonia_id = -1;
            otra_colonia = addressSelected.other_neighborhood;
          } else {
            colonia_id = addressSelected?.colonia_id;
            otra_colonia = "";
          }

          formik.setFieldValue("coloniaId", colonia_id);
          formik.setFieldValue("otra_colonia", otra_colonia);

          setAddress(addressSelected);
          fetchLocationData(addressSelected.cp, colonia_id);
        }

        setTimeout(() => {
          formik.validateForm();
        }, 200);
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Box maxWidth="sm" mx="auto" mt={4} px={2}>
          <Typography
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={700}
            color={theme?.palette?.primary?.dark}
          >
            Información del Propietario Real
          </Typography>

          <Typography
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            mt={2}
          >
            <strong>1</strong> de 2
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              <TextField
                id="nombre"
                name="nombre"
                label="Nombre"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
                value={formik.values.nombre}
                disabled={true}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="apellidoPaterno"
                name="apellidoPaterno"
                label="Apellido Paterno"
                value={formik.values.apellidoPaterno}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
                error={formik.touched.apellidoPaterno && Boolean(formik.errors.apellidoPaterno)}
                helperText={formik.touched.apellidoPaterno && formik.errors.apellidoPaterno}
                margin="normal"
                disabled={true}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="apellidoMaterno"
                name="apellidoMaterno"
                label="Apellido Materno"
                value={formik.values.apellidoMaterno}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.apellidoMaterno && Boolean(formik.errors.apellidoMaterno)}
                helperText={formik.touched.apellidoMaterno && formik.errors.apellidoMaterno}
                margin="normal"
                disabled={true}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <CustomDatePicker
                label={"Fecha de nacimiento"}
                name={"fechaNacimiento"}
                value={formik.values.fechaNacimiento}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.fechaNacimiento && Boolean(formik.errors.fechaNacimiento)}
                helperText={formik.touched.fechaNacimiento && formik.errors.fechaNacimiento}
              />
            </FormControl>

            <FormControl
              fullWidth
              required
              sx={{
                ...customStylesAsterisk,
                mt: 2,
              }}
              error={formik.touched.lugarNacimiento && Boolean(formik.errors.lugarNacimiento)}
            >
              <InputLabel id="lugarNacimiento">Lugar de nacimiento</InputLabel>
              <Select
                labelId="lugarNacimiento"
                id="lugarNacimiento"
                name="lugarNacimiento"
                fullWidth
                required
                label="Lugar de nacimiento"
                value={formik.values.lugarNacimiento}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.lugarNacimiento && Boolean(formik.errors.lugarNacimiento)}
                disabled={isLoading}
              >
                <MenuItem value={""}>Selecciona</MenuItem>
                {listaEstados?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.lugarNacimiento && formik.errors.lugarNacimiento && (
                <FormHelperText>{formik.errors.lugarNacimiento}</FormHelperText>
              )}
            </FormControl>

            <FormControl component="fieldset" sx={{ mt: 4 }}>
              <FormLabel component="legend" sx={{ color: theme?.palette?.primary?.dark, fontSize: "13px" }}>
                Sexo como aparece en la INE{" "}
              </FormLabel>
              <RadioGroup aria-label="sexo" name="sexo" value={formik.values.sexo} onChange={formik.handleChange} row>
                <FormControlLabel
                  sx={{ color: theme?.palette?.primary?.dark, fontSize: "13px" }}
                  value="MASCULINO"
                  control={<Radio />}
                  label="Masculino"
                />
                <FormControlLabel
                  sx={{ color: theme?.palette?.primary?.dark, fontSize: "13px" }}
                  value="FEMENINO"
                  control={<Radio />}
                  label="Femenino"
                />
              </RadioGroup>
            </FormControl>

            <Grid container spacing={1} mt={1}>
              <Grid item xs={4} sm={4} md={4}>
                <CustomContryCode
                  label="Código"
                  name="country_code"
                  value={formik.values.country_code}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                />
              </Grid>

              <Grid item xs={8} sm={8} md={8}>
                <TextField
                  type="text"
                  name="telefono"
                  variant="outlined"
                  label="Teléfono"
                  fullWidth
                  required
                  value={formik.values.telefono}
                  onChange={(event) => {
                    formik.setFieldValue("telefono", event.target.value.replace(/[^0-9]/g, ""));
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.telefono && Boolean(formik.errors.telefono)}
                  helperText={formik.touched.telefono && formik.errors.telefono}
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric",
                  }}
                />
              </Grid>
            </Grid>

            <FormControl
              fullWidth
              required
              sx={{
                ...customStylesAsterisk,
                mt: 2,
              }}
              error={formik.touched.estadoCivil && Boolean(formik.errors.estadoCivil)}
            >
              <InputLabel id="estadoCivil">Estado civil</InputLabel>
              <Select
                labelId="estadoCivil"
                id="estadoCivil"
                name="estadoCivil"
                fullWidth
                required
                label="Estado civil"
                value={formik.values.estadoCivil}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.estadoCivil && Boolean(formik.errors.estadoCivil)}
                disabled={isLoading}
              >
                <MenuItem value={""}>Selecciona</MenuItem>
                {listaEstadoCivil.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.estadoCivil && formik.errors.estadoCivil && (
                <FormHelperText>{formik.errors.estadoCivil}</FormHelperText>
              )}
            </FormControl>

            {formik.values.estadoCivil === estadoCivilCasadoId && (
              <FormControl
                fullWidth
                required
                sx={{
                  ...customStylesAsterisk,
                  mt: 2,
                }}
                error={formik.touched.regimenMatrimonial && Boolean(formik.errors.regimenMatrimonial)}
              >
                <InputLabel id="matrimonial_regimes_id">Régimen matrimonial</InputLabel>
                <Select
                  labelId="matrimonial_regimes_id"
                  id="matrimonial_regimes_id"
                  name="regimenMatrimonial"
                  fullWidth
                  required
                  label="Régimen matrimonial *"
                  value={formik.values.regimenMatrimonial}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.regimenMatrimonial && Boolean(formik.errors.regimenMatrimonial)}
                >
                  <MenuItem value={""}>Selecciona</MenuItem>
                  {listaRegimenMatrimonial.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.regimenMatrimonial && formik.errors.regimenMatrimonial && (
                  <FormHelperText>{formik.errors.regimenMatrimonial}</FormHelperText>
                )}
              </FormControl>
            )}

            <FormControl fullWidth>
              <TextField
                id="email"
                name="email"
                label="Correo electrónico"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email ? formik.errors.email : ""}
                inputProps={{ maxLength: 30 }}
              />
            </FormControl>

            <Box mt={4}>
              <Typography
                my={3}
                variant={isMobileOrTablet ? "body2" : "body1"}
                color={theme?.palette?.primary?.dark}
                fontWeight={600}
              >
                Domicilio operativo:
              </Typography>
              <Typography variant="body2" fontWeight={300} color="textSecondary" sx={{ fontSize: "12px" }}>
                Tal como aparece en el comprobante de domicilio y deberá ser un domicilio dentro de la República
                Mexicana
              </Typography>
            </Box>

            <FormControl fullWidth>
              <TextField
                id="codigoPostal"
                name="codigoPostal"
                label="Código Postal"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                  fetchLocationData(e.target.value);
                }}
                error={formik.touched.codigoPostal && Boolean(formik.errors.codigoPostal)}
                helperText={formik.touched.codigoPostal && formik.errors.codigoPostal}
                value={formik.values.codigoPostal}
                inputProps={{ maxLength: 5 }}
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="calle"
                name="calle"
                label="Calle o avenida"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.calle && Boolean(formik.errors.calle)}
                helperText={formik.touched.calle && formik.errors.calle}
                value={formik.values.calle}
              />
            </FormControl>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    id="noExterior"
                    name="noExterior"
                    label="No. Exterior"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.noExterior && Boolean(formik.errors.noExterior)}
                    helperText={formik.touched.noExterior && formik.errors.noExterior}
                    value={formik.values.noExterior}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    id="noInterior"
                    name="noInterior"
                    label="No. Interior"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.noInterior}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <FormControl
              fullWidth
              required
              sx={{
                ...customStylesAsterisk,
                mt: 2,
              }}
              error={formik.touched.coloniaId && Boolean(formik.errors.coloniaId)}
            >
              <InputLabel id="coloniaId">Colonia</InputLabel>
              <Select
                labelId="coloniaId"
                id="coloniaId"
                name="coloniaId"
                fullWidth
                required
                label="Colonia"
                value={formik.values.coloniaId}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={formik.touched.coloniaId && Boolean(formik.errors.coloniaId)}
              >
                <MenuItem value={""}>Selecciona</MenuItem>
                {colonias.map((colonia: { id: number; name: string }) => (
                  <MenuItem key={colonia.id} value={colonia.id}>
                    {colonia.name}
                  </MenuItem>
                ))}
              </Select>
              {formik.touched.coloniaId && formik.errors.coloniaId && (
                <FormHelperText>{formik.errors.coloniaId}</FormHelperText>
              )}
            </FormControl>

            {String(formik.values.coloniaId) === "-1" && (
              <FormControl fullWidth>
                <TextField
                  id="otra_colonia"
                  name="otra_colonia"
                  label="Otra Colonia"
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="off"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.otra_colonia && Boolean(formik.errors.otra_colonia)}
                  helperText={formik.touched.otra_colonia && formik.errors.otra_colonia}
                  value={formik.values.otra_colonia}
                />
              </FormControl>
            )}

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="ciudad"
                name="ciudad"
                label="Ciudad"
                value={formik.values.ciudad}
                error={formik.touched.ciudadId && Boolean(formik.errors.ciudadId)}
                helperText={formik.touched.ciudadId && formik.errors.ciudadId ? String(formik.errors.ciudadId) : ""}
                margin="normal"
                disabled
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="municipio"
                name="municipio"
                label="Municipio"
                value={formik.values.municipio}
                error={formik.touched.municipioId && Boolean(formik.errors.municipioId)}
                helperText={
                  formik.touched.municipioId && formik.errors.municipioId ? String(formik.errors.municipioId) : ""
                }
                margin="normal"
                disabled
              />
            </FormControl>

            <FormControl fullWidth>
              <TextField
                fullWidth
                id="estado"
                name="estado"
                label="Entidad federativa"
                value={formik.values.estado}
                error={formik.touched.estadoId && Boolean(formik.errors.estadoId)}
                helperText={formik.touched.estadoId && formik.errors.estadoId ? String(formik.errors.estadoId) : ""}
                margin="normal"
                disabled
              />
            </FormControl>

            <Grid container spacing={2} my={4}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="blue_outlined"
                  sx={{ padding: "0", height: "100%" }}
                  onClick={() => navigate("/producto/creditosimple/propietario_real_pm")}
                  disabled={formik.isSubmitting || isLoading}
                >
                  Regresar al inicio
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  type="submit"
                  variant="default"
                  fullWidth
                  color="primary"
                  disabled={!formik.isValid || formik.isSubmitting || isLoading}
                >
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
            <CustomRightImage imageName="img_bofu_9" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DatosPropietarioRealPart1;
