import { useState } from "react";
import {
  Box,
  Grid,
  Container,
  TextField,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Modal,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { EMAIL } from "@utils/expreciones";
import { useRequestPasswordChangeMutation } from "@api/onboarding";
import CustomRightImage from "@components/CustomRightImage";

export const CambiarContrasena = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [requestPasswordChange] = useRequestPasswordChangeMutation();

  const handleCloseSuccessModal = () => setOpenSuccessModal(false);
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .trim("El correo no puede tener espacios al inicio o al final")
        .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)")
        .required("Campo requerido"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);
        await requestPasswordChange({ username: values.email }).unwrap();
        setOpenSuccessModal(true);
      } catch (error) {
        setOpenErrorModal(true);
        console.error("Error enviando solicitud de cambio de contraseña:", error);
      } finally {
        setSubmitting(false);
      }
    },
    validateOnMount: true,
  });

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <Box
            sx={{
              mt: isMobileOrTablet ? 1 : 2,
              mx: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant={isMobileOrTablet ? "h4" : "h3"}
              fontWeight={700}
              textAlign="center"
              color={theme?.palette?.primary?.main}
            >
              Ingresa tu correo electrónico
            </Typography>
            <Typography
              mt={isMobileOrTablet ? 2 : 3}
              variant={isMobileOrTablet ? "body2" : "body1"}
              textAlign="center"
              color={theme?.palette?.primary?.dark}
            >
              Te enviaremos un enlace para que generes una nueva contraseña
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <FormControl fullWidth>
                  <TextField
                    id="email"
                    name="email"
                    label="Correo electrónico"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onChange={(e) => {
                      const trimmedValue = e.target.value.trimStart();
                      const trimmed = trimmedValue.trim();
                      formik.setFieldValue("email", trimmed);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    value={formik.values.email}
                  />
                </FormControl>

                <Box textAlign={"center"} mt={6}>
                  <Button variant="default" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                    {formik.isSubmitting ? "Enviando..." : "Continuar"}
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
              <CustomRightImage imageName="img_bofu_15" />
            </Box>
          </Grid>
        )}
      </Grid>

      <Modal
        open={openSuccessModal}
        onClose={handleCloseSuccessModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" fontWeight="700" color={theme?.palette?.primary?.main} mb={2}>
            ¡Correo enviado!
          </Typography>
          <Typography variant="body1" mb={3}>
            Hemos enviado un enlace a tu correo electrónico. Sigue las instrucciones para restablecer tu contraseña.
          </Typography>
          <Button onClick={handleCloseSuccessModal} variant="contained">
            Cerrar
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" fontWeight="700" color="#F2704F" mb={2}>
            Cuenta no encontrada
          </Typography>
          <Typography variant="body1" mb={3} color={theme?.palette?.primary?.dark}>
            No encontramos una cuenta asociada a este correo electrónico. Por favor verifica el correo ingresado e
            inténtalo de nuevo.
          </Typography>
          <Button onClick={handleCloseErrorModal} variant="contained">
            Cerrar
          </Button>
        </Box>
      </Modal>
    </>
  );
};
