import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
  Modal,
  FormControl,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate } from "react-router-dom";
import { useUpdatePasswordChangeMutation } from "@api/onboarding";
import CustomRightImage from "@components/CustomRightImage";
import { PASSWORD, HAS_SPECIAL_CHARACTERS, HAS_NUMBERS, HAS_UPPERCASE, HAS_LOWERCASE } from "@utils/expreciones";

export const NuevaContrasena = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [uuid, setUuid] = useState<string | null>(null);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [updatePasswordChange] = useUpdatePasswordChangeMutation();
  const location = useLocation();
  const navigate = useNavigate();

  const handleCloseSuccessModal = () => {
    setOpenSuccessModal(false);
    navigate("/login");
  };
  const handleCloseErrorModal = () => setOpenErrorModal(false);

  const validationSchema = yup.object().shape({
    password: yup.string().required("Campo requerido").matches(PASSWORD, "Formato Incorrecto"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Las contraseñas no coinciden")
      .required("Campo requerido"),
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const requestUUID = queryParams.get("request");
    if (requestUUID) {
      setUuid(requestUUID);
    }
  }, [location]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      if (!uuid) {
        setOpenErrorModal(true);
        return;
      }

      try {
        setSubmitting(true);
        await updatePasswordChange({
          request_id: uuid,
          password: values.password,
        }).unwrap();
        setOpenSuccessModal(true);
      } catch (error) {
        setOpenErrorModal(true);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const isLengthValid = formik.values.password.length >= 8 && formik.values.password.length <= 20;
  const hasNumber = HAS_NUMBERS.test(formik.values.password);
  const hasSpecialChar = HAS_SPECIAL_CHARACTERS.test(formik.values.password);
  const hasUpperCase = HAS_UPPERCASE.test(formik.values.password);
  const hasLowerCase = HAS_LOWERCASE.test(formik.values.password);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <Box
            sx={{
              mt: isMobileOrTablet ? 1 : 2,
              mx: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant={isMobileOrTablet ? "h4" : "h3"}
              fontWeight={700}
              textAlign="center"
              color={theme?.palette?.primary?.main}
            >
              Escribe y confirma tu nueva contraseña
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 6 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <FormControl fullWidth>
                  <TextField
                    id="password"
                    name="password"
                    label="Nueva contraseña"
                    type={showPassword ? "text" : "password"}
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirma contraseña"
                    type={showConfirmPassword ? "text" : "password"}
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowConfirmPassword} edge="end">
                            {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>

                <Box sx={{ mt: 1, mb: 2 }}>
                  <Typography variant="body1" fontWeight={500}>
                    Tu contraseña debe contener:
                  </Typography>
                  <Typography
                    mt={1}
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    color={isLengthValid ? "green" : "red"}
                    display="flex"
                    alignItems="center"
                  >
                    {isLengthValid ? <CheckIcon /> : <CloseIcon />} &nbsp;Entre 8 y 20 caracteres
                  </Typography>
                  <Typography
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    color={hasNumber ? "green" : "red"}
                    display="flex"
                    alignItems="center"
                  >
                    {hasNumber ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter numérico
                  </Typography>
                  <Typography
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    color={hasSpecialChar ? "green" : "red"}
                    display="flex"
                    alignItems="center"
                  >
                    {hasSpecialChar ? <CheckIcon /> : <CloseIcon />} &nbsp;Un carácter especial
                  </Typography>
                  <Typography
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    color={hasUpperCase ? "green" : "red"}
                    display="flex"
                    alignItems="center"
                  >
                    {hasUpperCase ? <CheckIcon /> : <CloseIcon />} &nbsp;Una mayúscula
                  </Typography>
                  <Typography
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    color={hasLowerCase ? "green" : "red"}
                    display="flex"
                    alignItems="center"
                  >
                    {hasLowerCase ? <CheckIcon /> : <CloseIcon />} &nbsp;Una minúscula
                  </Typography>
                </Box>

                <Box textAlign={"center"} mt={6}>
                  <Button variant="default" type="submit" disabled={!formik.isValid || formik.isSubmitting}>
                    Continuar
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
              <CustomRightImage imageName="img_tofu_7" />
            </Box>
          </Grid>
        )}
      </Grid>

      <Modal
        open={openSuccessModal}
        onClose={handleCloseSuccessModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxWidth: "400px",
            textAlign: "center",
            outline: "none",
          }}
        >
          <Typography variant="h6" fontWeight="700" color={theme?.palette?.primary?.main} mb={2}>
            ¡Contraseña actualizada!
          </Typography>
          <Typography variant="body1" mb={3}>
            Tu contraseña ha sido actualizada exitosamente.
          </Typography>
          <Button
            onClick={handleCloseSuccessModal}
            variant="default"
            sx={{
              textTransform: "none",
              px: 4,
              py: 1,
            }}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>

      <Modal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxWidth: "400px",
            textAlign: "center",
          }}
        >
          <Typography variant="h6" fontWeight="700" color="#F2704F" mb={2}>
            Error al actualizar
          </Typography>
          <Typography variant="body1" mb={3} color={theme?.palette?.primary?.dark}>
            Hubo un problema al actualizar tu contraseña. Inténtalo de nuevo.
          </Typography>
          <Button
            onClick={handleCloseErrorModal}
            variant="contained"
            sx={{
              backgroundColor: "#F2704F",
              color: "#fff",
              textTransform: "none",
              px: 4,
              py: 1,
            }}
          >
            Cerrar
          </Button>
        </Box>
      </Modal>
    </>
  );
};
