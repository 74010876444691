/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  Checkbox,
  Typography,
  useMediaQuery,
  useTheme,
  Skeleton,
  TextField,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { UUID } from "crypto";
import {
  usePostPaymentDetailsMutation,
  usePostPaymentMethodMutation,
  usePutPaymentDetailsMutation,
} from "@api/applications";
import { RootState } from "@store/store";
import { useGetPaymentMethodQuery } from "@api/catalogs";
import CustomRadioGroup from "@components/CustomRadioGroup";
import { setPfaeTransactionalInfo } from "@store/slices/bofuSlicePfae";
import { InactivityHandler } from "@components/InactivityHandler";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";

const EMPTY_UUID = "00000000-0000-0000-0000-000000000000";

const SolicitudCreditoPart2PFAE: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const companyData = useSelector((state: RootState) => state.bofuPfae.digital_file.pfae);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const paymentsMethodCatalog = useGetPaymentMethodQuery();
  const [triggerPaymentMethod] = usePostPaymentMethodMutation();
  const [triggerPaymentDetails] = usePostPaymentDetailsMutation();
  const [triggerPutPaymentDetails] = usePutPaymentDetailsMutation();
  const [idOther, setIdOther] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {
    transactional_info: { payment_methods, details },
  } = companyData;

  const validationSchema = yup.object().shape({
    paymentMethods: yup
      .array()
      .of(yup.string().required("Método de pago es requerido"))
      .min(1, "Selecciona al menos un método de pago")
      .required("Selecciona al menos un método de pago"),
    otherPaymentMethod: yup.string().when("paymentMethods", {
      is: (methods: string[] = []) => methods.includes(idOther),
      then: (schema) => schema.required("Este campo es requerido para el método de pago 'Otro'"),
      otherwise: (schema) => schema.notRequired(),
    }),
    advancePayments: yup.string().required("Campo requerido"),
    resourcesTypes: yup.string().required("Campo requerido"),
  });

  const formik = useFormik({
    initialValues: {
      paymentMethods: (payment_methods || []).filter((item) => item !== EMPTY_UUID),
      otherPaymentMethod: "",
      advancePayments: details.advance_payments === undefined ? "" : details.advance_payments ? "Si" : "No",
      resourcesTypes: details.own_resources === undefined ? "" : details.own_resources ? "Propios" : "Tercero",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);

        const body = {
          payment_methods: values.paymentMethods.map((id) => ({
            payment_method_id: id,
            other_payment_method: id === idOther ? values.otherPaymentMethod : null,
          })),
        };
        await triggerPaymentMethod({
          solicitudId: applicationData.id,
          body,
        }).unwrap();

        let detailsId = undefined;
        if (details.id) {
          const response = await triggerPutPaymentDetails({
            solicitudId: applicationData.id,
            detailId: details.id,
            body: {
              advance_payments: values.advancePayments === "Si",
              own_account: false,
              own_resources: values.resourcesTypes === "Propios",
            },
          }).unwrap();

          detailsId = response.data.id;
        } else {
          const response = await triggerPaymentDetails({
            solicitudId: applicationData.id,
            body: {
              advance_payments: values.advancePayments === "Si",
              own_account: false,
              own_resources: values.resourcesTypes === "Propios",
            },
          }).unwrap();

          detailsId = response.data.id;
        }

        dispatch(
          setPfaeTransactionalInfo({
            paymentMethods: formik.values.paymentMethods as UUID[],
            paymentDetailsId: detailsId,
            advancePayments: formik.values.advancePayments === "Si",
            resourcesTypes: formik.values.resourcesTypes === "Propios",
          }),
        );

        navigate("/producto/creditosimple/solicitud_credito_3PFAE");
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setIsLoading(false);
      }
    },
    validateOnMount: true,
    validateOnChange: true,
  });

  useEffect(() => {
    if (paymentsMethodCatalog.isSuccess) {
      const cpiotPaymentMethod = paymentsMethodCatalog.data?.find((item) => item.code === "CPIOT");
      if (cpiotPaymentMethod) {
        setIdOther(cpiotPaymentMethod.id);
      }
    }
  }, [paymentsMethodCatalog.isSuccess, paymentsMethodCatalog.data]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = event.target;
    const newMethods = checked
      ? [...formik.values.paymentMethods, value]
      : formik.values.paymentMethods.filter((method) => method !== value);

    formik.setFieldValue("paymentMethods", newMethods);
  };

  const handleOnClickRestart = () => {
    navigate("/producto/creditosimple/avance_expediente_digitalPFAE");
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <Box textAlign={"center"} maxWidth="sm" mx="auto">
          <InactivityHandler />
          <Typography
            mt={4}
            mb={3}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={600}
            color={theme?.palette?.primary?.dark}
          >
            Solicitud de crédito
          </Typography>
          <Typography
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={800}
            color={theme?.palette?.primary?.dark}
          >
            2{" "}
            <Typography
              component="span"
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={400}
              color={theme?.palette?.primary?.dark}
            >
              de 3
            </Typography>
          </Typography>
          <Typography
            my={3}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            fontWeight={600}
          >
            Información transaccional
          </Typography>
          <form onSubmit={formik.handleSubmit} noValidate>
            <Box textAlign={"left"} my={2}>
              <Typography variant="body1">¿De qué forma realizarás tus pagos?</Typography>
              <FormGroup>
                {paymentsMethodCatalog.isSuccess && paymentsMethodCatalog.data ? (
                  paymentsMethodCatalog.data.map((paymentMethod, index) => (
                    <FormControlLabel
                      sx={{ color: theme?.palette?.primary?.dark }}
                      key={index}
                      control={
                        <Checkbox
                          key={paymentMethod.id}
                          name="paymentMethods"
                          checked={formik.values.paymentMethods.includes(
                            paymentMethod.id as `${string}-${string}-${string}-${string}-${string}`,
                          )}
                          onBlur={formik.handleBlur}
                          onChange={handleCheckboxChange}
                          value={paymentMethod.id}
                        />
                      }
                      label={paymentMethod.description}
                    />
                  ))
                ) : (
                  <>
                    <Skeleton>
                      <FormControlLabel control={<Checkbox />} label={""}></FormControlLabel>
                    </Skeleton>
                    <Skeleton>
                      <FormControlLabel control={<Checkbox />} label={""}></FormControlLabel>
                    </Skeleton>
                    <Skeleton>
                      <FormControlLabel control={<Checkbox />} label={""}></FormControlLabel>
                    </Skeleton>
                    <Skeleton>
                      <FormControlLabel control={<Checkbox />} label={""}></FormControlLabel>
                    </Skeleton>
                  </>
                )}
              </FormGroup>
              {/* Mostrar campo de texto si el método de pago "CPIOT" está seleccionado */}
              {formik.values.paymentMethods.includes(idOther as UUID) && (
                <TextField
                  fullWidth
                  id="otherPaymentMethod"
                  name="otherPaymentMethod"
                  label="Especifica el método de pago"
                  value={formik.values.otherPaymentMethod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.otherPaymentMethod && Boolean(formik.errors.otherPaymentMethod)}
                  helperText={formik.touched.otherPaymentMethod && formik.errors.otherPaymentMethod}
                  margin="normal"
                />
              )}
            </Box>
            <Box textAlign={"left"} my={2}>
              <CustomRadioGroup
                label={"¿Realizarás pagos anticipados?"}
                name={"advancePayments"}
                value={formik.values.advancePayments}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.advancePayments && Boolean(formik.errors.advancePayments)}
                helperText={formik.touched.advancePayments && formik.errors.advancePayments}
                options={[
                  {
                    label: "Si",
                    value: "Si",
                  },
                  {
                    label: "No",
                    value: "No",
                  },
                ]}
              />
            </Box>
            <Box textAlign={"left"} my={2}>
              <CustomRadioGroup
                label={"¿Los recursos son propios o de un tercero?"}
                name={"resourcesTypes"}
                value={formik.values.resourcesTypes}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.resourcesTypes && Boolean(formik.errors.resourcesTypes)}
                helperText={formik.touched.resourcesTypes && formik.errors.resourcesTypes}
                options={[
                  {
                    label: "Propios",
                    value: "Propios",
                  },
                  {
                    label: "De un tercero",
                    value: "Tercero",
                  },
                ]}
              />
            </Box>

            <Grid container columnSpacing={1} my={4}>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  variant="blue_outlined"
                  sx={{ padding: "0", height: "100%" }}
                  onClick={handleOnClickRestart}
                >
                  Regresar al inicio
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth type="submit" variant="default" disabled={!formik.isValid || isLoading}>
                  Continuar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_4" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default SolicitudCreditoPart2PFAE;
