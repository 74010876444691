/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Box, Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { InactivityHandler } from "@components/InactivityHandler";
import { useLazyGetLoanApplicationByIdQuery } from "@api/applications";
import CustomRightImage from "@components/CustomRightImage";
import useInterval from "@hooks/useInterval";
import { setLoanStatus } from "@store/slices/registerSlice";
import { LoanStatus } from "@interfaces/catalogs";
import IconSandClock from "@components/Images/IconSandClock";

const SECONDS_TO_FETCH = 120;

const VisitaOcularConcluida = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const application = useSelector((state: RootState) => state.register.application);
  const loanStatus = useSelector((state: RootState) => state.register.loan_status);

  const [getLoanApplication] = useLazyGetLoanApplicationByIdQuery();

  const loanInterval = useInterval(async () => {
    try {
      const loan_application = await getLoanApplication(application.id).unwrap();
      if (loan_application?.status_catalog && loan_application?.status_catalog?.code !== loanStatus) {
        dispatch(setLoanStatus(loan_application.status_catalog.code as LoanStatus));
        navigate(loan_application.status_catalog.url_actual);
      }
    } catch (error) {
      console.error("Ha ocurrido un error al obtener la aplicación");
    }
  }, SECONDS_TO_FETCH);

  useEffect(() => {
    loanInterval.setIsActiveInterval(true);

    return () => {
      loanInterval.setIsActiveInterval(false);
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />

        <Container maxWidth="lg">
          <Typography
            mt={5}
            textAlign={"center"}
            variant={isMobileOrTablet ? "h5" : "h4"}
            color={theme?.palette?.primary?.dark}
            fontWeight={700}
          >
            Validando visita y preparando documentos contractuales
          </Typography>

          <NavBarOcularVisit currentStep={3} />

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            Tu visita concluyo, validaremos el resultado y prepararemos los documentos para firma, volveremos contigo a
            la brevedad
          </Typography>

          <Box mt={4} display="flex" justifyContent="center">
            <IconSandClock width={isMobileOrTablet ? "100" : "150"} fill={theme.palette.primary.dark} />
          </Box>

          <Typography
            mt={4}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
          >
            ¡Despreocúpate!
          </Typography>

          <Typography
            mt={1}
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
          >
            Puedes salir de esta pantalla, tan pronto terminemos, te contactaremos via WhatsApp y puedes volver a
            iniciar sesión después
          </Typography>
        </Container>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: theme?.palette?.primary?.dark,
            }}
          >
            <CustomRightImage imageName="img_bofu_6" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default VisitaOcularConcluida;
