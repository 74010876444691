/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Grid,
  Box,
  Container,
  useTheme,
  useMediaQuery,
  TextField,
  Button,
  Typography,
  SxProps,
  FormHelperText,
  Modal,
  alpha,
  Theme,
} from "@mui/material";
import { RootState, useAppDispatch } from "@store/store";
import { useValidatePhoneCodeMutation, useRequestPhoneCodeMutation } from "@api/onboarding";
import { TerminosCondiciones } from "@components/TerminosCondiciones";
import { ProgressBar } from "@components/ProgressBar";
import { formatTime, formatPhoneNumber } from "@helpers/funciones";
import { usePostValidateNextStageMutation } from "@api/applications";
import { usePatchCuentaMutation } from "@api/account";
import "@styles/stylesRegistro.css";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { setLoanStatus, setPersonalData } from "@store/slices/registerSlice";
import { STATUS_CATALOG, TAX_SYSTEM_TYPE } from "@helpers/constantes";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import * as Sentry from "@sentry/react";

const TIME_LIMIT = 120;

const CustomTextField: SxProps<Theme> = (theme) => ({
  "& input": {
    textAlign: "center",
    fontSize: "25px",
    fontWeight: 400,
    width: "40px",
    height: "60px",
  },
  margin: "8px",
  backgroundColor: alpha(theme?.palette?.primary?.light, 0.3), // Aplica opacidad del 30%
  "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
  },
  "& input[disabled]": {
    cursor: "not-allowed",
  },
});

const ValidacionTelefono = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useAppDispatch();
  const inputRef_1 = useRef<HTMLInputElement>(null);
  const inputRef_2 = useRef<HTMLInputElement>(null);
  const inputRef_3 = useRef<HTMLInputElement>(null);
  const inputRef_4 = useRef<HTMLInputElement>(null);

  const application = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const [validatePhoneCode] = useValidatePhoneCodeMutation();
  const [requestPhoneCode] = useRequestPhoneCodeMutation();
  const [patchAccount] = usePatchCuentaMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const [secondsRemaining, setSecondsRemaining] = useState(TIME_LIMIT);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const formik = useFormik({
    initialValues: {
      cell_phone_verified: clientDetails.cell_phone_verified,
      code_1: "",
      code_2: "",
      code_3: "",
      code_4: "",
    },
    validationSchema: yup.object().shape({
      code_1: yup.string().when("cell_phone_verified", {
        is: (value: boolean) => value === false,
        then: (schema) => schema.required("Campo requerido"),
      }),
      code_2: yup.string().when("cell_phone_verified", {
        is: (value: boolean) => value === false,
        then: (schema) => schema.required("Campo requerido"),
      }),
      code_3: yup.string().when("cell_phone_verified", {
        is: (value: boolean) => value === false,
        then: (schema) => schema.required("Campo requerido"),
      }),
      code_4: yup.string().when("cell_phone_verified", {
        is: (value: boolean) => value === false,
        then: (schema) => schema.required("Campo requerido"),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      const isPFAE = application.tax_system_type === TAX_SYSTEM_TYPE.PFAE.code;
      const next_status = isPFAE ? STATUS_CATALOG.datos_empresa_pfae.code : STATUS_CATALOG.datos_empresa_pm.code;
      const next_url = isPFAE ? STATUS_CATALOG.datos_empresa_pfae.url : STATUS_CATALOG.datos_empresa_pm.url;

      try {
        setSubmitting(true);
        setErrorMessage(null);

        if (!clientDetails.cell_phone_verified) {
          await validatePhoneCode({
            country_code: clientDetails.country_code,
            phone_number: clientDetails.phone,
            code: `${values.code_1}${values.code_2}${values.code_3}${values.code_4}`,
          }).unwrap();

          await patchAccount({
            accountId: clientDetails.id,
            body: {
              cell_phone_verified: true,
            },
          });

          await triggerPostNextStage({
            applicationId: application.id,
            statusCode: next_status,
            username: `TOFU - ${accountUser.email}`,
          }).unwrap();

          dispatch(
            setPersonalData({
              ...clientDetails,
              cell_phone_verified: true,
            }),
          );

          dispatch(setLoanStatus(next_status));
        }

        navigate(next_url);
      } catch (error) {
        const api_error = error as ApiError;
        if (api_error?.status === 404 || api_error?.status === 400) {
          setErrorMessage("Código incorrecto, intenta de nuevo");
          return;
        }

        dispatch(setModalErrorVisible({ open: true, error: api_error }));
        Sentry.captureException(api_error, {
          extra: {
            component: "ValidacionTelefono",
            values: formik.values,
          },
        });
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleResendCode = async () => {
    try {
      setIsLoading(true);
      await requestPhoneCode({
        country_code: clientDetails.country_code,
        phone_number: clientDetails.phone,
      }).unwrap();

      setSecondsRemaining(TIME_LIMIT);
    } catch (error) {
      const api_error = error as ApiError;
      if (api_error?.status === 429) {
        handleOpen();
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePhone = () => {
    navigate("/producto/creditosimple/registro_datos_personales");
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [secondsRemaining]);

  useEffect(() => {
    const fetchInitialData = () => {
      if (!accountUser.email || !clientDetails.phone) {
        navigate("/producto/creditosimple");
      }
    };
    fetchInitialData();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      inputRef_1.current?.focus();
    }, 200);
  }, []);

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pasteData = e.clipboardData.getData("text").trim().slice(0, 4);

    if (pasteData.length === 4) {
      formik.setFieldValue("code_1", pasteData[0]);
      formik.setFieldValue("code_2", pasteData[1]);
      formik.setFieldValue("code_3", pasteData[2]);
      formik.setFieldValue("code_4", pasteData[3]);

      inputRef_4.current?.focus();
    }
  };

  return (
    <Grid container height={"100%"}>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box
          sx={{
            mt: isMobileOrTablet ? 2 : 3,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isMobileOrTablet ? "h4" : "h3"}
            fontWeight={500}
            textAlign="center"
            color={theme?.palette?.primary?.dark}
          >
            Validación de teléfono
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <ProgressBar progress={2} />
          </Container>

          <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3} textAlign={"center"}>
            <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={theme?.palette?.primary?.dark}>
              Ingresa el código que enviamos vía whatsapp al número
            </Typography>
          </Box>

          <Typography
            variant={isMobileOrTablet ? "h5" : "h4"}
            mt={isMobileOrTablet ? 2 : 3}
            fontWeight={600}
            color={theme?.palette?.primary?.main}
          >
            ({clientDetails.country_code}) {formatPhoneNumber(clientDetails.phone)}
          </Typography>

          <Typography
            mt={1}
            variant={isMobileOrTablet ? "body2" : "body2"}
            component={"a"}
            onClick={handleChangePhone}
            sx={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
            color={theme?.palette?.primary?.main}
          >
            ¿No es tu número? Editalo aquí
          </Typography>

          <Typography
            mt={isMobileOrTablet ? 2 : 4}
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={300}
            color={theme?.palette?.primary?.dark}
          >
            Código {clientDetails.cell_phone_verified ? "verificado" : "no verificado"}
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 1, mb: 1 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Box display="flex" flexDirection="row" justifyContent="center">
                <TextField
                  type="text"
                  name="code_1"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="off"
                  onChange={(e) => {
                    if (/^[0-9]$/.test(e.target.value)) {
                      formik.handleChange(e);
                      inputRef_2.current?.focus();
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => {
                    if (e.code === "Backspace") {
                      formik.setFieldValue("code_1", "");
                    }
                  }}
                  onPaste={handlePaste}
                  error={formik.touched.code_1 && Boolean(formik.errors.code_1)}
                  sx={CustomTextField(theme)}
                  inputProps={{
                    maxLength: 1,
                    inputMode: "numeric",
                  }}
                  inputRef={inputRef_1}
                  disabled={isLoading || formik.isSubmitting || clientDetails.cell_phone_verified}
                  value={formik.values.code_1}
                />
                <TextField
                  type="text"
                  name="code_2"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="off"
                  onChange={(e) => {
                    if (/^[0-9]$/.test(e.target.value)) {
                      formik.handleChange(e);
                      inputRef_3.current?.focus();
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => {
                    if (e.code === "Backspace" && !formik.values.code_2) {
                      inputRef_1.current?.focus();
                    } else if (e.code === "Backspace") {
                      formik.setFieldValue("code_2", "");
                    }
                  }}
                  onPaste={handlePaste}
                  error={formik.touched.code_2 && Boolean(formik.errors.code_2)}
                  sx={CustomTextField(theme)}
                  inputProps={{
                    maxLength: 1,
                    inputMode: "numeric",
                  }}
                  inputRef={inputRef_2}
                  disabled={isLoading || formik.isSubmitting || clientDetails.cell_phone_verified}
                  value={formik.values.code_2}
                />
                <TextField
                  type="text"
                  name="code_3"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="off"
                  onChange={(e) => {
                    if (/^[0-9]$/.test(e.target.value)) {
                      formik.handleChange(e);
                      inputRef_4.current?.focus();
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => {
                    if (e.code === "Backspace" && !formik.values.code_3) {
                      inputRef_2.current?.focus();
                    } else if (e.code === "Backspace") {
                      formik.setFieldValue("code_3", "");
                    }
                  }}
                  onPaste={handlePaste}
                  error={formik.touched.code_3 && Boolean(formik.errors.code_3)}
                  sx={CustomTextField(theme)}
                  inputProps={{
                    maxLength: 1,
                    inputMode: "numeric",
                  }}
                  inputRef={inputRef_3}
                  disabled={isLoading || formik.isSubmitting || clientDetails.cell_phone_verified}
                  value={formik.values.code_3}
                />
                <TextField
                  type="text"
                  name="code_4"
                  variant="outlined"
                  margin="dense"
                  size="small"
                  autoComplete="off"
                  onChange={(e) => {
                    if (/^[0-9]$/.test(e.target.value)) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  onKeyUp={(e) => {
                    if (e.code === "Backspace" && !formik.values.code_4) {
                      inputRef_3.current?.focus();
                    } else if (e.code === "Backspace") {
                      formik.setFieldValue("code_4", "");
                    }
                  }}
                  onPaste={handlePaste}
                  error={formik.touched.code_4 && Boolean(formik.errors.code_4)}
                  sx={CustomTextField(theme)}
                  inputProps={{
                    maxLength: 1,
                    inputMode: "numeric",
                  }}
                  inputRef={inputRef_4}
                  disabled={isLoading || formik.isSubmitting || clientDetails.cell_phone_verified}
                  value={formik.values.code_4}
                />
              </Box>

              {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}

              <Typography
                variant={isMobileOrTablet ? "body2" : "body1"}
                mt={isMobileOrTablet ? 2 : 3}
                textAlign="center"
                fontWeight={600}
                color={theme?.palette?.primary?.main}
              >
                {formatTime(secondsRemaining)} min
              </Typography>

              {secondsRemaining === 0 && (
                <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3}>
                  <Typography
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    component={"a"}
                    onClick={handleResendCode}
                    sx={{ cursor: "pointer", textDecoration: "underline" }}
                    color={theme?.palette?.primary?.dark}
                  >
                    Reenviar código
                  </Typography>
                </Box>
              )}

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3}>
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={!formik.isValid || isLoading || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Box>
            </form>
            <Box sx={{ mt: isMobileOrTablet ? 2 : 4, mb: 1 }}>
              <TerminosCondiciones />
            </Box>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} bgcolor={theme?.palette?.primary?.light} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
            <CustomRightImage imageName="img_tofu_4" />
          </Box>
        </Grid>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: "center",
          }}
        >
          <Typography fontWeight={"600"} gutterBottom color={theme?.palette?.primary?.dark} fontSize={"20px"}>
            Excedió el número de intentos permitidos para ese número
          </Typography>
          <Typography fontSize={"16px"} sx={{ mt: 2, mb: 2 }} color={theme?.palette?.primary?.dark}>
            intenta más tarde o contacta a un asesor.
          </Typography>
          <Button variant="default" color="primary" onClick={handleClose}>
            Cerrar
          </Button>
        </Box>
      </Modal>
    </Grid>
  );
};

export default ValidacionTelefono;
