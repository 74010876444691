/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  useMediaQuery,
  useTheme,
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  InputLabel,
  Container,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLazyGetByCpQuery } from "@api/postalCodes";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import { useLazyGetAddressTypeQuery } from "@api/catalogs";
import { usePatchAddressMutation, usePostAddressMutation, useLazyGetAddressQuery } from "@api/address";
import { NavBarOcularVisit } from "@components/NavBarOcularVisit";
import { setSectionCompanyDocumentComprobanteDomicilio } from "@store/slices/bofuSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { AddressData, ApiError, DocumentResponse } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import { customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { DOCUMENTS_CATALOG, ADDRESS_CODES } from "@helpers/constantes";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import { Address } from "@interfaces/catalogs";

const AgendaVisitaOcularPM: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const application = useSelector((state: RootState) => state.register.application);
  const tofuSection = useSelector((state: RootState) => state.register);

  const [getByCp] = useLazyGetByCpQuery();
  const [getAddress] = useLazyGetAddressQuery();
  const [getAddressType] = useLazyGetAddressTypeQuery();
  const [updateAddress] = usePatchAddressMutation();
  const [createAddress] = usePostAddressMutation();

  const [colonias, setColonias] = useState<{ id: number; name: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addressTypeConfirmed, setAddressTypeConfirmed] = useState<Address | undefined>(undefined);
  const [address, setAddress] = useState<AddressData | undefined>(undefined);

  const formik = useFormik({
    initialValues: {
      codigoPostal: "",
      calle: "",
      noExterior: "",
      noInterior: "",
      estadoId: "",
      ciudadId: "",
      municipioId: "",
      coloniaId: "",
      estado: "",
      ciudad: "",
      municipio: "",
      otro: "",
    },
    validationSchema: yup.object({
      codigoPostal: yup
        .string()
        .required("Requerido")
        .matches(/^[0-9]{5}$/, "Debe ser un código postal válido"),
      calle: yup.string().required("Requerido"),
      noInterior: yup.string().notRequired(),
      noExterior: yup.string().required(),
      estadoId: yup.string().required("Requerido"),
      ciudadId: yup.string().required("Requerido"),
      municipioId: yup.string().required("Requerido"),
      coloniaId: yup.string().required("Requerido"),
      otro: yup.string().when("coloniaId", {
        is: "-1",
        then: (schema) => schema.required("Campo requerido"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!addressTypeConfirmed) {
          throw new Error("No se encontró el tipo de dirección requerido.");
        }

        if (address?.id) {
          await updateAddress({
            addressId: address.id,
            data: {
              street: values.calle,
              no_ext: values.noExterior,
              no_int: values.noInterior,
              cp: values.codigoPostal,
              country_id: 1,
              state_id: Number(values.estadoId),
              city_id: Number(values.ciudadId),
              colonia_id: Number(values.coloniaId) === -1 ? null : Number(values.coloniaId),
              other_neighborhood: values.otro,
            },
          }).unwrap();
        } else {
          await createAddress({
            client_details_id: application.client_details_id,
            address_type_id: addressTypeConfirmed.id,
            cp: values.codigoPostal,
            street: values.calle,
            no_ext: values.noExterior,
            no_int: values.noInterior,
            country_id: 1,
            state_id: Number(values.estadoId),
            city_id: Number(values.ciudadId),
            colonia_id: Number(values.coloniaId) === -1 ? null : Number(values.coloniaId),
            other_neighborhood: values.otro,
          }).unwrap();
        }

        navigate("/creditosimple/confirmacion_domicilio");
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setSubmitting(false);
      }
    },
  });

  const fetchLocationData = async (codigoPostal: string, coloniaId?: number) => {
    try {
      if (codigoPostal.length === 5) {
        const value = await getByCp(codigoPostal).unwrap();
        const { colonias, cities, states } = value;
        setColonias(colonias);

        formik.setFieldValue("estadoId", states[0]?.id || "");
        formik.setFieldValue("estado", states[0]?.name || "");
        formik.setFieldValue("ciudadId", cities[0]?.id || "");
        formik.setFieldValue("ciudad", cities[0]?.name || "");
        formik.setFieldValue("municipioId", cities[0]?.id || "");
        formik.setFieldValue("municipio", cities[0]?.name || "");

        if (coloniaId) {
          const coloniaSelected = colonias.find((item: any) => item.id == coloniaId);
          formik.setFieldValue("coloniaId", coloniaSelected?.id || "");
        } else {
          formik.setFieldValue("coloniaId", colonias[0]?.id || "");
        }

        setTimeout(() => {
          formik.validateForm();
        }, 200);
      } else {
        formik.setFieldValue("estadoId", "");
        formik.setFieldValue("estado", "");
        formik.setFieldValue("ciudadId", "");
        formik.setFieldValue("ciudad", "");
        formik.setFieldValue("municipioId", "");
        formik.setFieldValue("municipio", "");
        formik.setFieldValue("coloniaId", "");
        formik.setFieldValue("colonia", "");
      }
    } catch (error) {
      console.error("Error fetching location data:", error);
    }
  };

  const handleClickBack = () => {
    navigate("/producto/creditosimple/agenda_visita");
  };

  const fetchAddress = async () => {
    try {
      setIsLoading(true);

      const [addressTypes, rawAddressData] = await Promise.all([
        getAddressType().unwrap(),
        getAddress({
          accountId: application.client_details_id,
        }).unwrap(),
      ]);

      const { operativo, operativo_confirmado } = ADDRESS_CODES;

      const address_type_operative = addressTypes?.find((item) => item.code === operativo.code);
      const address_type_confirmed = addressTypes?.find((item) => item.code === operativo_confirmado.code);

      if (!address_type_operative || !address_type_confirmed) {
        throw new Error("No se encontraron los tipos de dirección requeridos.");
      }

      const address_operative = rawAddressData?.find((item) => item.address_type_id === address_type_operative?.id);
      const address_confirmed = rawAddressData?.find((item) => item.address_type_id === address_type_confirmed?.id);

      if (!address_operative) {
        throw new Error("No se encontró la dirección operativa.");
      }

      setAddressTypeConfirmed(address_type_confirmed);

      if (address_confirmed) {
        formik.setValues({
          codigoPostal: address_confirmed?.cp || "",
          calle: address_confirmed?.street || "",
          noInterior: address_confirmed?.no_int || "",
          noExterior: address_confirmed?.no_ext || "",
          estadoId: "",
          ciudadId: "",
          coloniaId: address_confirmed?.colonia_id?.toString() || "",
          municipioId: "",
          estado: "",
          ciudad: "",
          municipio: "",
          otro: address_confirmed?.other_neighborhood || "",
        });
        setAddress(address_confirmed);

        setTimeout(() => {
          fetchLocationData(address_confirmed?.cp, address_confirmed?.colonia_id);
        }, 200);
      } else if (address_operative) {
        formik.setValues({
          codigoPostal: address_operative?.cp || "",
          calle: address_operative?.street || "",
          noInterior: address_operative?.no_int || "",
          noExterior: address_operative?.no_ext || "",
          estadoId: "",
          ciudadId: "",
          coloniaId: address_operative?.colonia_id?.toString() || "",
          municipioId: "",
          estado: "",
          ciudad: "",
          municipio: "",
          otro: address_operative?.other_neighborhood || "",
        });
        setAddress(undefined);

        setTimeout(() => {
          fetchLocationData(address_operative?.cp, address_operative?.colonia_id);
        }, 200);
      }
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <InactivityHandler />
        <Container maxWidth="lg">
          <Typography
            mt={5}
            variant={isMobileOrTablet ? "h5" : "h4"}
            fontWeight={700}
            color={theme?.palette?.primary?.dark}
            textAlign="center"
          >
            Agenda tu visita ocular
          </Typography>

          <NavBarOcularVisit currentStep={1} />

          <Typography
            variant={isMobileOrTablet ? "body2" : "body1"}
            fontWeight={400}
            color={theme?.palette?.primary?.main}
            textAlign="center"
            mt={3}
          >
            Bienvenido a los últimos pasos para obtener tu crédito, a continuación realizaremos una visita ocular y
            cotejo de documentos
          </Typography>

          <Typography
            my={3}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
            fontWeight={600}
            textAlign="center"
          >
            Tu ejecutivo se pondrá en contacto para agendar la visita a tu domicilio operativo:
          </Typography>

          <Box maxWidth="sm" mx="auto" mt={4} px={2}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="codigoPostal"
                  name="codigoPostal"
                  label="Código Postal"
                  required
                  value={formik.values.codigoPostal}
                  onChange={(e) => {
                    const cp = e.target.value.replace(/[^0-9]/g, "");
                    formik.setFieldValue("codigoPostal", cp);
                    if (cp.length === 5) {
                      fetchLocationData(cp);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  error={formik.touched.codigoPostal && Boolean(formik.errors.codigoPostal)}
                  helperText={formik.touched.codigoPostal ? formik.errors.codigoPostal : ""}
                  margin="normal"
                  disabled={isLoading || formik.isSubmitting}
                  inputProps={{ maxLength: 5 }}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="calle"
                  name="calle"
                  label="Calle o avenida"
                  required
                  value={formik.values.calle}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.calle && Boolean(formik.errors.calle)}
                  helperText={formik.touched.calle ? formik.errors.calle : ""}
                  margin="normal"
                  disabled={isLoading || formik.isSubmitting}
                  inputProps={{ maxLength: 100 }}
                />
              </FormControl>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      id="noExterior"
                      name="noExterior"
                      label="No. Exterior"
                      required
                      value={formik.values.noExterior}
                      onChange={formik.handleChange}
                      error={formik.touched.noExterior && Boolean(formik.errors.noExterior)}
                      helperText={formik.touched.noExterior ? formik.errors.noExterior : ""}
                      margin="normal"
                      disabled={isLoading || formik.isSubmitting}
                      inputProps={{ maxLength: 20 }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      fullWidth
                      id="noInterior"
                      name="noInterior"
                      label="No. Interior"
                      value={formik.values.noInterior}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.noInterior && Boolean(formik.errors.noInterior)}
                      helperText={formik.touched.noInterior ? formik.errors.noInterior : ""}
                      margin="normal"
                      disabled={isLoading || formik.isSubmitting}
                      inputProps={{ maxLength: 20 }}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl
                fullWidth
                required
                sx={{
                  ...customStylesAsterisk,
                  mt: 1,
                }}
                error={formik.touched.coloniaId && Boolean(formik.errors.coloniaId)}
              >
                <InputLabel id={"coloniaId"}>Colonia</InputLabel>
                <Select
                  labelId="coloniaId"
                  name="coloniaId"
                  id="coloniaId"
                  fullWidth
                  required
                  label="Colonia"
                  value={formik.values.coloniaId}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={formik.touched.coloniaId && Boolean(formik.errors.coloniaId)}
                  sx={{ textAlign: "left" }}
                  disabled={isLoading || formik.isSubmitting}
                >
                  <MenuItem value="">Selecciona</MenuItem>
                  {colonias.map((colonia) => (
                    <MenuItem key={colonia.id} value={colonia.id}>
                      {colonia.name}
                    </MenuItem>
                  ))}
                </Select>
                {formik.touched.coloniaId && formik.errors.coloniaId && (
                  <FormHelperText>{formik.errors.coloniaId}</FormHelperText>
                )}
              </FormControl>

              {String(formik.values.coloniaId) === "-1" && (
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        id="otro"
                        name="otro"
                        label="Otra Colonia"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={formik.touched.otro && Boolean(formik.errors.otro)}
                        helperText={formik.touched.otro && formik.errors.otro}
                        value={formik.values.otro}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="ciudad"
                  name="ciudad"
                  label="Ciudad"
                  required
                  value={formik.values.ciudad}
                  error={formik.touched.ciudadId && Boolean(formik.errors.ciudadId)}
                  helperText={formik.touched.ciudadId ? formik.errors.ciudadId : ""}
                  margin="normal"
                  disabled={true}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="municipio"
                  name="municipio"
                  label="Municipio"
                  value={formik.values.municipio}
                  error={formik.touched.municipioId && Boolean(formik.errors.municipioId)}
                  helperText={formik.touched.municipioId ? formik.errors.municipioId : ""}
                  margin="normal"
                  disabled={true}
                />
              </FormControl>

              <FormControl fullWidth>
                <TextField
                  fullWidth
                  id="estado"
                  name="estado"
                  label="Entidad federativa"
                  value={formik.values.estado}
                  error={formik.touched.estadoId && Boolean(formik.errors.estadoId)}
                  helperText={formik.touched.estadoId ? formik.errors.estadoId : ""}
                  margin="normal"
                  disabled={true}
                />
              </FormControl>

              <CustomUploadFileV2
                mt={2}
                inputName={"comprobante_domicilio"}
                uploadedFile={undefined}
                setUploadedFile={(newUpload: DocumentResponse | undefined) => {
                  dispatch(setSectionCompanyDocumentComprobanteDomicilio(newUpload));
                }}
                optionalDescription="No deberá ser mayor a 3 meses"
                metadata={{
                  fileCode: DOCUMENTS_CATALOG.comprobante_domicilio_confirmado.code,
                  uploadType: "client",
                  clientDetailsId: tofuSection.personal_data.id,
                }}
                required
              />

              <Grid container columnSpacing={1} my={4}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    variant="blue_outlined"
                    sx={{ padding: "0", height: "100%" }}
                    onClick={handleClickBack}
                    disabled={formik.isSubmitting || isLoading}
                  >
                    Regresar
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    variant="default"
                    fullWidth
                    color="primary"
                    disabled={!formik.isValid || formik.isSubmitting || isLoading}
                  >
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Container>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
            <CustomRightImage imageName="img_bofu_1" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default AgendaVisitaOcularPM;
