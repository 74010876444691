/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Checkbox,
  Stack,
  FormHelperText,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import { useBuroValidatorMutation, BuroValidatorRequest } from "@api/sat_apis_new/satApsi";
import { getDynamicUrl } from "@helpers/funciones";
import { ProgressBar, CustomSwitch, CustomDialog } from "@components/index";
import { usePostValidateNextStageMutation } from "@api/applications";
import { setModalErrorVisible, setProcessPage } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { setCheckSatBureau } from "@store/slices/registerSlice";
import { STATUS_CATALOG } from "@helpers/constantes";
import { CustomTextCiecBuro } from "@components/CustomTextCiecBuro";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import * as Sentry from "@sentry/react";
import { useLazyGetColonyByIdQuery } from "@api/catalogs";

const ConsultaBuro = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const icon = getDynamicUrl("icon_buro.svg");

  const shareholderData = useSelector((state: RootState) => state.register.shareholder);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const addressData = useSelector((state: RootState) => state.register.address_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [buroValidator] = useBuroValidatorMutation();

  const [closeModal, setCloseModal] = useState<boolean>(true);
  const [closeModalError, setCloseModalError] = useState<boolean>(true);
  const [colonia, setColonia] = useState<string>("");

  const [getColony] = useLazyGetColonyByIdQuery();

  const continueToWaitingRoom = async () => {
    try {
      const next_status = STATUS_CATALOG.waiting_room.code;
      const next_url = STATUS_CATALOG.waiting_room.url;

      await triggerPostNextStage({
        applicationId: applicationData.id,
        statusCode: next_status,
        username: `TOFU - ${accountUser.email}`,
      }).unwrap();

      dispatch(setProcessPage(STATUS_CATALOG.waiting_room.url));
      dispatch(setCheckSatBureau({ check_sat_bureau: true }));
      navigate(next_url);
    } catch (error) {
      Sentry.captureException(error, {
        extra: {
          component: "ConsultaBuro",
          values: {},
        },
      });
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    }
  };

  const formik = useFormik({
    initialValues: {
      prestamo_hipotecario: false,
      credito_automotriz: false,
      tarjeta_credito: false,
      tarjeta: "",
      authorize: false,
      check_sat_bureau: shareholderData.check_sat_bureau,
    },
    validationSchema: yup.object().shape({
      credito_automotriz: yup.boolean(),
      prestamo_hipotecario: yup.boolean(),
      tarjeta_credito: yup.boolean(),
      authorize: yup.boolean().when("check_sat_bureau", {
        is: false,
        then: (schema) => schema.isTrue("Se requiere aceptar"),
        otherwise: (schema) => schema.notRequired(),
      }),
      tarjeta: yup.string().when("tarjeta_credito", {
        is: true,
        then: (schema) => schema.required("Campo requerido").min(4, "Formato de tarjeta no válido"),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (!shareholderData.check_sat_bureau) {
        try {
          setSubmitting(true);

          // se limita a 26 caracteres por campo, ya que es el máximo permitido por BURO
          const first_name = shareholderData.name.substring(0, 26).trim();
          const first_lastname = shareholderData.first_lastname.substring(0, 26).trim();
          const second_lastname = shareholderData.second_lastname.substring(0, 26).trim();
          const locality = colonia.substring(0, 40).trim();

          const payload_buro: BuroValidatorRequest = {
            person_Type: "pf",
            data: {
              rfc: shareholderData.rfc + shareholderData.rfc_homoclave,
              curp: shareholderData.curp,
              transaction_number: String(applicationData.id),
              application_code: applicationData.company_application_code,
              legal_name: first_name,
              first_name: first_name,
              second_name: "",
              father_last_name: first_lastname,
              mother_last_name: second_lastname === "" ? "" : second_lastname,
              reenter_NIP: true,
              authorization_label_response: formik.values.authorize,
              loan_application_id: String(applicationData.id),
              person_id: String(shareholderData?.id),
              is_endorsement: true,
              pf: {
                mortgage_credit: values.prestamo_hipotecario,
                automotive_credit: values.credito_automotriz,
                credit_card: values.tarjeta_credito,
                last_four_digits: values.tarjeta,
              },
              address: {
                street: addressData.street,
                exteriorNumber: addressData.no_ext,
                interiorNumber: addressData.no_int,
                state: addressData.state_name,
                municipality: addressData.city_name,
                locality: locality,
                postalCode: addressData.postal_code,
              },
            },
          };

          await buroValidator(payload_buro).unwrap();

          await continueToWaitingRoom();
        } catch (error) {
          const apiError = error as ApiError;
          if (apiError.status === 409) {
            await continueToWaitingRoom();
          } else if (apiError.status === 403) {
            setCloseModalError(false);
          } else {
            Sentry.captureException(error, {
              extra: {
                component: "ConsultaBuro",
                values: {},
              },
            });
            dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
          }
        } finally {
          setSubmitting(false);
        }
      } else {
        await continueToWaitingRoom();
      }
    },
    validateOnMount: true,
  });

  const handleOnCloseModal = () => {
    setCloseModal(true);
  };

  const handleOnCloseModalError = () => {
    setCloseModalError(true);
  };

  useEffect(() => {
    if (formik.values.authorize) {
      setCloseModal(false);
    } else {
      setCloseModal(true);
    }
  }, [formik.values.authorize]);

  useEffect(() => {
    if (addressData.colony_id === -1) {
      setColonia(String(addressData.other_neighborhood));
      return;
    }
    const fetchCp = async () => {
      try {
        const response = await getColony({ colonyId: addressData.colony_id }).unwrap();
        setColonia(response.name);
      } catch (error) {
        console.log("Error al obtener los datos iniciales");
      }
    };

    fetchCp();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          <InactivityHandler />
          <Box
            sx={{
              mt: isMobileOrTablet ? 2 : 4,
              mx: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant={isMobileOrTablet ? "h4" : "h3"}
              fontWeight={500}
              textAlign="center"
              color={theme?.palette?.primary?.dark}
            >
              Consulta Buró
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
              <ProgressBar progress={10} />
            </Container>

            <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3} textAlign={"center"}>
              <Box display="flex" alignItems="center" flexDirection={isMobileOrTablet ? "column" : "row"}>
                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  color={theme?.palette?.primary?.dark}
                  component="span"
                >
                  Haremos una consulta al Buró de Crédito del accionista mayoritario{" "}
                  <img
                    src={icon}
                    alt="icon buro"
                    style={{
                      width: 10,
                      marginLeft: 5,
                      marginTop: 5,
                    }}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <Grid container rowSpacing={1}>
                <Grid item>
                  <FormControlLabel
                    sx={{ color: theme.palette.primary.dark }}
                    control={
                      <Checkbox
                        id="one"
                        name="prestamo_hipotecario"
                        onChange={formik.handleChange}
                        disabled={shareholderData.check_sat_bureau}
                      />
                    }
                    label="¿Tiene un crédito hipotecario activo que se encuentre pagando actualmente?"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    sx={{ color: theme.palette.primary.dark }}
                    control={
                      <Checkbox
                        id="two"
                        name="credito_automotriz"
                        onChange={formik.handleChange}
                        disabled={shareholderData.check_sat_bureau}
                      />
                    }
                    label="¿Ha tenido un crédito automotriz en los últimos 2 años?"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    sx={{ color: theme.palette.primary.dark }}
                    control={
                      <Checkbox
                        id="three"
                        name="tarjeta_credito"
                        onChange={formik.handleChange}
                        disabled={shareholderData.check_sat_bureau}
                      />
                    }
                    label="¿Es titular de una tarjeta de crédito activa?"
                  />
                  <Typography color={theme.palette.primary.dark} fontSize={"11px"} ml={4.5} mb={1}>
                    Las tarjetas de servicio, adicionales, corporativas de la empresa, así como las de débito y nómina
                    no se consideran para fines de está validación.
                  </Typography>
                </Grid>
                {formik.values.tarjeta_credito && (
                  <Grid item xs={12}>
                    <TextField
                      id="tarjeta"
                      name="tarjeta"
                      label="Últimos 4 dígitos tarjeta de crédito"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      inputProps={{ maxLength: 4, inputMode: "numeric" }}
                      disabled={!formik.values.tarjeta_credito}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        if (event.target.value.match(/[^0-9]/)) {
                          event.preventDefault();
                          return;
                        }
                        formik.handleChange(event);
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.tarjeta}
                      error={formik.touched.tarjeta && Boolean(formik.errors.tarjeta)}
                      helperText={formik.touched.tarjeta && formik.errors.tarjeta}
                    />
                  </Grid>
                )}
                <Grid item xs={12} py={2} mx={1}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <CustomSwitch id="authorize" name="authorize" onChange={formik.handleChange} />
                    <CustomTextCiecBuro type="textBuro" />
                  </Stack>
                  {Boolean(formik.touched.authorize) && Boolean(formik.errors.authorize) && (
                    <FormHelperText sx={{ ml: 2 }}>{formik.errors.authorize}</FormHelperText>
                  )}
                </Grid>
              </Grid>
              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <CustomTextCiecBuro type="avisoTerminosTextBuro" />
              </Box>

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button
                  variant="default"
                  type="submit"
                  color="primary"
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Grid>

        {isMobileOrTablet == false && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme.palette.primary.light }}>
              <CustomRightImage imageName="img_tofu_12" />
            </Box>
          </Grid>
        )}
      </Grid>

      <CustomDialog
        colorClose={theme.palette.primary.light}
        open={!closeModal}
        onClose={handleOnCloseModal}
        itemTitle={
          <Typography textAlign={"center"} variant="subtitle1" fontWeight={600} color={theme.palette.primary.dark}>
            Consulta a Buró de Crédito
          </Typography>
        }
        itemContent={<CustomTextCiecBuro type="modalBuro" />}
      />

      <CustomDialog
        colorClose="#F2704F"
        open={!closeModalError}
        onClose={handleOnCloseModalError}
        itemTitle={
          <Typography textAlign={"center"} variant="subtitle1" fontWeight={600} color={"#F2704F"}>
            Datos Incorrectos
          </Typography>
        }
        itemContent={
          <Typography
            textAlign={"center"}
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme.palette.primary.dark}
          >
            <span>
              Los datos ingresados no coinciden con la <br /> información registrada en Buró de Crédito. <br /> Por
              favor, revisa la información <br /> proporcionada y vuelve a intentarlo.
            </span>
          </Typography>
        }
      />
    </>
  );
};

export default ConsultaBuro;
