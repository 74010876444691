/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  Typography,
  Container,
  useTheme,
  useMediaQuery,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import CustomRadioGroup from "@components/CustomRadioGroup";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import {
  usePatchPersonMutation,
  usePostPersonPepsMutation,
  useLazyGetPersonPepsQuery,
  useLazyGetPersonByIdQuery,
} from "@api/personasApi";
import { useEffect, useState } from "react";
import {
  useLazyGetCargoPublicoQuery,
  useLazyGetDependeciasQuery,
  useLazyGetPepsTypeQuery,
  useLazyGetRelationshipQuery,
} from "@api/catalogs";
import { CargoPublicoData, DependenciasData, PersonaStructure, RelationshipData } from "@interfaces/index";
import { customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import dayjs from "dayjs";
import { InactivityHandler } from "@components/InactivityHandler";
import { PEP_CODES } from "@helpers/constantes";
import { UUID } from "crypto";
import CustomDatePicker from "@components/CustomDatePicker";
import { separarNombres } from "@helpers/funciones";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";

const DatosPropietarioRealPart2: React.FC = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const application = useSelector((state: RootState) => state.register.application);
  const ownerId = useSelector((state: RootState) => state.bofu.digital_file.current_owner_id);

  const [patchPerson] = usePatchPersonMutation();
  const [getPersona] = useLazyGetPersonByIdQuery();
  const [postPersonPeps] = usePostPersonPepsMutation();
  const [getPersonPeps] = useLazyGetPersonPepsQuery();
  const [getDependeciasLista] = useLazyGetDependeciasQuery();
  const [getCargoPublico] = useLazyGetCargoPublicoQuery();
  const [getPepsType] = useLazyGetPepsTypeQuery();
  const [getFamiliar] = useLazyGetRelationshipQuery();

  const [listDependecias, setListDependecias] = useState<DependenciasData[]>([]);
  const [listCargo, setListCargo] = useState<CargoPublicoData[]>([]);
  const [listFamiliar, setListFamiliar] = useState<RelationshipData[]>([]);
  const [propietarioSelected, setPropietarioSelected] = useState<PersonaStructure | null>(null);
  const [idPepTypePropio, setIdPepTypePropio] = useState<UUID | null>(null);
  const [idPepTypeFamiliar, setIdPepTypeFamiliar] = useState<UUID | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      publicas: "",
      actualmente: "",
      conyuge: "",
      dependencia: "",
      cargo: "",
      start_date: "",
      end_date: "",
      peps_conyuge: [
        {
          parentesco_conyuge: "",
          nombre_conyuge: "",
          ap_paterno_conyuge: "",
          ap_materno_conyuge: "",
          dependencia_conyuge: "",
          cargo_conyuge: "",
          actualmente_conyuge: "",
          start_date_conyuge: "",
          end_date_conyuge: "",
          id: "",
        },
      ],
    },
    validationSchema: yup.object().shape({
      acutuas: yup.string().required("Campo requerido"),
      recursos: yup.string().required("Campo requerido"),
      publicas: yup.string().required("Campo requerido"),
      conyuge: yup.string().required("Campo requerido"),
      dependencia: yup.string().when("publicas", {
        is: "true",
        then: (schema) => schema.required("Campo requerido"),
      }),
      cargo: yup.string().when("publicas", {
        is: "true",
        then: (schema) => schema.required("Campo requerido"),
      }),
      actualmente: yup.string().when("publicas", {
        is: "true",
        then: (schema) => schema.required("Campo requerido"),
      }),
      start_date: yup.string().when("publicas", {
        is: "true",
        then: (schema) =>
          schema.required("Campo requerido").matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido"),
      }),
      end_date: yup
        .string()
        .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido")
        .when("actualmente", {
          is: "false",
          then: (schema) =>
            schema
              .required("Campo requerido")
              .test(
                "end_date_greater_than_start_date",
                "La fecha de término no puede ser menor que la fecha de inicio",
                function (value) {
                  const { start_date } = this.parent;
                  if (start_date && value) {
                    return dayjs(value).isSameOrAfter(dayjs(start_date));
                  }
                  return true;
                },
              )
              .test("end_date_not_in_future", "La fecha de término no puede ser mayor al día actual", (value) => {
                if (value) {
                  return dayjs(value).isSameOrBefore(dayjs(), "day");
                }
                return true;
              }),
        }),
      peps_conyuge: yup.array().of(
        yup.object().shape({
          parentesco_conyuge: yup
            .string()
            .when("conyuge", {
              is: "true",
              then: (schema) => schema.required("Campo requerido"),
              otherwise: (schema) => schema.notRequired(),
            })
            .when("$conyuge", ([conyuge], schema) =>
              conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
            ),
          nombre_conyuge: yup
            .string()
            .when("conyuge", {
              is: "true",
              then: (schema) => schema.required("Campo requerido"),
              otherwise: (schema) => schema.notRequired(),
            })
            .when("$conyuge", ([conyuge], schema) =>
              conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
            ),
          ap_paterno_conyuge: yup
            .string()
            .when("conyuge", {
              is: "true",
              then: (schema) => schema.required("Campo requerido"),
              otherwise: (schema) => schema.notRequired(),
            })
            .when("$conyuge", ([conyuge], schema) =>
              conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
            ),
          ap_materno_conyuge: yup
            .string()
            .when("conyuge", {
              is: "true",
              then: (schema) => schema.required("Campo requerido"),
              otherwise: (schema) => schema.notRequired(),
            })
            .when("$conyuge", ([conyuge], schema) =>
              conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
            ),
          dependencia_conyuge: yup
            .string()
            .when("conyuge", {
              is: "true",
              then: (schema) => schema.required("Campo requerido"),
              otherwise: (schema) => schema.notRequired(),
            })
            .when("$conyuge", ([conyuge], schema) =>
              conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
            ),
          cargo_conyuge: yup
            .string()
            .when("conyuge", {
              is: "true",
              then: (schema) => schema.required("Campo requerido"),
              otherwise: (schema) => schema.notRequired(),
            })
            .when("$conyuge", ([conyuge], schema) =>
              conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
            ),
          actualmente_conyuge: yup
            .string()
            .when("conyuge", {
              is: "true",
              then: (schema) => schema.required("Campo requerido"),
              otherwise: (schema) => schema.notRequired(),
            })
            .when("$conyuge", ([conyuge], schema) =>
              conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
            ),
          start_date_conyuge: yup
            .string()
            .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido")
            .when("conyuge", {
              is: "true",
              then: (schema) => schema.required("Campo requerido"),
              otherwise: (schema) => schema.notRequired(),
            })
            .when("$conyuge", ([conyuge], schema) =>
              conyuge === "true" ? schema.required("Campo requerido") : schema.notRequired(),
            ),
          end_date_conyuge: yup
            .string()
            .matches(/^\d{4}-\d{2}-\d{2}$/, "Formato de fecha no válido")
            .when("actualmente_conyuge", {
              is: "false",
              then: (schema) =>
                schema
                  .required("Campo requerido")
                  .test(
                    "end_date_greater_than_start_date",
                    "La fecha de término no puede ser menor que la fecha de inicio",
                    function (value) {
                      const { start_date_conyuge } = this.parent;
                      if (start_date_conyuge && value) {
                        return dayjs(value).isSameOrAfter(dayjs(start_date_conyuge), "day");
                      }
                      return true;
                    },
                  )
                  .test("end_date_not_in_future", "La fecha de término no puede ser mayor al día actual", (value) => {
                    if (value) {
                      return dayjs(value).isSameOrBefore(dayjs(), "day");
                    }
                    return true;
                  }),
              otherwise: (schema) => schema.notRequired(),
            }),
        }),
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!propietarioSelected || !idPepTypePropio || !idPepTypeFamiliar) {
          return;
        }

        setSubmitting(true);
        const payload_peps: { peps: any[] } = {
          peps: [],
        };

        if (values.publicas) {
          payload_peps.peps.push({
            loan_application_id: application.id,
            person_id: propietarioSelected.id,
            pep_type_id: idPepTypePropio,
            name: `${propietarioSelected.name} ${propietarioSelected.last_name} ${propietarioSelected.last_name_2}`,
            position: values.cargo || null,
            dependency: values.dependencia || null,
            active: values.actualmente === "true",
            start_date: values.start_date || null,
            end_date: values.end_date || null,
            relationship_id: null,
          });
        }

        if (values.conyuge) {
          values.peps_conyuge.forEach((item) => {
            payload_peps.peps.push({
              loan_application_id: application.id,
              person_id: propietarioSelected.id,
              pep_type_id: idPepTypeFamiliar,
              name: `${item.nombre_conyuge} ${item.ap_paterno_conyuge} ${item.ap_materno_conyuge}`,
              position: item.cargo_conyuge || null,
              dependency: item.dependencia_conyuge || null,
              active: item.actualmente_conyuge === "true",
              start_date: item.start_date_conyuge || null,
              end_date: item.end_date_conyuge || null,
              relationship_id: item.parentesco_conyuge || null,
            });
          });
        }

        await postPersonPeps({
          personId: propietarioSelected.id,
          body: payload_peps,
        }).unwrap();

        await patchPerson({
          personId: propietarioSelected.id,
          body: {
            pep_shareholder: values.publicas === "true",
            pep_members: values.conyuge === "true",
            information_person_completed: true,
          },
        }).unwrap();

        navigate("/producto/creditosimple/propietario_real_pm");
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setSubmitting(false);
      }
    },
  });

  const getFieldError = (fieldName: string, index: number) => {
    const error: any = formik.errors.peps_conyuge?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  const handleAddConyuge = () => {
    if (formik.values.peps_conyuge.length < 5) {
      formik.setFieldValue("peps_conyuge", [
        ...formik.values.peps_conyuge,
        {
          parentesco_conyuge: "",
          nombre_conyuge: "",
          ap_paterno_conyuge: "",
          ap_materno_conyuge: "",
          dependencia_conyuge: "",
          cargo_conyuge: "",
          actualmente_conyuge: "",
          start_date_conyuge: "",
          end_date_conyuge: "",
        },
      ]);
    }
  };

  const handleRemoveConyuge = async (index: number) => {
    const updatedPepsConyuge = formik.values.peps_conyuge.filter((_, i) => i !== index);
    formik.setFieldValue("peps_conyuge", updatedPepsConyuge);
    if (updatedPepsConyuge.length === 0) {
      formik.setFieldValue("conyuge", "false");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        if (!ownerId) {
          navigate("/producto/creditosimple/propietario_real_pm");
          return;
        }

        const data_propietario = await getPersona(ownerId).unwrap();
        if (!data_propietario) {
          navigate("/producto/creditosimple/propietario_real_pm");
          return;
        }

        setPropietarioSelected(data_propietario);

        formik.setFieldValue("acutuas", data_propietario.own_account ? "true" : "false");
        formik.setFieldValue("recursos", data_propietario.own_resources ? "true" : "false");
        formik.setFieldValue("publicas", String(data_propietario.pep_shareholder ?? ""));
        formik.setFieldValue("conyuge", String(data_propietario.pep_members ?? ""));

        const [data_peps_type, data_familiar, data_cargo, data_dependencia, data_peps_aval] = await Promise.all([
          getPepsType().unwrap(),
          getFamiliar().unwrap(),
          getCargoPublico().unwrap(),
          getDependeciasLista().unwrap(),
          getPersonPeps({
            personId: data_propietario.id,
          }).unwrap(),
        ]);

        const pep_type_propio = data_peps_type.find((item) => item.name === PEP_CODES.propio.name);
        const pep_type_familiar = data_peps_type.find((item) => item.name === PEP_CODES.familiar.name);

        setListDependecias(data_dependencia);
        setListCargo(data_cargo);
        setListFamiliar(data_familiar);
        setIdPepTypePropio(pep_type_propio?.id || null);
        setIdPepTypeFamiliar(pep_type_familiar?.id || null);

        const peps_propio = data_peps_aval.find((item) => item.pep_type_id === pep_type_propio?.id);
        const peps_familiares = data_peps_aval.filter((item) => item.pep_type_id === pep_type_familiar?.id);

        if (data_propietario.pep_shareholder && peps_propio) {
          formik.setFieldValue("dependencia", peps_propio.dependency);
          formik.setFieldValue("cargo", peps_propio.position);
          formik.setFieldValue("actualmente", !peps_propio.end_date ? "true" : "false");
          formik.setFieldValue(
            "start_date",
            peps_propio.start_date ? dayjs(peps_propio.start_date).format("YYYY-MM-DD") : "",
          );
          formik.setFieldValue(
            "end_date",
            peps_propio.end_date ? dayjs(peps_propio.end_date).format("YYYY-MM-DD") : "",
          );
        }

        if (data_propietario.pep_members && peps_familiares.length > 0) {
          const peps_conyuge: any = [];
          peps_familiares.forEach((item) => {
            const nombreCompleto = separarNombres(item.name || "");
            peps_conyuge.push({
              parentesco_conyuge: item.relationship_id,
              nombre_conyuge: nombreCompleto.nombre,
              ap_paterno_conyuge: nombreCompleto.apellido1 || "",
              ap_materno_conyuge: nombreCompleto.apellido2 || "",
              dependencia_conyuge: item.dependency,
              cargo_conyuge: item.position,
              actualmente_conyuge: item.active ? "true" : "false",
              start_date_conyuge: dayjs(item.start_date).format("YYYY-MM-DD"),
              end_date_conyuge: item.end_date ? dayjs(item.end_date).format("YYYY-MM-DD") : "",
            });
          });
          formik.setFieldValue("peps_conyuge", peps_conyuge);
        }

        setTimeout(() => {
          formik.validateForm();
        }, 200);
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (formik.values.peps_conyuge.length === 0 && formik.values.conyuge === "true") {
      formik.setFieldValue("peps_conyuge", [
        ...formik.values.peps_conyuge,
        {
          nombre_conyuge: "",
          ap_paterno_conyuge: "",
          ap_materno_conyuge: "",
          dependencia_conyuge: "",
          cargo_conyuge: "",
          actualmente_conyuge: "",
          start_date_conyuge: "",
          end_date_conyuge: "",
          id: "",
        },
      ]);
    }
  }, [formik.values.conyuge, formik.values.peps_conyuge]);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={2}>
        <InactivityHandler />
        <Box maxWidth="sm" mx="auto" mt={4}>
          <Typography align={"center"} fontSize={"16px"} fontWeight={800} color={theme?.palette?.primary?.dark}>
            Información del Propietario Real
          </Typography>

          <Typography
            mt={3}
            textAlign="center"
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.dark}
          >
            <strong>2</strong> de 2
          </Typography>

          <Typography
            mt={3}
            textAlign="center"
            variant={isMobileOrTablet ? "body2" : "body1"}
            color={theme?.palette?.primary?.main}
            fontWeight={600}
          >
            {propietarioSelected?.name} {propietarioSelected?.last_name} {propietarioSelected?.last_name_2}
          </Typography>

          <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box textAlign={"left"} my={2}>
                <CustomRadioGroup
                  label={
                    "¿Desempeñas o has desempeñado funciones públicas destacadas en México o el extranjero, " +
                    "altos puestos ejecutivos en empresas estatales o funciones importantes en partidos políticos?​"
                  }
                  name={"publicas"}
                  value={formik.values.publicas}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.publicas && Boolean(formik.errors.publicas)}
                  helperText={formik.touched.publicas && formik.errors.publicas}
                  options={[
                    {
                      label: "Si",
                      value: "true",
                    },
                    {
                      label: "No",
                      value: "false",
                    },
                  ]}
                />
              </Box>

              {formik.values.publicas === "true" && (
                <>
                  <FormControl
                    fullWidth
                    required
                    sx={{
                      ...customStylesAsterisk,
                      mt: 2,
                    }}
                    error={formik.touched.dependencia && Boolean(formik.errors.dependencia)}
                  >
                    <InputLabel id="dependencia">Dependencia del cargo</InputLabel>
                    <Select
                      labelId="dependencia"
                      id="dependencia"
                      name="dependencia"
                      fullWidth
                      required
                      label="Dependencia del cargo"
                      value={formik.values.dependencia}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.dependencia && Boolean(formik.errors.dependencia)}
                    >
                      <MenuItem value={""}>Selecciona</MenuItem>
                      {listDependecias.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.dependencia && formik.errors.dependencia && (
                      <FormHelperText>{formik.errors.dependencia}</FormHelperText>
                    )}
                  </FormControl>

                  <FormControl
                    fullWidth
                    required
                    sx={{
                      ...customStylesAsterisk,
                      mt: 2,
                    }}
                    error={formik.touched.cargo && Boolean(formik.errors.cargo)}
                  >
                    <InputLabel id="cargo">Cargo público</InputLabel>
                    <Select
                      labelId="cargo"
                      id="cargo"
                      name="cargo"
                      fullWidth
                      required
                      label="Cargo público"
                      value={formik.values.cargo}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={formik.touched.cargo && Boolean(formik.errors.cargo)}
                    >
                      <MenuItem value={""}>Selecciona</MenuItem>
                      {listCargo.map((item) => (
                        <MenuItem key={item.id} value={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.cargo && formik.errors.cargo && (
                      <FormHelperText>{formik.errors.cargo}</FormHelperText>
                    )}
                  </FormControl>
                </>
              )}

              {formik.values.publicas === "true" && (
                <>
                  <Box textAlign={"left"} my={2}>
                    <CustomRadioGroup
                      label={"¿Actualmente esta en ese cargo?​"}
                      name={"actualmente"}
                      value={formik.values.actualmente}
                      onChange={(e: any) => {
                        formik.handleChange(e);

                        if (e.target.value === "true") {
                          formik.setFieldValue(`end_date`, "");
                        }
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.actualmente && Boolean(formik.errors.actualmente)}
                      helperText={formik.touched.actualmente && formik.errors.actualmente}
                      options={[
                        {
                          label: "Si",
                          value: "true",
                        },
                        {
                          label: "No",
                          value: "false",
                        },
                      ]}
                    />
                  </Box>

                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <CustomDatePicker
                      label="Fecha de Inicio"
                      name={"start_date"}
                      value={formik.values.start_date}
                      onChange={formik.handleChange}
                      maxDate={dayjs()}
                      onBlur={formik.handleBlur}
                      error={formik.touched.start_date && Boolean(formik.errors.start_date)}
                      helperText={formik.touched.start_date && formik.errors.start_date}
                      disabled={isLoading}
                    />
                  </FormControl>

                  {formik.values.actualmente === "false" && (
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <CustomDatePicker
                        label="Fecha de termino"
                        name={"end_date"}
                        value={formik.values.end_date}
                        onChange={formik.handleChange}
                        minDate={formik.values.start_date ? dayjs(formik.values.start_date) : undefined}
                        maxDate={dayjs()}
                        onBlur={formik.handleBlur}
                        error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                        helperText={formik.touched.end_date && formik.errors.end_date}
                        disabled={isLoading}
                      />
                    </FormControl>
                  )}
                </>
              )}

              <Box textAlign={"left"} my={2}>
                <CustomRadioGroup
                  label={
                    "¿Tu cónyuge o alguno de tus padres, abuelos, hijos, nietos, hermanos, " +
                    "suegros, hijos políticos, abuelos políticos o cuñados, desempeña o ha " +
                    "desempeñado funciones públicas destacadas en México o el extranjero, altos puestos ejecutivos " +
                    "en empresas estatales o funciones importantes en partidos políticos?​"
                  }
                  name={"conyuge"}
                  value={formik.values.conyuge}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.conyuge && Boolean(formik.errors.conyuge)}
                  helperText={formik.touched.conyuge && formik.errors.conyuge}
                  options={[
                    {
                      label: "Si",
                      value: "true",
                    },
                    {
                      label: "No",
                      value: "false",
                    },
                  ]}
                />
              </Box>

              {formik.values.conyuge === "true" && (
                <>
                  {formik.values.peps_conyuge.map((pep, index) => (
                    <Box key={index}>
                      <FormControl
                        fullWidth
                        required
                        sx={{
                          ...customStylesAsterisk,
                          mt: 2,
                        }}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                            getFieldError("parentesco_conyuge", index),
                        )}
                      >
                        <InputLabel id={`parentesco_conyuge-${index}`}>Parentesco</InputLabel>
                        <Select
                          labelId={`parentesco_conyuge-${index}`}
                          id={`parentesco_conyuge-${index}`}
                          name={`peps_conyuge[${index}].parentesco_conyuge`}
                          fullWidth
                          required
                          label="Parentesco"
                          value={pep.parentesco_conyuge}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                              getFieldError("parentesco_conyuge", index),
                          )}
                        >
                          <MenuItem value={""}>Selecciona</MenuItem>
                          {listFamiliar.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>

                        {formik.touched.peps_conyuge?.[index]?.parentesco_conyuge &&
                          getFieldError("parentesco_conyuge", index) && (
                            <FormHelperText>{getFieldError("parentesco_conyuge", index)}</FormHelperText>
                          )}
                      </FormControl>

                      <TextField
                        id={`nombre_conyuge-${index}`}
                        name={`peps_conyuge[${index}].nombre_conyuge`}
                        label="Nombre(s)"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.nombre_conyuge &&
                            getFieldError("nombre_conyuge", index),
                        )}
                        helperText={
                          formik.touched.peps_conyuge?.[index]?.nombre_conyuge && getFieldError("nombre_conyuge", index)
                        }
                        value={pep.nombre_conyuge}
                      />

                      <TextField
                        id={`ap_paterno_conyuge-${index}`}
                        name={`peps_conyuge[${index}].ap_paterno_conyuge`}
                        label="Apellido paterno"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.ap_paterno_conyuge &&
                            getFieldError("ap_paterno_conyuge", index),
                        )}
                        helperText={
                          formik.touched.peps_conyuge?.[index]?.ap_paterno_conyuge &&
                          getFieldError("ap_paterno_conyuge", index)
                        }
                        value={pep.ap_paterno_conyuge}
                      />

                      <TextField
                        id={`ap_materno_conyuge-${index}`}
                        name={`peps_conyuge[${index}].ap_materno_conyuge`}
                        label="Apellido materno"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="off"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.ap_materno_conyuge &&
                            getFieldError("ap_materno_conyuge", index),
                        )}
                        helperText={
                          formik.touched.peps_conyuge?.[index]?.ap_materno_conyuge &&
                          getFieldError("ap_materno_conyuge", index)
                        }
                        value={pep.ap_materno_conyuge}
                      />

                      <FormControl
                        fullWidth
                        required
                        sx={{
                          ...customStylesAsterisk,
                          mt: 2,
                        }}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                            getFieldError("dependencia_conyuge", index),
                        )}
                      >
                        <InputLabel id={`dependencia_conyuge-${index}`}>Dependencia del cargo</InputLabel>
                        <Select
                          labelId={`dependencia_conyuge-${index}`}
                          id={`dependencia_conyuge-${index}`}
                          name={`peps_conyuge[${index}].dependencia_conyuge`}
                          fullWidth
                          required
                          label="Dependencia del cargo"
                          value={pep.dependencia_conyuge}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                              getFieldError("dependencia_conyuge", index),
                          )}
                        >
                          <MenuItem value={""}>Selecciona</MenuItem>
                          {listDependecias.map((item) => (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.peps_conyuge?.[index]?.dependencia_conyuge &&
                          getFieldError("dependencia_conyuge", index) && (
                            <FormHelperText>{getFieldError("dependencia_conyuge", index)}</FormHelperText>
                          )}
                      </FormControl>

                      <FormControl
                        fullWidth
                        required
                        sx={{
                          ...customStylesAsterisk,
                          mt: 2,
                        }}
                        error={Boolean(
                          formik.touched.peps_conyuge?.[index]?.cargo_conyuge && getFieldError("cargo_conyuge", index),
                        )}
                      >
                        <InputLabel id={`cargo_conyuge-${index}`}>Cargo público</InputLabel>
                        <Select
                          labelId={`cargo_conyuge-${index}`}
                          id={`cargo_conyuge-${index}`}
                          name={`peps_conyuge[${index}].cargo_conyuge`}
                          fullWidth
                          required
                          label="Cargo público"
                          value={pep.cargo_conyuge}
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.cargo_conyuge &&
                              getFieldError("cargo_conyuge", index),
                          )}
                        >
                          <MenuItem value={""}>Selecciona</MenuItem>
                          {listCargo.map((item) => (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {formik.touched.peps_conyuge?.[index]?.cargo_conyuge &&
                          getFieldError("cargo_conyuge", index) && (
                            <FormHelperText>{getFieldError("cargo_conyuge", index)}</FormHelperText>
                          )}
                      </FormControl>

                      <Box textAlign={"left"} my={2}>
                        <CustomRadioGroup
                          label={"¿Actualmente esta en ese cargo?​"}
                          name={`peps_conyuge[${index}].actualmente_conyuge`}
                          value={pep.actualmente_conyuge}
                          onChange={(e: any) => {
                            formik.handleChange(e);

                            if (e.target.value === "true") {
                              formik.setFieldValue(`peps_conyuge[${index}].end_date_conyuge`, "");
                            }
                          }}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            formik.touched.peps_conyuge?.[index]?.actualmente_conyuge &&
                              getFieldError("actualmente_conyuge", index),
                          )}
                          helperText={
                            formik.touched.peps_conyuge?.[index]?.actualmente_conyuge &&
                            getFieldError("actualmente_conyuge", index)
                          }
                          options={[
                            {
                              label: "Si",
                              value: "true",
                            },
                            {
                              label: "No",
                              value: "false",
                            },
                          ]}
                        />
                      </Box>

                      <FormControl fullWidth sx={{ mt: 2 }}>
                        <CustomDatePicker
                          label="Fecha de inicio"
                          name={`peps_conyuge[${index}].start_date_conyuge`}
                          value={pep.start_date_conyuge ? pep.start_date_conyuge : ""}
                          onChange={formik.handleChange}
                          maxDate={dayjs()}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched.peps_conyuge?.[index]?.start_date_conyuge &&
                            getFieldError("start_date_conyuge", index)
                          }
                          helperText={
                            formik.touched.peps_conyuge?.[index]?.start_date_conyuge
                              ? getFieldError("start_date_conyuge", index)
                              : ""
                          }
                          disabled={isLoading}
                        />
                      </FormControl>

                      {formik.values.peps_conyuge[index].actualmente_conyuge === "false" && (
                        <FormControl fullWidth sx={{ mt: 2 }}>
                          <CustomDatePicker
                            label="Fecha de termino"
                            name={`peps_conyuge[${index}].end_date_conyuge`}
                            value={pep.end_date_conyuge ? pep.end_date_conyuge : ""}
                            onChange={formik.handleChange}
                            minDate={pep.start_date_conyuge ? dayjs(pep.start_date_conyuge) : undefined}
                            maxDate={dayjs()}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.peps_conyuge?.[index]?.end_date_conyuge &&
                              getFieldError("end_date_conyuge", index)
                            }
                            helperText={
                              formik.touched.peps_conyuge?.[index]?.end_date_conyuge
                                ? getFieldError("end_date_conyuge", index)
                                : ""
                            }
                            disabled={isLoading}
                          />
                        </FormControl>
                      )}

                      <Typography
                        onClick={() => handleRemoveConyuge(index)}
                        color={"#F2704F"}
                        fontSize={"15px"}
                        fontWeight={400}
                        mt={3}
                        sx={{ cursor: "pointer", textDecoration: "" }}
                      >
                        - Remover persona
                      </Typography>
                      <hr />
                    </Box>
                  ))}

                  <Typography
                    onClick={handleAddConyuge}
                    color={theme?.palette?.primary?.main}
                    fontSize={"15px"}
                    fontWeight={400}
                    mt={3}
                    sx={{ cursor: formik.values.peps_conyuge.length > 5 ? "not-allowed" : "pointer" }}
                  >
                    + Agregar persona
                  </Typography>
                </>
              )}

              <Grid container columnSpacing={1} my={4}>
                <Grid item xs={6}>
                  <Button
                    variant="blue_outlined"
                    type="button"
                    fullWidth
                    onClick={() => {
                      navigate("/producto/creditosimple/propietario_real_pm");
                    }}
                    disabled={formik.isSubmitting || isLoading}
                  >
                    Regresar al inicio
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="default"
                    disabled={!formik.isValid || formik.isSubmitting || isLoading}
                  >
                    Continuar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_10" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DatosPropietarioRealPart2;
