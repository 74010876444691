import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { Outlet } from "react-router-dom";
import NotFound from "@pages/NotFound";

interface ProtectRoutesByStatusListProps {
  allowedStatus: string[];
}

const ProtectRoutesByStatusList = ({ allowedStatus }: ProtectRoutesByStatusListProps) => {
  const loan_status = useSelector((state: RootState) => state.register.loan_status);
  const previus_status = useRef<string>("");

  useEffect(() => {
    setTimeout(() => {
      previus_status.current = loan_status;
    }, 2000);
  }, [loan_status]);

  if (
    allowedStatus.includes(loan_status) ||
    allowedStatus.includes(previus_status.current) ||
    allowedStatus[0] === "*"
  ) {
    return <Outlet />;
  }
  return <NotFound />;
};

export default ProtectRoutesByStatusList;
