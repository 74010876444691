/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Container,
  TextField,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  Button,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { signIn } from "aws-amplify/auth";
import { RootState, useAppDispatch } from "@store/store";
import {
  set_Id_applicationCondition,
  setAccountUserId,
  setClientDetailsId,
  setId_application,
  setLoanId,
  setPersonalData,
} from "@store/slices/registerSlice";
import { useRegisterUserMutation, useRequestPhoneCodeMutation } from "@api/onboarding";
import { useLazyGetSessionInfoQuery } from "@api/session";
import { useLazyGetPersonTypeQuery } from "@api/catalogs";
import { ProgressBar } from "@components/ProgressBar";
import { TerminosCondiciones } from "@components/TerminosCondiciones";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { transformTitleCase } from "@helpers/funciones";
import { CatalogPersonType, PayloadRegisterUser, PayloadRequestPhoneCode } from "@interfaces/index";
import { NAME, LAST_NAMES, PHONE_NUMBER } from "@utils/expreciones";
import "react-phone-input-2/lib/style.css";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { usePatchCuentaMutation } from "@api/account";
import { setLoanStatus } from "@store/slices/registerSlice";
import { HOSTNAMES, STATUS_CATALOG } from "@helpers/constantes";
import { UUID } from "crypto";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import * as Sentry from "@sentry/react";

const RegistroDatosPersonales = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const application = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);

  const dispatch = useAppDispatch();
  const [registerUser] = useRegisterUserMutation();
  const [requestPhoneCode] = useRequestPhoneCodeMutation();
  const [getPersonTypesTrigger] = useLazyGetPersonTypeQuery();
  const [getSessionInfo] = useLazyGetSessionInfoQuery();
  const [patchAccount] = usePatchCuentaMutation();

  const [isLoadingInitial, setIsloadingInitial] = useState(false);
  const [personTypeCatalog, setPersonTypeCatalog] = useState<CatalogPersonType[]>([]);
  const [otherPersonTypeId, setOtherPersonTypeId] = useState<string | null>(null);
  const [productId, setProductId] = useState("");

  const formik = useFormik({
    initialValues: {
      name: clientDetails.name || "",
      first_lastname: clientDetails.first_lastname || "",
      second_lastname: clientDetails.second_lastname || "",
      country_code: clientDetails.country_code || "+52",
      phone: clientDetails.phone || "",
      person_type_id: clientDetails.person_type_id || "",
      rol_detail: clientDetails.rol_detail || "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Campo requerido").matches(new RegExp(NAME), "Nombre inválido, revisa el formato"),
      first_lastname: yup
        .string()
        .required("Campo requerido")
        .matches(new RegExp(LAST_NAMES), "Apellido paterno inválido, revisa el formato"),
      second_lastname: yup.string().matches(new RegExp(LAST_NAMES), "Apellido materno inválido, revisa el formato"),
      country_code: yup.string().required("Campo requerido"),
      phone: yup.string().required("Campo requerido").matches(PHONE_NUMBER, "Número de teléfono inválido"),
      person_type_id: yup.string().required("Campo requerido"),
      rol_detail: yup.string().when("person_type_id", {
        is: otherPersonTypeId,
        then: (schema) => schema.required("Campo requerido"),
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true);

        if (clientDetails && clientDetails.id !== "00000000-0000-0000-0000-000000000000") {
          await patchAccount({
            accountId: clientDetails.id,
            body: {
              name: values.name,
              last_name: values.first_lastname,
              last_name_2: values.second_lastname,
              country_code: values.country_code,
              cell_phone_number: values.phone,
              person_type_id: values.person_type_id,
            },
          }).unwrap();

          dispatch(
            setPersonalData({
              id: clientDetails.id,
              name: values.name,
              first_lastname: values.first_lastname,
              second_lastname: values.second_lastname,
              country_code: values.country_code,
              phone: values.phone,
              person_type_id: values.person_type_id,
              rol_detail: values.rol_detail,
              cell_phone_verified: clientDetails.cell_phone_verified,
            }),
          );
        } else {
          const payloadRegisterUser: PayloadRegisterUser = {
            username: accountUser.email,
            email: accountUser.email,
            password: accountUser.password,
            phone: `${values.country_code}${values.phone}`,
            loan_application: {
              product_id: productId as UUID,
              tax_systems_id: String(application.tax_system_id),
              source_code: application.source_code,
            },
            application_condition: {
              loan_amount: application.loan_amount,
              requested_term: application.loan_term,
            },
            client_details: {
              name: values.name,
              last_name: values.first_lastname,
              last_name_2: values.second_lastname,
              country_code: values.country_code,
              cell_phone_number: values.phone,
              person_type_id: values.person_type_id,
              tax_systems_id: String(application.tax_system_id),
            },
          };
          const responseRegisterUser = await registerUser(payloadRegisterUser).unwrap();

          await signIn({
            username: accountUser.email,
            password: accountUser.password,
          });

          await getSessionInfo(responseRegisterUser.data.sso_id).unwrap();

          dispatch(setAccountUserId(responseRegisterUser.data.id));
          dispatch(setLoanId(responseRegisterUser.data.loan_application.id));
          dispatch(setClientDetailsId(responseRegisterUser.data.client_details.id));
          dispatch(setId_application(Number(responseRegisterUser.data.loan_application.id_application)));
          dispatch(set_Id_applicationCondition(String(responseRegisterUser.data.application_condition.id)));
          dispatch(
            setPersonalData({
              id: responseRegisterUser.data.client_details.id,
              name: values.name,
              first_lastname: values.first_lastname,
              second_lastname: values.second_lastname,
              country_code: values.country_code,
              phone: values.phone,
              person_type_id: values.person_type_id,
              rol_detail: values.rol_detail,
              cell_phone_verified: false,
            }),
          );
        }

        const next_status = STATUS_CATALOG.validacion_telefono.code;
        const next_url = STATUS_CATALOG.validacion_telefono.url;

        if (!clientDetails.cell_phone_verified) {
          const payloadRequestPhoneCode: PayloadRequestPhoneCode = {
            country_code: values.country_code,
            phone_number: values.phone,
          };
          if (clientDetails.phone === "") {
            await requestPhoneCode(payloadRequestPhoneCode).unwrap();
          } else if (clientDetails.phone !== values.phone) {
            await requestPhoneCode(payloadRequestPhoneCode).unwrap();
          }
          dispatch(setLoanStatus(next_status));
        }

        navigate(next_url);
      } catch (error) {
        // TODO: validar el error de usuario registrado, viene con status 400 y code 001
        const api_error = error as ApiError;

        if (api_error.status && api_error.data) {
          if (api_error.data.code === "002") {
            formik.setFieldError("phone", "El teléfono ya se encuentra registrado, intenta con otro número");
            return;
          }

          if (api_error.data.code === "001") {
            formik.setFieldError("name", "El correo ya se encuentra registrado, intenta con otro correo");
            return;
          }

          if (api_error?.status === 429) {
            formik.setFieldError(
              "phone",
              "Excedió el número de intentos permitidos para ese número, intenta más tarde o contacta a un asesor.",
            );
            return;
          }
        }

        Sentry.captureException(error, {
          extra: {
            component: "RegistroDatosPersonales",
            values: formik.values,
          },
        });
        dispatch(setModalErrorVisible({ open: true, error: api_error }));
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        setIsloadingInitial(true);
        if (!application.tax_system_type || !accountUser.email) {
          return;
        }

        const result = await getPersonTypesTrigger().unwrap();

        const filteredData = result
          .filter(
            (item) =>
              (item.tax_system_type === application.tax_system_type || item.tax_systems_id === null) &&
              item.use_for_rol,
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        setPersonTypeCatalog(filteredData);

        const otherItem = filteredData.find((item) => item.name.toLowerCase() === "otro");
        if (otherItem) {
          setOtherPersonTypeId(otherItem.id);
        }
      } catch (error) {
        console.error("Ha ocurrido un error al consultar el catalogo", error);
      } finally {
        setIsloadingInitial(false);
      }
    };

    fetchInitialData();
  }, [application, accountUser]);

  useEffect(() => {
    const hostname = window.location.hostname;
    const foundHost = HOSTNAMES.find(({ host }) => host === hostname);
    if (foundHost?.value === "lendia") {
      setProductId(String(import.meta.env.VITE_PRODUCT_SIMPLE_CREDIT_ID));
    }
    if (foundHost?.value === "finbe") {
      setProductId(String(import.meta.env.VITE_PRODUCT_SIMPLE_CREDIT_ID_FINBE));
    }
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8} mt={4}>
          {clientDetails && clientDetails.id !== "00000000-0000-0000-0000-000000000000" && <InactivityHandler />}

          <Box
            sx={{
              mt: isMobileOrTablet ? 2 : 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant={isMobileOrTablet ? "h4" : "h3"}
              fontWeight={500}
              textAlign="center"
              color={theme?.palette?.primary?.dark}
            >
              Creación de cuenta
            </Typography>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
              <ProgressBar progress={1} />
            </Container>

            <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3} textAlign={"center"}>
              <Typography variant={isMobileOrTablet ? "body2" : "body1"} color={theme?.palette?.primary?.dark}>
                Ingresa los datos para crear tu cuenta
              </Typography>
            </Box>

            <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 4 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <FormControl fullWidth>
                  <TextField
                    id="name"
                    name="name"
                    label="Nombre(s)"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("name", transformTitleCase(e.target.value))}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name ? formik.errors.name : ""}
                    inputProps={{ maxLength: 100 }}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="first_lastname"
                    name="first_lastname"
                    label="Apellido paterno"
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("first_lastname", transformTitleCase(e.target.value))}
                    value={formik.values.first_lastname}
                    error={formik.touched.first_lastname && Boolean(formik.errors.first_lastname)}
                    helperText={formik.touched.first_lastname ? formik.errors.first_lastname : ""}
                    inputProps={{ maxLength: 100 }}
                    disabled={formik.isSubmitting}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    id="second_lastname"
                    name="second_lastname"
                    label="Apellido materno"
                    margin="normal"
                    fullWidth
                    autoComplete="off"
                    onBlur={formik.handleBlur}
                    onChange={(e) => formik.setFieldValue("second_lastname", transformTitleCase(e.target.value))}
                    inputProps={{ maxLength: 100 }}
                    value={formik.values.second_lastname}
                    disabled={formik.isSubmitting}
                    error={formik.touched.second_lastname && Boolean(formik.errors.second_lastname)}
                    helperText={formik.touched.second_lastname ? formik.errors.second_lastname : ""}
                  />
                </FormControl>

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="person_type_id-label" required>
                    Rol en la empresa
                  </InputLabel>
                  <Select
                    labelId="person_type_id-label"
                    id="person_type_id"
                    name="person_type_id"
                    fullWidth
                    required
                    label="Rol en la empresa*"
                    value={formik.values.person_type_id}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.person_type_id && Boolean(formik.errors.person_type_id)}
                    disabled={formik.isSubmitting}
                  >
                    <MenuItem value={""}>Selecciona</MenuItem>
                    {personTypeCatalog.length > 0 ? (
                      personTypeCatalog.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value={""} disabled>
                        No hay opciones disponibles
                      </MenuItem>
                    )}
                  </Select>
                  {Boolean(formik.touched.person_type_id) && Boolean(formik.errors.person_type_id) && (
                    <FormHelperText>{formik.errors.person_type_id}</FormHelperText>
                  )}
                </FormControl>

                {formik.values.person_type_id === otherPersonTypeId && (
                  <FormControl fullWidth>
                    <TextField
                      id="rol_detail"
                      name="rol_detail"
                      label="Especifica otro"
                      margin="normal"
                      fullWidth
                      required={formik.values.person_type_id === otherPersonTypeId}
                      autoComplete="off"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.rol_detail}
                      disabled={formik.isSubmitting}
                      inputProps={{ maxLength: 150 }}
                    />
                  </FormControl>
                )}

                <Grid container spacing={1} mt={2}>
                  <Grid item xs={5} sm={5} md={5}>
                    <CustomContryCode
                      label="Código"
                      name="country_code"
                      value={formik.values.country_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                      disabled={formik.isSubmitting || clientDetails.cell_phone_verified}
                      required={true}
                    />
                  </Grid>

                  <Grid item xs={7} sm={7} md={7}>
                    <TextField
                      type="text"
                      name="phone"
                      variant="outlined"
                      label="Teléfono"
                      fullWidth
                      value={formik.values.phone}
                      required
                      onChange={(event) => {
                        formik.setFieldValue("phone", event.target.value.replace(/[^0-9]/g, ""));
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.touched.phone && Boolean(formik.errors.phone)}
                      helperText={formik.touched.phone && formik.errors.phone}
                      inputProps={{
                        maxLength: 10,
                        inputMode: "numeric",
                      }}
                      disabled={formik.isSubmitting || clientDetails.cell_phone_verified}
                    />
                  </Grid>
                </Grid>

                <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                  <Button
                    variant="default"
                    type="submit"
                    color="primary"
                    disabled={!formik.isValid || formik.isSubmitting || isLoadingInitial}
                  >
                    Continuar
                  </Button>
                </Box>
              </form>

              <Box sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
                <TerminosCondiciones />
              </Box>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
              <CustomRightImage imageName="img_tofu_3" />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default RegistroDatosPersonales;
