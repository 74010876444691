import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import icon_navbar_back from "@assets/img/icon_navbar_back.svg";
import { useSelector } from "react-redux";
import { RootState } from "@store/store";
import { STATUS_CATALOG, TAX_SYSTEM_TYPE } from "@helpers/constantes";

export const CustomReturnArrow = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const loanApplication = useSelector((state: RootState) => state.register.application);
  const routes_group = useSelector((state: RootState) => state.register.routes_group);

  const onBack = () => {
    switch (location.pathname) {
      case STATUS_CATALOG.validacion_telefono.url:
        navigate(STATUS_CATALOG.registro_datos_personales.url);
        break;
      //pm
      case STATUS_CATALOG.datos_empresa_pm.url:
        navigate(STATUS_CATALOG.validacion_telefono.url);
        break;
      case STATUS_CATALOG.consulta_ciec.url:
        {
          loanApplication.tax_system_type === TAX_SYSTEM_TYPE.PM.code
            ? navigate(STATUS_CATALOG.datos_empresa_pm.url)
            : navigate(STATUS_CATALOG.domicilio_pfae.url);
        }
        break;
      case STATUS_CATALOG.uso_de_credito.url:
        navigate(STATUS_CATALOG.consulta_ciec.url);
        break;

      case STATUS_CATALOG.presencia_digital.url:
        navigate(STATUS_CATALOG.uso_de_credito.url);
        break;

      case STATUS_CATALOG.datos_accionista.url:
        navigate(STATUS_CATALOG.presencia_digital.url);
        break;

      case STATUS_CATALOG.rfc_curp.url:
        navigate(STATUS_CATALOG.datos_accionista.url);
        break;

      case STATUS_CATALOG.domicilio_accionista.url:
        navigate(STATUS_CATALOG.rfc_curp.url);
        break;

      case STATUS_CATALOG.consulta_buro.url:
        navigate(STATUS_CATALOG.domicilio_accionista.url);
        break;

      //pfae

      case STATUS_CATALOG.datos_empresa_pfae.url:
        navigate(STATUS_CATALOG.validacion_telefono.url);
        break;

      case STATUS_CATALOG.domicilio_pfae.url:
        navigate(STATUS_CATALOG.datos_empresa_pfae.url);
        break;

      case STATUS_CATALOG.presencia_digital_pfae.url:
        navigate(STATUS_CATALOG.uso_de_credito.url);
        break;

      case STATUS_CATALOG.consulta_buro_pfae.url:
        navigate(STATUS_CATALOG.presencia_digital_pfae.url);
        break;

      default:
        break;
    }
  };
  return (
    <>
      {location.pathname !== STATUS_CATALOG.registro_datos_personales.url && routes_group === "TOFU" && (
        <>
          <Box
            component="img"
            src={icon_navbar_back}
            alt="logo"
            sx={{ height: 25, cursor: "pointer" }}
            mr={2}
            onClick={onBack}
          />
        </>
      )}
    </>
  );
};
