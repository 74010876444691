/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { Grid, Box, Container, useTheme, useMediaQuery, TextField, Button, Typography, SxProps } from "@mui/material";
import { formatTime, splitPhoneNumber } from "@helpers/funciones";
import "@styles/stylesRegistro.css";
import { fetchAuthSession, signOut, getCurrentUser } from "aws-amplify/auth";
import {
  useRequestPhoneCodeLoginMutation,
  useValidatePhoneCodeLoginMutation,
  useLazyGetSessionInfoQuery,
} from "@api/session";
import ModalResultOtpLogin from "@components/ModalResultOtpLogin";
import CustomRightImage from "@components/CustomRightImage";

const TIME_LIMIT = 120;

const CustomTextField: SxProps = {
  "& input": {
    textAlign: "center",
    fontSize: "25px",
    fontWeight: 400,
    width: "40px",
    height: "60px",
  },
  margin: "8px",
  backgroundColor: "#E3F2F8",
  "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
    WebkitAppearance: "none",
  },
  "& input[disabled]": {
    cursor: "not-allowed",
  },
};

const validationSchema = yup.object().shape({
  code_1: yup.string().required("Campo requerido"),
  code_2: yup.string().required("Campo requerido"),
  code_3: yup.string().required("Campo requerido"),
  code_4: yup.string().required("Campo requerido"),
});

interface FormInitialValues {
  code_1: string;
  code_2: string;
  code_3: string;
  code_4: string;
}

interface OtpErrorMessage {
  title: string;
  message: string;
}

export const ValidateCodeLogin = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const [triggerGetSessionInfo, sessionInfoResult] = useLazyGetSessionInfoQuery();
  const [secondsRemaining, setSecondsRemaining] = useState(TIME_LIMIT);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("");
  const [requestPhoneCode] = useRequestPhoneCodeLoginMutation();
  const [validatePhoneCode] = useValidatePhoneCodeLoginMutation();
  const inputRef_1 = useRef<HTMLInputElement>(null);
  const inputRef_2 = useRef<HTMLInputElement>(null);
  const inputRef_3 = useRef<HTMLInputElement>(null);
  const inputRef_4 = useRef<HTMLInputElement>(null);
  const [otpSuccess, setOtpSuccess] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<OtpErrorMessage | null>(null);

  const initialValues: FormInitialValues = {
    code_1: "",
    code_2: "",
    code_3: "",
    code_4: "",
  };

  const handleSubmit = async (values: FormInitialValues) => {
    try {
      setOtpSuccess(false);
      setOtpError(null);

      await validatePhoneCode({
        country_code: countryCode,
        phone_number: phoneNumber,
        code: `${values.code_1}${values.code_2}${values.code_3}${values.code_4}`,
      }).unwrap();
      setOtpSuccess(true);
    } catch (error: any) {
      if (error && error.data && error.data.message) {
        setOtpError({
          title: error.status === 429 ? "Agotaste el límite de intentos" : "Error al validar",
          message: error?.data?.message,
        });
        setOtpError(error?.data?.message);
      } else {
        setOtpError({
          title: "Error en el servidor",
          message: "Por favor, intenta más tarde",
        });
      }
    }
  };

  const handleResendCode = async () => {
    try {
      if (secondsRemaining > 0) {
        return;
      }

      setIsLoading(true);

      await requestPhoneCode({
        country_code: countryCode,
        phone_number: phoneNumber,
      }).unwrap();

      setSecondsRemaining(TIME_LIMIT);
    } catch (error: any) {
      if (error && error.data && error.data.message) {
        setOtpError({
          title: "Error al enviar código",
          message: error?.data?.message,
        });
      } else {
        setOtpError({
          title: "Error en el servidor",
          message: "Por favor, intenta más tarde",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const pasteData = e.clipboardData.getData("text").trim().slice(0, 4);

    if (pasteData.length === 4) {
      formik.setFieldValue("code_1", pasteData[0]);
      formik.setFieldValue("code_2", pasteData[1]);
      formik.setFieldValue("code_3", pasteData[2]);
      formik.setFieldValue("code_4", pasteData[3]);

      inputRef_4.current?.focus();
    }
  };

  const formatPhoneNumber = (phoneString: string) => {
    if (phoneString.length < 4) {
      return "Número de teléfono inválido";
    }
    const hiddenPart = phoneString.slice(0, -4).replace(/./g, "*");
    const visiblePart = phoneString.slice(-4);

    return hiddenPart + visiblePart;
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (sessionInfoResult.isSuccess) {
      if (sessionInfoResult.data.data.last_screen.sequence == 12) {
        navigate(sessionInfoResult.data.data.last_screen.url);
      } else if (sessionInfoResult.data.data.last_screen.sequence == 11) {
        navigate(sessionInfoResult.data.data.last_screen.url);
      } else {
        navigate(
          sessionInfoResult.data.data.next_screen.url
            ? sessionInfoResult.data.data.next_screen.url
            : sessionInfoResult.data.data.last_screen.url,
        );
      }
    }
  }, [sessionInfoResult]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        clearInterval(intervalId);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [secondsRemaining]);

  useEffect(() => {
    const fetchUserSession = async () => {
      const session = await fetchAuthSession();
      const payload = session?.tokens?.accessToken?.payload || {};
      const userRole = String(payload["custom:role"] || "");
      const userPhone = String(payload["phone_number"] || "");

      if (userRole === "client") {
        const { phone_number, country_code } = splitPhoneNumber(userPhone);
        setPhoneNumber(phone_number || "");
        setCountryCode(country_code || "");
      } else {
        await signOut();
        navigate("/login");
      }
    };

    fetchUserSession();
  }, []);

  return (
    <>
      <Grid container height={"100%"}>
        <Grid item xs={12} md={6} mt={2}>
          <Box
            sx={{
              mt: isMobileOrTablet ? 3 : 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography fontSize={"20px"} fontWeight={600} textAlign="center" color={"#528CD6"} mt={4} mb={4}>
              <span>
                Ingresa el código que enviamos vía <br /> sms y whatsapp al número
              </span>
            </Typography>

            <Typography fontSize={"20px"} fontWeight={500} textAlign="center" color={"#002652"}>
              {formatPhoneNumber(phoneNumber)}
            </Typography>

            <Container maxWidth="sm" sx={{ mt: 2, mb: 1 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                <Box display="flex" flexDirection="row" justifyContent="center">
                  <TextField
                    type="text"
                    name="code_1"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d]/g, "");
                      formik.setFieldValue("code_1", value);
                      value && inputRef_2.current?.focus();
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.code_1 && Boolean(formik.errors.code_1)}
                    sx={CustomTextField}
                    inputProps={{
                      maxLength: 1,
                      inputMode: "numeric",
                    }}
                    inputRef={inputRef_1}
                    disabled={isLoading}
                    onPaste={handlePaste}
                    value={formik.values.code_1}
                  />
                  <TextField
                    type="text"
                    name="code_2"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    onKeyUp={(e) => {
                      if (e.code == "Backspace") {
                        inputRef_1.current?.focus();
                      }
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d]/g, "");
                      formik.setFieldValue("code_2", value);
                      value && inputRef_3.current?.focus();
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.code_2 && Boolean(formik.errors.code_2)}
                    sx={CustomTextField}
                    inputProps={{
                      maxLength: 1,
                      inputMode: "numeric",
                    }}
                    inputRef={inputRef_2}
                    disabled={isLoading}
                    value={formik.values.code_2}
                  />
                  <TextField
                    type="text"
                    name="code_3"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    onKeyUp={(e) => {
                      if (e.code == "Backspace") {
                        inputRef_2.current?.focus();
                      }
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d]/g, "");
                      formik.setFieldValue("code_3", value);
                      value && inputRef_4.current?.focus();
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.code_3 && Boolean(formik.errors.code_3)}
                    sx={CustomTextField}
                    inputProps={{
                      maxLength: 1,
                      inputMode: "numeric",
                    }}
                    inputRef={inputRef_3}
                    disabled={isLoading}
                    value={formik.values.code_3}
                  />
                  <TextField
                    type="text"
                    name="code_4"
                    variant="outlined"
                    margin="dense"
                    size="small"
                    autoComplete="off"
                    onKeyUp={(e) => {
                      if (e.code == "Backspace") {
                        inputRef_3.current?.focus();
                      }
                    }}
                    onChange={(e) => {
                      let value = e.target.value;
                      value = value.replace(/[^\d]/g, "");
                      formik.setFieldValue("code_4", value);
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.code_4 && Boolean(formik.errors.code_4)}
                    sx={CustomTextField}
                    inputProps={{
                      maxLength: 1,
                      inputMode: "numeric",
                    }}
                    inputRef={inputRef_4}
                    disabled={isLoading}
                    value={formik.values.code_4}
                  />
                </Box>

                <Typography
                  fontSize={"16px"}
                  mt={isMobileOrTablet ? 2 : 7}
                  textAlign="center"
                  fontWeight={600}
                  color={secondsRemaining > 60 ? "#528CD6" : "#CD0202"}
                >
                  {formatTime(secondsRemaining)} min
                </Typography>

                <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 1 : 6}>
                  <Typography
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    component={"a"}
                    onClick={handleResendCode}
                    sx={{
                      textDecoration: "underline",
                      cursor: secondsRemaining > 0 || isLoading ? "not-allowed" : "pointer",
                      fontWeight: secondsRemaining > 0 || isLoading ? 300 : 500,
                      color: secondsRemaining > 0 || isLoading ? "#002652" : "#528CD6",
                    }}
                  >
                    Reenviar código
                  </Typography>
                </Box>

                <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 4 : 8}>
                  <Button variant="default" type="submit" color="primary" disabled={isLoading}>
                    Continuar
                  </Button>
                </Box>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={6} bgcolor={"#A3D4E8"} textAlign={"center"}>
            <Box sx={{ position: "fixed", width: "100%", height: "100%", backgroundColor: "#A3D4E8" }}>
              <CustomRightImage imageName="img_bofu_18" />
            </Box>
          </Grid>
        )}
      </Grid>

      {otpSuccess && (
        <ModalResultOtpLogin
          isError={false}
          title="¡Listo!"
          message="Código validado exitosamente"
          handleClose={async () => {
            const currentUser = await getCurrentUser();
            await triggerGetSessionInfo(currentUser.userId).unwrap();
            setOtpSuccess(false);
            navigate("/home");
          }}
        />
      )}

      {otpError && (
        <ModalResultOtpLogin
          isError={true}
          title={otpError.title}
          message={otpError.message}
          handleClose={() => {
            setOtpError(null);
          }}
        />
      )}
    </>
  );
};
