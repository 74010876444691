/* eslint-disable react-hooks/exhaustive-deps */
import {
  Grid,
  Box,
  Container,
  TextField,
  useTheme,
  useMediaQuery,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RFC_PFAE } from "@utils/expreciones";
import { useAppDispatch, type RootState } from "@store/store";
import { ProgressBar } from "@components/ProgressBar";
import { setCompanyData, setLoanStatus } from "@store/slices/registerSlice";
import { useLazyAddCompanyDataQuery } from "@api/account";
import { usePostValidateNextStageMutation } from "@api/applications";
import { transformTitleCase } from "@helpers/funciones";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { STATUS_CATALOG } from "@helpers/constantes";
import { useEffect } from "react";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";

const DatosEmpresaPfae = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [triggerAddCompanyData] = useLazyAddCompanyDataQuery();

  const companyData = useSelector((state: RootState) => state.register.company);
  const application = useSelector((state: RootState) => state.register.application);
  const personalData = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const formik = useFormik({
    initialValues: {
      rfc: "",
      company_name: "",
      name: "",
      lastName1: "",
      lastName2: "",
      isPFAE: false,
    },
    validationSchema: yup.object().shape({
      rfc: yup.string().trim().required("Campo requerido").matches(RFC_PFAE, "Formato incorrecto, inténtalo de nuevo"),
      company_name: yup.string().trim().required("Campo requerido"),
      name: yup.string().trim().required("El nombre es obligatorio"),
      lastName1: yup.string().trim().required("El apellido paterno es obligatorio"),
      lastName2: yup.string().trim().nullable(),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (formik.isValid) {
        try {
          setSubmitting(true);

          const rfc = values.rfc.trim();
          const company_name = values.company_name.trim();
          const legal_name = `${values.name} ${values.lastName1} ${values.lastName2 || ""}`.trim();
          const name = values.name.trim();
          const lastName1 = values.lastName1.trim();
          const lastName2 = values.lastName2?.trim();
          const isPFAE = values.isPFAE;

          await triggerAddCompanyData({
            id: personalData.id,
            body: {
              rfc: rfc,
              name: company_name,
              legal_name: legal_name,
              is_pfae: isPFAE,
              pfae_name: name,
              pfae_last_name: lastName1,
              pfae_last_name_2: lastName2,
            },
          }).unwrap();

          const next_status = STATUS_CATALOG.domicilio_pfae.code;
          const next_url = STATUS_CATALOG.domicilio_pfae.url;

          await triggerPostNextStage({
            applicationId: application.id,
            statusCode: next_status,
            username: `TOFU - ${accountUser.email}`,
          }).unwrap();

          dispatch(
            setCompanyData({
              id: personalData.id,
              rfc: rfc,
              name: company_name,
              legal_name: legal_name,
              role: "",
              ciec_pass: "",
              authorize_sat: false,
              is_pfae: isPFAE,
              pfae_name: name,
              pfae_last_name: lastName1,
              pfae_last_name_2: lastName2,
              is_correct_ciec: false, // TODO: validar si debe ir true o false
            }),
          );

          dispatch(setLoanStatus(next_status));

          navigate(next_url);
        } catch (error) {
          const api_error = error as ApiError;
          if (api_error?.data?.errors) {
            formik.setFieldError("rfc", `Existe una cuenta con RFC - ${values.rfc}`);
            return;
          }

          dispatch(setModalErrorVisible({ open: true, error: api_error }));
        } finally {
          setSubmitting(false);
        }
      }
    },
    validateOnMount: true,
  });

  const handleUpperChange = (values: string) => {
    formik.setFieldValue("rfc", values.toUpperCase());
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      formik.setValues(
        {
          ...formik.values,
          name: personalData.name || "",
          lastName1: personalData.first_lastname || "",
          lastName2: personalData.second_lastname || "",
          isPFAE: true,
        },
        true,
      );
    } else {
      formik.setValues(
        {
          ...formik.values,
          name: "",
          lastName1: "",
          lastName2: "",
          isPFAE: false,
        },
        true,
      );
    }
  };

  useEffect(() => {
    if (companyData) {
      formik.setFieldValue("rfc", companyData.rfc || "");
      formik.setFieldValue("company_name", companyData.name || "");
      formik.setFieldValue("name", companyData.pfae_name || "");
      formik.setFieldValue("lastName1", companyData.pfae_last_name || "");
      formik.setFieldValue("lastName2", companyData.pfae_last_name_2 || "");
      formik.setFieldValue("isPFAE", companyData.is_pfae || false);
    }
  }, [companyData]);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box
          sx={{
            mt: isMobileOrTablet ? 2 : 4,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isMobileOrTablet ? "h4" : "h3"}
            fontWeight={500}
            textAlign="center"
            color={theme?.palette?.primary?.dark}
          >
            Registro de empresa
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <ProgressBar progress={3} />
          </Container>

          <Box maxWidth={"sm"} mt={isMobileOrTablet ? 2 : 3}>
            <Typography
              variant={isMobileOrTablet ? "body2" : "body1"}
              textAlign={"center"}
              color={theme?.palette?.primary?.dark}
            >
              Ingresa los datos de la PFAE
            </Typography>
          </Box>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              <TextField
                id="rfc"
                name="rfc"
                label="RFC de la PFAE"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => handleUpperChange(e.target.value)}
                value={formik.values.rfc}
                error={formik.touched.rfc && Boolean(formik.errors.rfc)}
                helperText={formik.touched.rfc ? formik.errors.rfc : ""}
                inputProps={{ maxLength: 13 }}
                disabled={formik.isSubmitting}
              />

              <TextField
                id="company_name"
                name="company_name"
                label="Nombre comercial"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => formik.setFieldValue("company_name", transformTitleCase(e.target.value))}
                value={formik.values.company_name}
                error={formik.touched.company_name && Boolean(formik.errors.company_name)}
                helperText={formik.touched.company_name ? formik.errors.company_name : ""}
                inputProps={{ maxLength: 40 }}
                disabled={formik.isSubmitting}
              />

              <Grid item sx={{ textAlign: "left", mb: 1, mt: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox id="one" name="isPFAE" onChange={handleCheckboxChange} checked={formik.values.isPFAE} />
                  }
                  label="Soy la Persona Física con act. empresarial"
                  disabled={formik.isSubmitting}
                  sx={{ color: theme?.palette?.primary?.dark }}
                />
              </Grid>

              <TextField
                id="name"
                name="name"
                label="Nombre"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => formik.setFieldValue("name", transformTitleCase(e.target.value))}
                value={formik.values.name}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name ? formik.errors.name : ""}
                inputProps={{ maxLength: 40 }}
                disabled={formik.values.isPFAE || formik.isSubmitting}
              />

              <TextField
                id="lastName1"
                name="lastName1"
                label="Apellido Paterno"
                margin="normal"
                required
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => formik.setFieldValue("lastName1", transformTitleCase(e.target.value))}
                value={formik.values.lastName1}
                error={formik.touched.lastName1 && Boolean(formik.errors.lastName1)}
                helperText={formik.touched.lastName1 ? formik.errors.lastName1 : ""}
                inputProps={{ maxLength: 40 }}
                disabled={formik.values.isPFAE || formik.isSubmitting}
              />

              <TextField
                id="lastName2"
                name="lastName2"
                label="Apellido Materno"
                margin="normal"
                fullWidth
                autoComplete="off"
                onBlur={formik.handleBlur}
                onChange={(e) => formik.setFieldValue("lastName2", transformTitleCase(e.target.value))}
                value={formik.values.lastName2}
                error={formik.touched.lastName2 && Boolean(formik.errors.lastName2)}
                helperText={formik.touched.lastName2 ? formik.errors.lastName2 : ""}
                inputProps={{ maxLength: 40 }}
                disabled={formik.values.isPFAE || formik.isSubmitting}
              />

              <Box display="flex" justifyContent="center" mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button type="submit" variant="default" disabled={!formik.isValid || formik.isSubmitting}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_tofu_5" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DatosEmpresaPfae;
