/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { EMAIL, PHONE_NUMBER } from "@utils/expreciones";
import { RootState } from "@store/store";
import CustomContryCode from "@components/CustomContryCode/CustomContryCode";
import { useGetClientsSuppliersByRfcQuery } from "@api/proveedorClienteApi";
import { customStylesAsterisk } from "@components/customStylesInputs/inputsStyles";
import { useLazyGetReferencesQuery, usePatchReferencesMutation, usePostReferencesMutation } from "@api/referenciasApi";
import { useLazyGetReferenceTypesQuery } from "@api/catalogs";
import { usePutApplicationCompletedMutation } from "@api/applications";
import { InactivityHandler } from "@components/InactivityHandler";
import { getOrdinalText } from "@helpers/funciones";
import { REFERENCE_CODES } from "@helpers/constantes";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError, ReferenceType } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import { UUID } from "crypto";

const ReferenciasProveedores = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const applicationData = useSelector((state: RootState) => state.register.application);
  const client = useSelector((state: RootState) => state.register.personal_data);
  const company = useSelector((state: RootState) => state.register.company);

  const [triggerPostReferences] = usePostReferencesMutation();
  const [triggerPatchReferences] = usePatchReferencesMutation();
  const supplierReferences = useGetClientsSuppliersByRfcQuery(String(company?.rfc));
  const [putAapplicationCompleted] = usePutApplicationCompletedMutation();
  const [getReferenceTypes] = useLazyGetReferenceTypesQuery();
  const [getReferences] = useLazyGetReferencesQuery();

  const [isLoading, setIsLoading] = useState(false);
  const [referenceType, setReferenceType] = useState<ReferenceType | null>(null);

  const formik = useFormik({
    initialValues: {
      referencia: [
        {
          id: "",
          country_code: "+52",
          phone: "",
          phone_extension: "",
          proveedor: "",
          contact_name: "",
          email: "",
        },
        {
          id: "",
          country_code: "+52",
          phone: "",
          phone_extension: "",
          proveedor: "",
          contact_name: "",
          email: "",
        },
        {
          id: "",
          country_code: "+52",
          phone: "",
          phone_extension: "",
          proveedor: "",
          contact_name: "",
          email: "",
        },
      ],
    },
    validationSchema: yup.object().shape({
      referencia: yup.array().of(
        yup.object().shape({
          proveedor: yup.string().required("Campo requerido"),
          country_code: yup.string().required("Campo requerido"),
          phone: yup
            .string()
            .required("Campo requerido")
            .matches(PHONE_NUMBER, "Número de teléfono inválido")
            .test("unique", "El teléfono ya se encuentra registrado.", function (_value, params) {
              const reference_index = +params.path.replace("referencia[", "").replace("].phone", "");
              const reference_phone =
                (formik.values.referencia[reference_index].country_code || "") +
                (formik.values.referencia[reference_index].phone || "") +
                (formik.values.referencia[reference_index].phone_extension || "");
              const array_items = formik.values.referencia.map((item) => {
                return (item.country_code || "") + (item.phone || "") + (item.phone_extension || "");
              });

              const num_results: number = array_items.filter((item) => item === reference_phone).length;
              return num_results > 1 ? false : true;
            }),
          contact_name: yup
            .string()
            .required("Campo requerido")
            .test("unique", "El nombre de contacto ya se encuentra registrado.", function (value) {
              const array_items = formik.values.referencia.map((item) =>
                (item.contact_name || "").replaceAll(" ", "").toLowerCase(),
              );
              const name_to_compare = value.replaceAll(" ", "").toLowerCase();
              const num_results: number = array_items.filter((item) => item === name_to_compare).length;
              return num_results > 1 ? false : true;
            }),
          email: yup
            .string()
            .required("Campo requerido")
            .matches(EMAIL, "Asegúrate de incluir el @ y los puntos necesarios (.com .mx)")
            .test("unique", "El email ya se encuentra registrado.", function (value) {
              const array_items = formik.values.referencia.map((item) => item.email || "");
              const num_results: number = array_items.filter((item) => item === value).length;
              return num_results > 1 ? false : true;
            }),
        }),
      ),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!formik.isValid || !referenceType) {
          return;
        }

        setSubmitting(true);

        for (let index = 0; index < values.referencia.length; index++) {
          const reference = values.referencia[index];
          const supplierItem = supplierReferences?.data?.data?.suppliers?.find(
            (supplier) => supplier.rfc === reference.proveedor,
          );

          const referenceData = {
            client_details_id: client.id,
            id_ext: "4444",
            phone_number: reference.phone,
            reference_type_id: referenceType.id,
            code_phone_number: reference.country_code,
            phone_extension: reference.phone_extension,
            supplier_name: supplierItem?.name,
            supplier_rfc: supplierItem?.rfc,
            contact_name: reference.contact_name,
            email: reference.email,
          };

          if (reference.id) {
            await triggerPatchReferences({
              referenceId: reference.id as UUID,
              body: referenceData,
            }).unwrap();
          } else {
            await triggerPostReferences(referenceData).unwrap();
          }
        }

        const data = {
          references_completed: true,
        };

        await putAapplicationCompleted({ id_sol: applicationData.id, body: data }).unwrap();

        navigate("/producto/creditosimple/avance_expediente_digital");
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setSubmitting(false);
      }
    },
  });

  const getFieldError = (fieldName: string, index: number) => {
    const error: any = formik.errors.referencia?.[index];
    if (error && fieldName in error) {
      return error[fieldName];
    }
    return undefined;
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (!client.id) return;
        const reference_types = await getReferenceTypes().unwrap();
        const reference_type_proveedor = reference_types.find((item) => item.code === REFERENCE_CODES.proveedor.code);
        if (!reference_type_proveedor) return;

        const references_result = await getReferences({
          clientDetailsId: client.id,
          referenceTypeId: reference_type_proveedor.id,
        }).unwrap();

        if (references_result.length) {
          const values = references_result
            .filter((item) => item.reference_type_id === reference_type_proveedor.id)
            .slice(0, 3)
            .map((item) => {
              return {
                id: item.id,
                country_code: item.code_phone_number,
                phone: item.phone_number,
                contact_name: item.contact_name,
                proveedor: item.supplier_rfc,
                email: item.email,
                phone_extension: item.phone_extension,
              };
            });
          formik.setFieldValue("referencia", values);

          setTimeout(() => {
            formik.validateForm();
          }, 200);
        }

        setReferenceType(reference_type_proveedor);

        window.scrollTo(0, 0);
      } catch (error) {
        dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box maxWidth="sm" mx="auto" mt={4} px={2}>
            <Typography
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={700}
              color={theme?.palette?.primary?.dark}
            >
              Referencias
            </Typography>

            <Typography
              textAlign={"center"}
              variant={isMobileOrTablet ? "body2" : "body1"}
              color={theme?.palette?.primary?.dark}
              mt={2}
            >
              <strong>2</strong> de 2
            </Typography>

            <Typography
              textAlign={"center"}
              color={theme?.palette?.primary?.main}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={600}
              mt={2}
            >
              Ahora, escribe 3 referencias que sean tus proveedores, considera que podríamos contactarlos.
            </Typography>

            <Container maxWidth="sm" sx={{ mt: 2, mb: 2 }}>
              <form onSubmit={formik.handleSubmit} noValidate>
                {formik.values.referencia.map((_, index) => (
                  <div key={index}>
                    <Box mt={4} mb={2}>
                      <Typography
                        textAlign={"left"}
                        variant={isMobileOrTablet ? "body2" : "body1"}
                        fontWeight={400}
                        color={theme?.palette?.primary?.dark}
                      >
                        {getOrdinalText(index + 1)} referencia
                      </Typography>
                    </Box>
                    <FormControl
                      fullWidth
                      required
                      sx={{
                        ...customStylesAsterisk,
                        mt: 1,
                      }}
                      error={Boolean(
                        formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index),
                      )}
                    >
                      <InputLabel id={`${index}proveedor`}>Proveedores</InputLabel>
                      <Select
                        labelId="proveedor"
                        name={`referencia[${index}].proveedor`}
                        id={`proveedor${index}`}
                        fullWidth
                        required
                        label="Proveedores"
                        value={formik.values.referencia?.[index]?.proveedor}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(
                          formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index),
                        )}
                        disabled={formik.isSubmitting || isLoading}
                      >
                        <MenuItem value="">Selecciona</MenuItem>
                        {supplierReferences.isSuccess &&
                          supplierReferences?.data?.data?.suppliers
                            ?.filter(
                              (supplier) =>
                                !formik.values.referencia.some(
                                  (ref, refIndex) => refIndex !== index && ref.proveedor === supplier.rfc,
                                ),
                            )
                            .slice()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((supplier) => (
                              <MenuItem key={supplier.rfc} value={supplier.rfc}>
                                {supplier.name.toUpperCase()}
                              </MenuItem>
                            ))}
                      </Select>

                      {formik.touched.referencia?.[index]?.proveedor && getFieldError("proveedor", index) && (
                        <FormHelperText>{getFieldError("proveedor", index)}</FormHelperText>
                      )}
                    </FormControl>

                    <FormControl fullWidth>
                      <TextField
                        name={`referencia[${index}].contact_name`}
                        id={`contact_name${index}`}
                        label="Nombre completo del contacto"
                        margin="normal"
                        required
                        fullWidth
                        autoComplete="off"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={Boolean(
                          formik.touched.referencia?.[index]?.contact_name && getFieldError("contact_name", index),
                        )}
                        helperText={
                          formik.touched.referencia?.[index]?.contact_name && getFieldError("contact_name", index)
                        }
                        value={formik.values.referencia?.[index]?.contact_name}
                        disabled={formik.isSubmitting || isLoading}
                      />
                    </FormControl>

                    <TextField
                      name={`referencia[${index}].email`}
                      id={`email${index}`}
                      label="Correo electrónico"
                      margin="normal"
                      required
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        const value_to_set = (e.target.value || "").toLowerCase();
                        formik.setFieldValue(`referencia[${index}].email`, value_to_set);
                      }}
                      onBlur={formik.handleBlur}
                      error={Boolean(formik.touched.referencia?.[index]?.email && getFieldError("email", index))}
                      helperText={formik.touched.referencia?.[index]?.email && getFieldError("email", index)}
                      value={formik.values.referencia?.[index]?.email}
                      disabled={formik.isSubmitting}
                      inputProps={{ maxLength: 100 }}
                    />

                    <Grid container spacing={1} mt={1}>
                      <Grid item xs={4} sm={4} md={4}>
                        <CustomContryCode
                          label="Código"
                          name={`referencia[${index}].country_code`}
                          value={formik.values.referencia?.[index]?.country_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          error={Boolean(
                            formik.touched.referencia?.[index]?.country_code && getFieldError("country_code", index),
                          )}
                          disabled={formik.isSubmitting || isLoading}
                        />
                      </Grid>

                      <Grid item xs={8} sm={8} md={8}>
                        <TextField
                          name={`referencia[${index}].phone`}
                          id={`phone${index}`}
                          type="text"
                          variant="outlined"
                          label="Teléfono"
                          fullWidth
                          required
                          value={formik.values.referencia?.[index]?.phone}
                          onChange={(event) => {
                            formik.setFieldValue(
                              `referencia[${index}].phone`,
                              event.target.value.replace(/[^0-9]/g, ""),
                            );
                          }}
                          onBlur={formik.handleBlur}
                          error={Boolean(formik.touched.referencia?.[index]?.phone && getFieldError("phone", index))}
                          helperText={formik.touched.referencia?.[index]?.phone && getFieldError("phone", index)}
                          inputProps={{
                            maxLength: 10,
                            inputMode: "numeric",
                          }}
                          disabled={formik.isSubmitting || isLoading}
                        />
                      </Grid>
                    </Grid>

                    <TextField
                      name={`referencia[${index}].phone_extension`}
                      id={`phone_extension${index}`}
                      label="Extensión del teléfono(si aplica)"
                      margin="normal"
                      fullWidth
                      autoComplete="off"
                      onChange={(e) => {
                        const value_to_set = (e.target.value || "").replace(/[^0-9]/g, "");
                        formik.setFieldValue(`referencia[${index}].phone_extension`, value_to_set);
                      }}
                      onBlur={formik.handleBlur}
                      error={Boolean(
                        formik.touched.referencia?.[index]?.phone_extension && getFieldError("phone_extension", index),
                      )}
                      helperText={
                        formik.touched.referencia?.[index]?.phone_extension && getFieldError("phone_extension", index)
                      }
                      value={formik.values.referencia?.[index]?.phone_extension}
                      disabled={formik.isSubmitting}
                      inputProps={{
                        maxLength: 5,
                        inputMode: "numeric",
                      }}
                    />
                  </div>
                ))}
                <Grid container spacing={1} my={2}>
                  <Grid item xs={6} sm={6} md={6}>
                    <Button
                      variant="blue_outlined"
                      type="button"
                      fullWidth
                      sx={{ padding: "0px", height: "100%" }}
                      onClick={() => {
                        navigate("/producto/creditosimple/avance_expediente_digital");
                      }}
                      disabled={formik.isSubmitting || isLoading}
                    >
                      Regresar al inicio
                    </Button>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6}>
                    <Button
                      variant="default"
                      type="submit"
                      fullWidth
                      disabled={!formik.isValid || formik.isSubmitting || isLoading}
                    >
                      Continuar
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Box>
        </Grid>

        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
              <CustomRightImage imageName="img_bofu_12" />
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ReferenciasProveedores;
