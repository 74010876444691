/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material";

import { store } from "./redux/store";
import { LenditApp } from "./LenditApp";
import { CustomTheme as lendiaTheme } from "./theme/CustomThemeLendia";
import { CustomTheme as finbeTheme } from "./theme/CustomThemeFinbe";

import "./styles/main-styles.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { Amplify } from "aws-amplify";
import DynamicMetadata from "@components/DynamicMetadata";
import ScriptsComponent from "@components/ScriptsComponent";
import { getConfigName } from "@helpers/funciones";
import * as Sentry from "@sentry/react";

const getAmplifyConfig = (hostValue: string): any => {
  let userPoolId = undefined;
  let userPoolClientId = undefined;

  if (hostValue === "lendia") {
    userPoolId = import.meta.env.VITE_AWS_USER_POOL_ID;
    userPoolClientId = import.meta.env.VITE_AWS_CLIENT_ID;
  } else if (hostValue === "finbe") {
    userPoolId = import.meta.env.VITE_AWS_USER_POOL_ID_FINBE;
    userPoolClientId = import.meta.env.VITE_AWS_CLIENT_ID_FINBE;
  }

  return {
    Auth: {
      Cognito: {
        userPoolId: userPoolId,
        userPoolClientId: userPoolClientId,
        loginWith: {
          email: true,
        },
        signUpVerificationMethod: "code",
        userAttributes: {
          email: {
            required: true,
          },
        },
      },
    },
  };
};

const getThemeConfig = (hostValue: string) => {
  const themesByValue: Record<string, any> = {
    lendia: lendiaTheme,
    finbe: finbeTheme,
  };

  return themesByValue[hostValue] ? themesByValue[hostValue] : lendiaTheme;
};

const initializeSenty = () => {
  if (import.meta.env.VITE_ENV === "localhost") {
    return;
  }

  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: [
      "localhost",
      // /^https:\/\/api\.lendia\.mx\/v1/,
      // /^https:\/\/asmtx2hphl\.execute-api\.us-east-1\.amazonaws\.com\/dev\/v1/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.VITE_ENV,
  });
};

initializeSenty();

const configName = getConfigName(window.location.href);
const amplifyConfig = getAmplifyConfig(configName);
const selectedTheme = getThemeConfig(configName);
const persistor = persistStore(store);

Amplify.configure(amplifyConfig);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <DynamicMetadata />
    <ScriptsComponent />
    <Provider store={store}>
      <PersistGate loading={<></>} persistor={persistor}>
        <ThemeProvider theme={selectedTheme}>
          <BrowserRouter>
            <LenditApp configName={configName} />
          </BrowserRouter>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
);
