import { createSlice } from "@reduxjs/toolkit";

interface SolicitudCreditoState {}

const initialState: SolicitudCreditoState = {};

const solicitudCreditoSlice = createSlice({
  name: "solicitudCredito",
  initialState,
  reducers: {},
});

export const {} = solicitudCreditoSlice.actions;

export default solicitudCreditoSlice.reducer;
