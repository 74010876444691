import { Box, Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { LoanConditions } from "@components/LoanConditions";
import { setModalQuotationVisible } from "@store/slices/appSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/store";
import icon from "@assets/img/icon_validando_expediente.svg";
import { InactivityHandler } from "@components/InactivityHandler";
import { useLazyGetApplicationByApplicationIdQuery } from "@api/applications";
import { useGetStatusQuery } from "@api/catalogs";
import CustomRightImage from "@components/CustomRightImage";

interface ValidandoExpedienteProps {
  isValidExpediente: boolean;
}

const ValidandoExpedientePFAE: React.FC<ValidandoExpedienteProps> = ({ isValidExpediente }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const applicationData = useSelector((state: RootState) => state.register.application.id);
  const [getApplicationDataWs, { data: applicationDataWs }] = useLazyGetApplicationByApplicationIdQuery();
  const { data: statusList } = useGetStatusQuery();

  useEffect(() => {
    if (applicationData) {
      const interval = setInterval(async () => {
        try {
          await getApplicationDataWs(applicationData).unwrap();
          const csevdStatus = statusList?.find((status: any) => status.code === "CSEVD");
          const cseciStatus = statusList?.find((status: any) => status.code === "CSECI");

          const currentStatusId = applicationDataWs?.data[0]?.status_id;

          if (currentStatusId === csevdStatus?.id) {
            navigate("/producto/creditosimple/completado");
            clearInterval(interval);
          } else if (currentStatusId === cseciStatus?.id) {
            navigate("/producto/creditosimple/errores_expediente_pfae");
            clearInterval(interval);
          }
        } catch (error) {
          console.log("Ha ocurrido un error");
        }
      }, 60000);

      return () => clearInterval(interval);
    }
  }, [applicationData, getApplicationDataWs, applicationDataWs, statusList, navigate]);

  const handleOnClickShowQuotation = () => {
    dispatch(setModalQuotationVisible(true));
  };

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={2}>
        <InactivityHandler />
        <Box textAlign={"center"} mx={4}>
          {isValidExpediente ? (
            <>
              <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
                Expediente en revisión
              </Typography>

              <Typography fontWeight={400} color={theme?.palette?.primary?.dark} mt={3} fontSize={"16px"}>
                Concluiste el llenado de tu expediente digital
              </Typography>

              <Typography fontWeight={300} color={theme?.palette?.primary?.main} mt={3} fontSize={"16px"}>
                ¡Estamos validando tu expediente, volveremos contigo a la brevedad!
              </Typography>

              <Typography fontWeight={600} color={theme?.palette?.primary?.dark} mt={3} fontSize={"16px"}>
                Revisa las condiciones del crédito que estas solicitando
              </Typography>

              <Box mt={3}>
                <LoanConditions />
              </Box>

              <Box sx={{ paddingTop: "50px" }}>
                <Button variant="blue_outlined" size="small" onClick={handleOnClickShowQuotation}>
                  Modifica tu cotización
                </Button>
              </Box>

              <Box sx={{ paddingTop: "2em" }}>
                <Typography fontWeight={300} color={theme?.palette?.primary?.dark} mt={2} fontSize={"14px"}>
                  <span>
                    ¡Despreocúpate!
                    <br /> Puedes salir de esta pantalla <br /> <br />
                    Si existe la necesidad de modificar o adicionar algún documento o tan pronto el expediente se
                    encuentre validado podrás consultarlo aquí mismo y te lo notificaremos vía WhatsApp
                  </span>
                </Typography>
              </Box>
            </>
          ) : (
            <>
              <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
                Faltantes en Expediente
              </Typography>

              <Box sx={{ paddingTop: "2em" }}>
                <Typography fontWeight={300} color={theme?.palette?.primary?.dark} fontSize={"16px"}>
                  Encontramos faltantes dentro de la validación.
                </Typography>
              </Box>

              <Box sx={{ paddingTop: "3em" }}>
                <img src={icon} alt="icon" />
              </Box>

              <Box sx={{ paddingTop: "2em", paddingBottom: "1em" }}>
                <Typography fontWeight={300} color={theme?.palette?.primary?.dark} fontSize={"16px"}>
                  Podría ser necesario el editar algunas secciones o volver a cargar algunos documentos.
                </Typography>
              </Box>

              <Grid container columnSpacing={1} my={4} justifyContent="center">
                <Grid item xs={6} sm={4} md={3}>
                  <Button
                    variant="default"
                    type="submit"
                    size="medium"
                    fullWidth
                    onClick={() => navigate("/producto/creditosimple/avance_expediente_digitalPFAE")}
                    sx={{ mt: 2, padding: "12px", backgroundColor: "#F2704F" }}
                  >
                    Volver a llenar
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_10" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ValidandoExpedientePFAE;
