/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Box, Grid, Skeleton, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetDocumentAppIdQuery } from "@api/documents";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@store/store";
import { setLoanStatus, setLoanApplication } from "@store/slices/registerSlice";
import {
  setSectionAval,
  setSectionCompany,
  setSectionDictamen,
  setRepresentanteLegal,
  setPropietariosReales,
  setAval,
  setIsLoading,
} from "@store/slices/bofuSlice";
import { useLazyGetLoanApplicationByIdQuery, usePostValidateNextStageMutation } from "@api/applications";
import { useLazyGetPersonsQuery } from "@api/personasApi";
import { DocumentResponse, LoanApplication, PersonaStructure, RealOwnerWithDocs } from "@interfaces/index";
import {
  DOCUMENTS_CATALOG,
  PERSON_CODES_AVAL,
  PERSON_CODES_LEGAL_REPRESENTATIVE,
  PERSON_CODES_PROPIETARIO_REAL,
  STATUS_CATALOG,
} from "@helpers/constantes";
import CustomButtonTabExpediente, { ButtonTabStatus } from "@components/CustomButtonTabExpediente";
import { ModalExpediente } from "@components/CustomModalExpediente";

export const TabStepBofuPm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [documentsDictamenUploaded, setDocumentsDictamenUploaded] = useState<boolean>(false);
  const [dictamenStatus, setDictamenStatus] = useState<ButtonTabStatus | undefined>(undefined);
  const [empresaStatus, setEmpresaStatus] = useState<ButtonTabStatus | undefined>(undefined);
  const [avalStatus, setAvalEstatus] = useState<ButtonTabStatus | undefined>(undefined);
  const [propietarioStatus, setPropietarioEstatus] = useState<ButtonTabStatus | undefined>(undefined);
  const [showModalCompanyNotAvailable, setShowModalCompanyNotAvailable] = useState(false);
  const [showModalDictamenNotAvailable, setShowModalDictamenNotAvailable] = useState(false);
  const [showModalBeneficialOwnerNotAvailable, setShowModalBeneficialOwnerNotAvailable] = useState(false);
  const [isSuccessData, setIsSuccessData] = useState<boolean>(false);
  const [disabledTabs, setDisabledTabs] = useState({
    dictamen: true,
    empresa: true,
    aval: true,
    propietario: true,
  });

  const accountUser = useSelector((state: RootState) => state.register.account_user);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const applicationData = useSelector((state: RootState) => state.register.application);
  const bofuAval = useSelector((state: RootState) => state.bofu.aval);
  const bofuOwners = useSelector((state: RootState) => state.bofu.propietarios_reales);
  const sectionDictamen = useSelector((state: RootState) => state.bofu.section_dictamen);
  const sectionCompany = useSelector((state: RootState) => state.bofu.section_company);
  const sectionAval = useSelector((state: RootState) => state.bofu.section_aval);

  const [getDocumentsByAppId] = useLazyGetDocumentAppIdQuery();
  const [getPersonsByCode] = useLazyGetPersonsQuery();
  const [getLoanAplication] = useLazyGetLoanApplicationByIdQuery();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();

  const handleButtonClick = (seccion: string) => {
    if (seccion === "empresa") {
      if (!documentsDictamenUploaded) {
        setShowModalCompanyNotAvailable(true);
        return;
      }
    } else if (seccion === "aval") {
      if (!applicationData.decision_validated) {
        setShowModalDictamenNotAvailable(true);
        return;
      }
    } else if (seccion === "propietario") {
      if (!applicationData.decision_validated) {
        setShowModalDictamenNotAvailable(true);
        return;
      }
      if (bofuOwners.length === 0) {
        setShowModalBeneficialOwnerNotAvailable(true);
        return;
      }
    }
  };

  const monitoringBofuChanges = async () => {
    try {
      const { errores_expediente_pm } = STATUS_CATALOG;

      if (applicationData.status_catalog?.code !== errores_expediente_pm.code) {
        return;
      }

      // validar si todo completo en la seccion de empresa
      const company_forms_completed =
        applicationData.loan_application_completed &&
        applicationData.references_completed &&
        applicationData.bank_information_completed &&
        applicationData.identity_validation_completed
          ? true
          : false;

      if (!company_forms_completed) {
        return;
      }

      if (
        !sectionCompany?.doc_comprobante_domicilio ||
        sectionCompany?.doc_comprobante_domicilio?.validated === false
      ) {
        return;
      }

      const aval_forms_completed =
        bofuAval?.information_person_completed &&
        bofuAval.identity_validation_completed &&
        bofuAval.bank_references_completed
          ? true
          : false;

      if (!aval_forms_completed) {
        return;
      }

      if (!sectionAval?.doc_constancia_sf || sectionAval?.doc_constancia_sf?.validated === false) {
        return;
      }

      if (!sectionAval?.doc_comprobante_domicilio || sectionAval?.doc_comprobante_domicilio?.validated === false) {
        return;
      }

      const owner_forms_completed = bofuOwners.every((owner) => owner.owner.information_person_completed === true);

      if (!owner_forms_completed) {
        return;
      }

      const owner_documents_completed = bofuOwners.every((owner) => {
        return owner.identificacion?.validated !== false && owner.constancia?.validated !== false;
      });

      if (!owner_documents_completed) {
        return;
      }

      const { code: next_status, url: next_url } = STATUS_CATALOG.validando_expediente_pm;
      await triggerPostNextStage({
        applicationId: applicationData.id,
        statusCode: next_status,
        username: `BOFU - ${accountUser.email}`,
        disable_sequence_check: true,
      }).unwrap();
      navigate(next_url);
    } catch (error) {
      console.error("Ha ocurrido un error al monitorear las incidencias", error);
    }
  };

  const setDocumentsDictamen = ({ docs_account }: { docs_account: DocumentResponse[] }) => {
    const { acta_constitutiva, identificacion_representante_legal, acta_adicional } = DOCUMENTS_CATALOG;

    const doc_acta_constitutiva = docs_account.find((item) => item.file_code === acta_constitutiva.code);
    const doc_identificacion = docs_account.find((item) => item.file_code === identificacion_representante_legal.code);
    const doc_acta_adicional = docs_account.filter((item) => item.file_code === acta_adicional.code);

    dispatch(
      setSectionDictamen({
        doc_identificacion,
        doc_acta_constitutiva,
        doc_actas_adicionales: doc_acta_adicional.length ? doc_acta_adicional : [undefined],
      }),
    );
  };

  const setDocumentsCompany = ({
    docs_account,
    docs_representante,
  }: {
    docs_account: DocumentResponse[];
    docs_representante: DocumentResponse[];
  }) => {
    const { solicitud_credito, comprobante_domicilio, validacion_identidad } = DOCUMENTS_CATALOG;

    const doc_solicitud = docs_account.find((file) => file.file_code === solicitud_credito.code && file.status);
    const doc_comprobante_dom = docs_account.find(
      (file) => file.file_code === comprobante_domicilio.code && file.status,
    );
    const doc_validacion_identidad = docs_representante.find(
      (item) => item.file_code === validacion_identidad.code && item.status,
    );

    dispatch(
      setSectionCompany({
        doc_solicitud_credito: doc_solicitud,
        doc_validacion_identidad: doc_validacion_identidad,
        doc_comprobante_domicilio: doc_comprobante_dom,
      }),
    );
  };

  const setDocumentsAval = async ({
    aval,
    docs_aval,
  }: {
    aval: PersonaStructure | null;
    docs_aval: DocumentResponse[];
  }) => {
    if (!aval) return;

    const { constancia_situacion_fiscal, comprobante_domicilio, anexo_1, validacion_identidad } = DOCUMENTS_CATALOG;

    const doc_anexo_1 = docs_aval.find((item) => item.file_code === anexo_1.code);
    const doc_validacion_identidad = docs_aval.find((file) => file.file_code === validacion_identidad.code);
    const doc_constancia_sf = docs_aval.find((item) => item.file_code === constancia_situacion_fiscal.code);
    const doc_comprobante_domicilio = docs_aval.find((item) => item.file_code === comprobante_domicilio.code);

    dispatch(
      setSectionAval({
        doc_anexo_1: doc_anexo_1,
        doc_validacion_identidad: doc_validacion_identidad,
        doc_constancia_sf: doc_constancia_sf,
        doc_comprobante_domicilio: doc_comprobante_domicilio,
      }),
    );
  };

  const enableOrDisableTabs = ({ loan_application }: { loan_application: LoanApplication }) => {
    const { status_catalog } = loan_application;
    const { avance_expediente_digital, errores_expediente_pm, documentos_dictamen, dictamen_juridico } = STATUS_CATALOG;

    if (status_catalog?.code === avance_expediente_digital.code) {
      // si la solicitud esta en llenado de expediente se habilitan todos los tabs
      setDisabledTabs({
        dictamen: false,
        empresa: false,
        aval: false,
        propietario: false,
      });
    } else if (status_catalog?.code === errores_expediente_pm.code) {
      // si la solicitud tiene incidencia se habilitan todos los tabs
      setDisabledTabs({
        dictamen: false,
        empresa: false,
        aval: false,
        propietario: false,
      });
    } else if (status_catalog?.code === documentos_dictamen.code || status_catalog?.code === dictamen_juridico.code) {
      // si la solicitud esta en dictamen juridico o documentos dictamen se habilitan los tabs de dictamen y empresa
      setDisabledTabs({
        dictamen: false,
        empresa: false,
        aval: true,
        propietario: true,
      });
    } else {
      setDisabledTabs({
        dictamen: true,
        empresa: true,
        aval: true,
        propietario: true,
      });
    }
  };

  const generateLabelDictamen = () => {
    const list_documents = [sectionDictamen?.doc_acta_constitutiva, sectionDictamen?.doc_identificacion];

    if (sectionDictamen?.doc_actas_adicionales.length === 0) {
      sectionDictamen.doc_actas_adicionales.forEach((item) => {
        list_documents.push(item);
      });
    }

    // validar si todos los documentos estan cargados
    const all_docs_uploaded = list_documents.every((item) => item);

    // validar si todos los documentos estan validados
    const all_docs_valid = list_documents.every((item) => item?.validated === true);

    // validar si algun documento fue rechazado
    const some_doc_invalid = list_documents.some((item) => item?.validated === false);

    setDocumentsDictamenUploaded(all_docs_uploaded);

    if (all_docs_valid) {
      setDictamenStatus("validado");
    } else if (some_doc_invalid) {
      setDictamenStatus("faltante");
    } else if (all_docs_uploaded) {
      setDictamenStatus("cargado");
    } else {
      setDictamenStatus(undefined);
    }
  };

  const generateLabelCompany = () => {
    const list_documents = [
      sectionCompany?.doc_solicitud_credito,
      sectionCompany?.doc_comprobante_domicilio,
      sectionCompany?.doc_validacion_identidad,
    ];

    const all_docs_valid = list_documents.every((item) => item?.validated === true);
    const some_doc_invalid = list_documents.some((item) => item?.validated === false);
    const all_docs_uploaded = list_documents.every((item) => item);

    if (all_docs_valid) {
      setEmpresaStatus("validado");
    } else if (some_doc_invalid) {
      setEmpresaStatus("faltante");
    } else if (all_docs_uploaded) {
      setEmpresaStatus("cargado");
    } else {
      setEmpresaStatus(undefined);
    }
  };

  const generateLabelAval = () => {
    // validar si todos los formularios estan completos
    const all_forms_completed =
      bofuAval?.information_person_completed != null &&
      bofuAval?.identity_validation_completed !== null &&
      bofuAval?.bank_references_completed !== null;

    // validar si algun formulario fue rechazado
    const some_form_invalid =
      bofuAval?.information_person_completed === false ||
      bofuAval?.identity_validation_completed === false ||
      bofuAval?.bank_references_completed === false;

    // validar si todos los documentos estan cargados
    const all_docs_uploaded = sectionAval?.doc_constancia_sf && sectionAval?.doc_comprobante_domicilio ? true : false;

    // lista de documentos a validar
    const list_documents = [
      sectionAval?.doc_anexo_1,
      sectionAval?.doc_comprobante_domicilio,
      sectionAval?.doc_constancia_sf,
    ];

    // validar si todos los documentos estan validados
    const is_all_docs_valid = list_documents.every((item) => item?.validated === true);

    // validar si algun documento fue rechazado
    const some_doc_invalid = list_documents.some((item) => item?.validated === false);

    if (all_forms_completed && all_docs_uploaded) {
      if (is_all_docs_valid) {
        setAvalEstatus("validado");
      } else if (some_doc_invalid || some_form_invalid) {
        setAvalEstatus("faltante");
      } else {
        setAvalEstatus("cargado");
      }
    } else {
      setAvalEstatus(undefined);
    }
  };

  const generateLabelRealOwners = () => {
    if (bofuOwners.length === 0) {
      setPropietarioEstatus(undefined);
      return;
    }

    const all_forms_completed = bofuOwners.every((owner) => owner.owner.information_person_completed !== null);
    const all_identificacion_uploaded = bofuOwners.every((owner) => owner.identificacion !== undefined);
    const all_constancia_uploaded = bofuOwners.every((owner) => owner.constancia !== undefined);
    const all_anexo_2_uploaded = bofuOwners.every((owner) => owner.anexo_2 !== undefined);
    const is_all_docs_uploaded =
      all_identificacion_uploaded && all_constancia_uploaded && all_anexo_2_uploaded ? true : false;

    const all_identificacion_valid = bofuOwners.every((owner) => owner.identificacion?.validated === true);
    const all_constancia_valid = bofuOwners.every((owner) => owner.constancia?.validated === true);
    const all_doc_anexo_2_valid = bofuOwners.every((owner) => owner.anexo_2?.validated === true);
    const all_docs_valid = all_doc_anexo_2_valid && all_identificacion_valid && all_constancia_valid ? true : false;

    const some_doc_invalid = bofuOwners.some((owner) => {
      const { identificacion, constancia, anexo_2 } = owner;
      return identificacion?.validated === false || constancia?.validated === false || anexo_2?.validated === false
        ? true
        : false;
    });

    if (all_forms_completed && is_all_docs_uploaded) {
      if (all_docs_valid) {
        setPropietarioEstatus("validado");
      } else if (some_doc_invalid) {
        setPropietarioEstatus("faltante");
      } else {
        setPropietarioEstatus("cargado");
      }
    } else {
      setPropietarioEstatus(undefined);
    }
  };

  useEffect(() => {
    monitoringBofuChanges();
  }, [sectionCompany, sectionAval, bofuAval, bofuOwners]);

  useEffect(() => {
    generateLabelDictamen();
  }, [sectionDictamen]);

  useEffect(() => {
    generateLabelCompany();
  }, [sectionCompany]);

  useEffect(() => {
    generateLabelAval();
  }, [bofuAval, sectionAval]);

  useEffect(() => {
    generateLabelRealOwners();
  }, [bofuOwners]);

  useEffect(() => {
    (async () => {
      try {
        setIsSuccessData(false);
        dispatch(setIsLoading(true));

        // consultar la informacion
        const [loan_application, persons, documents] = await Promise.all([
          getLoanAplication(applicationData.id).unwrap(),
          getPersonsByCode({
            loanApplicationId: applicationData.id,
          }).unwrap(),
          getDocumentsByAppId({
            loanApplicationId: applicationData.id,
            status: true,
          }).unwrap(),
        ]);

        // validar si la solicitud fue rechazada por documentacion
        const { rechazo_expediente } = STATUS_CATALOG;

        if (loan_application?.status_catalog?.code === rechazo_expediente.code) {
          dispatch(setLoanStatus(rechazo_expediente.code));
          navigate(rechazo_expediente.url);
          return;
        }

        const representantes = persons.filter((item) =>
          PERSON_CODES_LEGAL_REPRESENTATIVE.includes(item.person_type?.code || ""),
        );
        const avales = persons.filter(
          (item) => item.is_shareholder === true && PERSON_CODES_AVAL.includes(item?.person_type?.code || ""),
        );
        const propietarios = persons.filter(
          (item) =>
            item.is_shareholder === true && PERSON_CODES_PROPIETARIO_REAL.includes(item.person_type?.code || ""),
        );

        // tomar el primer representante y aval
        const representante = representantes[0];
        const aval = avales[0];

        // filtrar los documentos por tipo de persona
        const docs_account = documents.filter((item) => item.client_details_id === clientDetails.id);
        const docs_representante = representante ? documents.filter((item) => item.person_id === representante.id) : [];
        const docs_aval = aval ? documents.filter((item) => item.person_id === aval.id) : [];

        // generar estructura de propietarios reales
        const { identificacion_oficial, constancia_situacion_fiscal, anexo_2 } = DOCUMENTS_CATALOG;
        const owners_ids = propietarios.map((item) => item.id);
        const docs_owners = documents.filter((item) => owners_ids.includes(item.person_id || ""));
        const owners_with_docs: RealOwnerWithDocs[] = [];

        propietarios.forEach((owner) => {
          const docs_by_owner = docs_owners.filter((item) => item.person_id === owner.id);
          const newItem: RealOwnerWithDocs = {
            owner,
            anexo_2: docs_by_owner.find((item) => item.file_code === anexo_2.code) || undefined,
            identificacion: docs_by_owner.find((item) => item.file_code === identificacion_oficial.code) || undefined,
            constancia: docs_by_owner.find((item) => item.file_code === constancia_situacion_fiscal.code) || undefined,
          };
          owners_with_docs.push(newItem);
        });

        if (location.pathname === "/producto/creditosimple/docs_aval") {
          if (!loan_application?.decision_validated) {
            navigate("/producto/creditosimple/docs_empresa");
          }
        } else if (location.pathname === "/producto/creditosimple/propietario_real_pm") {
          if (!loan_application?.decision_validated || propietarios.length === 0) {
            navigate("/producto/creditosimple/docs_empresa");
          }
        }

        dispatch(setLoanApplication(loan_application));
        dispatch(setRepresentanteLegal(representante || undefined));
        dispatch(setAval(aval || undefined));
        dispatch(setPropietariosReales(owners_with_docs));

        enableOrDisableTabs({ loan_application });
        setDocumentsDictamen({ docs_account });
        setDocumentsCompany({ docs_account, docs_representante });
        setDocumentsAval({ aval, docs_aval });

        setIsSuccessData(true);
      } catch (error) {
        console.error("Ha ocurrido un error al cargar toda la informacion", error);
      } finally {
        dispatch(setIsLoading(false));
      }
    })();
  }, []);

  return (
    <>
      {isSuccessData ? (
        <Box
          sx={{
            width: "100%",
            height: "80px",
            bgcolor: theme?.palette?.backgroundRight?.tab,
            p: 1,
            borderRadius: "8px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={3} md={3}>
              <CustomButtonTabExpediente
                label="dictamen"
                status={dictamenStatus}
                disabled={disabledTabs.dictamen}
                isActive={location.pathname === "/producto/creditosimple/docs_empresa"}
                onClick={() => {
                  navigate("/producto/creditosimple/docs_empresa");
                }}
              />
            </Grid>

            <Grid item xs={3} md={3}>
              <CustomButtonTabExpediente
                label="empresa"
                status={empresaStatus}
                isActive={location.pathname === "/producto/creditosimple/avance_expediente_digital"}
                onClick={() => navigate("/producto/creditosimple/avance_expediente_digital")}
                disabled={!documentsDictamenUploaded || disabledTabs.empresa}
                onClickDisabled={() => handleButtonClick("empresa")}
              />
            </Grid>

            <Grid item xs={3} md={3}>
              <CustomButtonTabExpediente
                label="aval"
                status={avalStatus}
                isActive={location.pathname === "/producto/creditosimple/docs_aval"}
                onClick={() => navigate("/producto/creditosimple/docs_aval")}
                disabled={!applicationData?.decision_validated || disabledTabs.aval}
                onClickDisabled={() => handleButtonClick("aval")}
              />
            </Grid>

            <Grid item xs={3} md={3}>
              <CustomButtonTabExpediente
                label="propietario"
                status={propietarioStatus}
                isActive={location.pathname === "/producto/creditosimple/propietario_real_pm"}
                onClick={() => navigate("/producto/creditosimple/propietario_real_pm")}
                disabled={!applicationData?.decision_validated || bofuOwners.length === 0 || disabledTabs.propietario}
                onClickDisabled={() => handleButtonClick("propietario")}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Skeleton variant="rectangular" sx={{ height: "90px", width: "100%", borderRadius: 2 }} />
      )}

      <ModalExpediente
        type="company_not_available"
        openModal={showModalCompanyNotAvailable}
        closeModal={() => {
          setShowModalCompanyNotAvailable(false);
        }}
      />

      <ModalExpediente
        type="dictamen_not_available"
        openModal={showModalDictamenNotAvailable}
        closeModal={() => {
          setShowModalDictamenNotAvailable(false);
        }}
      />

      <ModalExpediente
        type="beneficial_owner_not_available"
        openModal={showModalBeneficialOwnerNotAvailable}
        closeModal={() => {
          setShowModalBeneficialOwnerNotAvailable(false);
        }}
      />
    </>
  );
};
