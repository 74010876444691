/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TabStepBofuPm } from "@components/index";
import icon from "@assets/img/icon_validando_expediente.svg";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomRightImage from "@components/CustomRightImage";

const ValidandoExpediente: React.FC = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={2}>
        <Box textAlign={"center"} mx={4}>
          <InactivityHandler />
          <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
            Faltantes en Expediente
          </Typography>

          <TabStepBofuPm />

          <Box sx={{ paddingTop: "2em" }}>
            <Typography fontWeight={300} color={theme?.palette?.primary?.dark} fontSize={"16px"}>
              Encontramos faltantes dentro de la validación, por favor ve a la sección o secciones marcadas con
              faltantes para resolverlos.
            </Typography>
          </Box>

          <Box sx={{ paddingTop: "3em" }}>
            <img src={icon} alt="icon" />
          </Box>

          <Box sx={{ paddingTop: "2em", paddingBottom: "1em" }}>
            <Typography fontWeight={300} color={theme?.palette?.primary?.dark} fontSize={"16px"}>
              Podría ser necesario el editar algunas secciones o volver a cargar algunos documentos.
            </Typography>
          </Box>
        </Box>
      </Grid>
      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_bofu_2" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default ValidandoExpediente;
