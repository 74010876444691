import { PayloadRequestReferencias, ReferenceStructure } from "@interfaces/request";
import { baseApi } from "./baseApi";
import { ReferenceGetResponse, ReferencePostResponse } from "@interfaces/response";
import { UUID } from "crypto";

const referenciasApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getReferences: build.query<ReferenceStructure[], { clientDetailsId: UUID; referenceTypeId: UUID }>({
      query: ({ clientDetailsId, referenceTypeId }) => {
        let url = `referencias?q=client_details_id:${clientDetailsId}&q=reference_type_id:${referenceTypeId}`;
        return { url };
      },
      transformResponse: (response: ReferenceGetResponse) => {
        return response.data.data;
      },
    }),
    postReferences: build.mutation<ReferencePostResponse, PayloadRequestReferencias>({
      query: (data: PayloadRequestReferencias) => ({
        url: `/referencias`,
        method: "POST",
        body: data,
      }),
    }),
    patchReferences: build.mutation<
      ReferencePostResponse,
      { referenceId: UUID | undefined; body: PayloadRequestReferencias }
    >({
      query: ({ referenceId, body }) => ({
        url: `/referencias/${referenceId}`,
        method: "PATCH",
        body: body,
      }),
    }),
  }),
});

export const { useLazyGetReferencesQuery, usePostReferencesMutation, usePatchReferencesMutation } = referenciasApi;
