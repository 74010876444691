/* eslint-disable @typescript-eslint/no-explicit-any */
import { baseApi } from "./baseApi";
import {
  UseLoanPaginated,
  UseLoanCategory,
  TaxSystemsPaginated,
  TaxSystem,
  CatalogPersonType,
  CatalogStates,
  FileCatalogResponse,
  CatalogFile,
  DocumentConfig,
  DocumentConfigResponse,
  BankResponse,
  Bank,
  GetCivilStatusResponse,
  Address,
  AddressDataStructure,
  DependenciasData,
  GetDependenciasCatalogo,
  CargoPublicoData,
  GetCargoPublicoCatalogo,
  GetPepsTypeDataCatalogo,
  PepsTypeData,
  RelationshipData,
  GetRelationshipCatalogo,
  PaymentMethod,
  GetPaymentMethodCatalogo,
  StatusData,
  GetStatusCatalogo,
  GetMatrimonialRegimesCatalogo,
  MatrimonialRegimesData,
  CivilStatus,
  ReferenceType,
  ReferenceTypeResponse,
  ColonyResponse,
} from "../types";

const catalogosApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getTaxSystems: build.query<TaxSystem, string>({
      query: (code) => ({
        url: `catalogos/tax_systems`,
        params: { code: code },
      }),
      transformResponse: (response: { data: TaxSystemsPaginated }) => {
        return response.data.data[0];
      },
    }),
    getLoanUse: build.query<Array<UseLoanCategory>, void>({
      query: () => "catalogos/loan_use_categories",
      transformResponse: (response: { data: UseLoanPaginated }) => {
        return response.data.data.sort((a, b) => {
          if (a.order > b.order) return 1;

          if (a.order < b.order) return -1;

          return 0;
        });
      },
    }),
    getPersonType: build.query<Array<CatalogPersonType>, void>({
      query: () => "catalogos/person_type?=user_for_rol:true",
      transformResponse: (response: { data: any }) => {
        const data = response.data.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
            tax_systems_id: item.tax_systems_id,
            use_for_rol: item.use_for_rol,
            code: item.code,
            tax_system_type: item.tax_system_type,
          };
        });
        return data;
      },
    }),
    getStates: build.query<Array<CatalogStates>, void>({
      query: () => "catalogos/state",
      transformResponse: (response: { data: any }) => {
        const result = response.data.data.map((item: any) => {
          return {
            id: item.id,
            name: item.name,
          };
        });
        return result;
      },
    }),
    getFilesCatalog: build.query<Array<CatalogFile>, void>({
      query: () => ({
        url: "catalogos/file_category",
        params: {
          size: 1000,
        },
      }),
      transformResponse: (response: { data: FileCatalogResponse }) => {
        return response.data.data;
      },
    }),
    getDocsConfig: build.query<Array<DocumentConfig>, { taxCode: string; personCode: string | undefined }>({
      query: (params) => ({
        url: `catalogos/document_config`,
        params: {
          taxcode: params.taxCode,
          personcode: params.personCode || "",
        },
      }),
      transformResponse: (response: DocumentConfigResponse) => {
        return response.data.data;
      },
    }),
    getBanksByCode: build.query<Bank | undefined, string>({
      query: (codeBank) => ({
        url: "catalogos/banks",
        params: {
          q: `code:${codeBank}`,
        },
      }),
      transformResponse: (response: BankResponse) => {
        return response.data.data.length > 0 ? response.data.data[0] : undefined;
      },
    }),
    getCivilStatus: build.query<CivilStatus[], void>({
      query: () => `catalogos/civil_status`,
      transformResponse: (response: GetCivilStatusResponse) => {
        return response.data.data;
      },
    }),
    getAddressType: build.query<Address[], void>({
      query: () => "catalogos/address_type",
      transformResponse: (response: { data: AddressDataStructure }) => {
        return response.data.data;
      },
    }),
    getBusinessActivities: build.query<
      Array<{ id: string; name: string; description: string; business_sector_id: string }>,
      void
    >({
      query: () => "catalogos/business_activity",
      transformResponse: (response: { data: any }) => {
        return response.data.data.map((item: any) => ({
          id: item.id,
          name: item.name,
          description: item.description,
          business_sector_id: item.business_sector_id,
        }));
      },
    }),
    getDependecias: build.query<DependenciasData[], void>({
      query: () => "catalogos/dependencies",
      transformResponse: (response: { data: GetDependenciasCatalogo }) => {
        return response.data.data;
      },
    }),
    getCargoPublico: build.query<CargoPublicoData[], void>({
      query: () => "catalogos/public_office",
      transformResponse: (response: { data: GetCargoPublicoCatalogo }) => {
        return response.data.data;
      },
    }),
    getPepsType: build.query<PepsTypeData[], void>({
      query: () => "catalogos/pep_type",
      transformResponse: (response: { data: GetPepsTypeDataCatalogo }) => {
        return response.data.data;
      },
    }),
    getRelationship: build.query<RelationshipData[], void>({
      query: () => "catalogos/relationship",
      transformResponse: (response: { data: GetRelationshipCatalogo }) => {
        return response.data.data;
      },
    }),
    getPaymentMethod: build.query<PaymentMethod[], void>({
      query: () => "catalogos/payment_method",
      transformResponse: (response: { data: GetPaymentMethodCatalogo }) => {
        return response.data.data;
      },
    }),
    // TODO: sugiero remover el endpoint
    getStatus: build.query<StatusData[], void>({
      query: () => "catalogos/status?size=50",
      transformResponse: (response: { data: GetStatusCatalogo }) => {
        return response.data.data;
      },
    }),
    getMatrimonialRegimes: build.query<MatrimonialRegimesData[], void>({
      query: () => "catalogos/matrimonial_regimes",
      transformResponse: (response: { data: GetMatrimonialRegimesCatalogo }) => {
        return response.data.data;
      },
    }),
    getReferenceTypes: build.query<Array<ReferenceType>, void>({
      query: () => "catalogos/reference_type",
      transformResponse: (response: { data: ReferenceTypeResponse }) => {
        return response.data.data;
      },
    }),
    getColonyById: build.query<ColonyResponse, { colonyId: number }>({
      query: ({ colonyId }) => `/catalogos/colonia/${colonyId}`,
      transformResponse: (response: { data: ColonyResponse }) => {
        return response.data;
      },
    }),
  }),
});

export const {
  useLazyGetLoanUseQuery,
  useLazyGetMatrimonialRegimesQuery,
  useLazyGetTaxSystemsQuery,
  useGetPersonTypeQuery,
  useLazyGetPersonTypeQuery,
  useGetStatesQuery,
  useLazyGetStatesQuery,
  useGetFilesCatalogQuery,
  useLazyGetFilesCatalogQuery,
  useGetDocsConfigQuery,
  useLazyGetDocsConfigQuery,
  useLazyGetBanksByCodeQuery,
  useLazyGetCivilStatusQuery,
  useGetAddressTypeQuery,
  useLazyGetAddressTypeQuery,
  useGetBusinessActivitiesQuery,
  useLazyGetDependeciasQuery,
  useLazyGetCargoPublicoQuery,
  useLazyGetPepsTypeQuery,
  useLazyGetRelationshipQuery,
  useGetPaymentMethodQuery,
  useGetStatusQuery,
  useGetReferenceTypesQuery,
  useLazyGetReferenceTypesQuery,
  useLazyGetColonyByIdQuery,
} = catalogosApi;
