import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import MainLayout from "../../../layouts/MainLayout";

import CrearCuenta from "@pages/producto/credito_simple/tofu/pm/CrearCuenta";
import RegistroDatosPersonales from "@pages/producto/credito_simple/tofu/pm/RegistroDatosPersonales";
import ValidacionTelefono from "@pages/producto/credito_simple/tofu/pm/ValidacionTelefono";
import DatosEmpresaPm from "@pages/producto/credito_simple/tofu/pm/DatosEmpresaPm";
import ConsultaCiec from "@pages/producto/credito_simple/tofu/pm/ConsultaCiec";
import UsoDeCredito from "@pages/producto/credito_simple/tofu/pm/UsoDeCredito";
import PresenciaDigitalPm from "@pages/producto/credito_simple/tofu/pm/PresenciaDigitalPm";
import DatosAccionista from "@pages/producto/credito_simple/tofu/pm/DatosAccionista";
import RfcCurp from "@pages/producto/credito_simple/tofu/pm/RfcCurp";
import DomicilioAccionista from "@pages/producto/credito_simple/tofu/pm/DomicilioAccionista";
import ConsultaBuro from "@pages/producto/credito_simple/tofu/pm/ConsultaBuro";
import Oferta from "@pages/producto/credito_simple/tofu/pm/Oferta";
import Rechazo from "@pages/producto/credito_simple/tofu/pm/Rechazo";
import WaitingRoom from "@pages/producto/credito_simple/tofu/pm/WaitingRoom";
import DatosEmpresaPfae from "@pages/producto/credito_simple/tofu/pfae/DatosEmpresaPfae";
import ConsultaBuroPfae from "@pages/producto/credito_simple/tofu/pfae/ConsultaBuroPfae";
import DomicilioPfae from "@pages/producto/credito_simple/tofu/pfae/DomicilioPfae";
import PresenciaDigitalPfae from "@pages/producto/credito_simple/tofu/pfae/PresenciaDigitalPfae";
import AgendaVisitaOcularPM from "@pages/producto/credito_simple/bofu/pm/AgendaVisitaOcularPM";
import AvanceExpedienteDigital from "@pages/producto/credito_simple/bofu/pm/AvanceExpedienteDigital";
import DatosPropietarioRealPart1 from "@pages/producto/credito_simple/bofu/pm/DatosPropietarioRealPart1";
import DatosPropietarioRealPart2 from "@pages/producto/credito_simple/bofu/pm/DatosPropietarioRealPart2";
import DocsAval from "@pages/producto/credito_simple/bofu/pm/DocsAval";
import DocsEmpresa from "@pages/producto/credito_simple/bofu/pm/DocsEmpresa";
import ExpedienteDigitalCompleto from "@pages/producto/credito_simple/bofu/pm/ExpedienteDigitalCompletado";
import InformacionAval from "@pages/producto/credito_simple/bofu/pm/InformacionAval";
import InformacionAvalP2 from "@pages/producto/credito_simple/bofu/pm/InformacionAvalP2";
import InformacionBancaria from "@pages/producto/credito_simple/bofu/pm/InformacionBancaria";
import ListaRevision from "@pages/producto/credito_simple/bofu/pm/ListaRevision";
import PropietarioRealPM from "@pages/producto/credito_simple/bofu/pm/PropietarioReal";
import ReferenciasClientes from "@pages/producto/credito_simple/bofu/pm/ReferenciasClientes";
import ReferenciasProveedores from "@pages/producto/credito_simple/bofu/pm/ReferenciasProveedores";
import VisitaOcularConcluida from "@pages/producto/credito_simple/bofu/pm/VisitaOcularConcluida";
import BofuCompletado from "@pages/producto/credito_simple/bofu/pm/BofuCompletado";
import SolicitudCreditoPart1 from "@pages/producto/credito_simple/bofu/pm/SolicitudCreditoPart1";
import SolicitudCreditoPart2 from "@pages/producto/credito_simple/bofu/pm/SolicitudCreditoPart2";
import SolicitudCreditoPart3 from "@pages/producto/credito_simple/bofu/pm/SolicitudCreditoPart3";
import ValidandoExpediente from "@pages/producto/credito_simple/bofu/pm/ValidandoExpediente";
import ErrorExpediente from "@pages/producto/credito_simple/bofu/pm/ErrorExpediente";
import AgendaVisitaOcularPFAE from "@pages/producto/credito_simple/bofu/pfae/AgendaVisitaOcularPFAE";
import AvanceExpedienteDigitalPFAE from "@pages/producto/credito_simple/bofu/pfae/AvanceExpedienteDigitalPFAE";
import InfoDocumentosPFAE from "@pages/producto/credito_simple/bofu/pfae/InfoDocumentosPFAE";
import InformacionBancariaPfae from "@pages/producto/credito_simple/bofu/pfae/InformacionBancariaPfae";
import ReferenciasClientesPfae from "@pages/producto/credito_simple/bofu/pfae/ReferenciasClientesPfae";
import ReferenciasProveedoresPfae from "@pages/producto/credito_simple/bofu/pfae/ReferenciasProveedoresPfae";
import SolicitudCreditoPart1PFAE from "@pages/producto/credito_simple/bofu/pfae/SolicitudCreditoPart1PFAE";
import SolicitudCreditoPart2PFAE from "@pages/producto/credito_simple/bofu/pfae/SolicitudCreditoPart2PFAE";
import SolicitudCreditoPart3PFAE from "@pages/producto/credito_simple/bofu/pfae/SolicitudCreditoPart3PFAE";
import ValidandoExpedientePFAE from "@pages/producto/credito_simple/bofu/pfae/ValidandoExpedientePFAE";
import ValidarIndentidadPfae from "@pages/producto/credito_simple/bofu/pfae/ValidarIndentidadPfae";
import ConfirmacionDomicilio from "@pages/producto/credito_simple/bofu/pm/ConfirmacionDomicilio";
import ValidacionIdentidad from "@pages/producto/credito_simple/bofu/pm/ValidacionIdentidadPersona";
import ConfirmacionVisitaAgendada from "@pages/producto/credito_simple/bofu/ConfirmacionVisitaAgendada";
import RechazoManual from "@pages/producto/credito_simple/bofu/RechazoManual";
import AgendaVisita from "@pages/producto/credito_simple/bofu/AgendaVisita";
// import FirmaDocumentos from "@pages/producto/credito_simple/bofu/pm/FirmaDocumentos";
import FirmaDocumentosFisicos from "@pages/producto/credito_simple/bofu/pm/FirmaDocumentosFisicos";
import { RootState } from "@store/store";
import {
  STATUS_TOFU_BY_SCREEN_PFAE,
  STATUS_TOFU_BY_SCREEN_PM,
  TAX_SYSTEM_TYPE,
  STATUS_BOFU_PM,
} from "@helpers/constantes";
import NotFound from "@pages/NotFound";
import ZonaGris from "@pages/producto/credito_simple/tofu/pm/ZonaGris";
import ProtectByStatus from "@routes/ProtectRouteByStatus";

export const CreditoSimple = () => {
  const appData = useSelector((state: RootState) => state.app);
  const application = useSelector((state: RootState) => state.register.application);

  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Navigate to={appData.process_page} />} />

        <Route path="crear_cuenta" element={<CrearCuenta />} />

        {application.tax_system_type === TAX_SYSTEM_TYPE.PFAE.code && (
          <React.Fragment>
            <Route
              path="/"
              element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.registro_datos_personales} />}
            >
              <Route path="registro_datos_personales" element={<RegistroDatosPersonales />} />
            </Route>
            <Route
              path="/"
              element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.validacion_telefono} />}
            >
              <Route path="validacion_telefono" element={<RegistroDatosPersonales />} />
            </Route>
            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.datos_empresa_pfae} />}>
              <Route path="datos_empresa_pfae" element={<DatosEmpresaPfae />} />
            </Route>
            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.domicilio_pfae} />}>
              <Route path="domicilio_pfae" element={<DomicilioPfae />} />
            </Route>
            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.consulta_ciec} />}>
              <Route path="consulta_ciec" element={<ConsultaCiec />} />
            </Route>
            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.uso_de_credito} />}>
              <Route path="uso_de_credito" element={<UsoDeCredito />} />
            </Route>
            <Route
              path="/"
              element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.presencia_digital_pfae} />}
            >
              <Route path="presencia_digital_pfae" element={<PresenciaDigitalPfae />} />
            </Route>
            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PFAE.consulta_buro_pfae} />}>
              <Route path="consulta_buro_pfae" element={<ConsultaBuroPfae />} />
            </Route>
          </React.Fragment>
        )}

        {application.tax_system_type === TAX_SYSTEM_TYPE.PM.code && (
          <React.Fragment>
            <Route
              path="/"
              element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.registro_datos_personales} />}
            >
              <Route path="registro_datos_personales" element={<RegistroDatosPersonales />} />
            </Route>

            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.validacion_telefono} />}>
              <Route path="validacion_telefono" element={<ValidacionTelefono />} />
            </Route>

            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.datos_empresa_pm} />}>
              <Route path="datos_empresa_pm" element={<DatosEmpresaPm />} />
            </Route>

            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.consulta_ciec} />}>
              <Route path="consulta_ciec" element={<ConsultaCiec />} />
            </Route>

            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.uso_de_credito} />}>
              <Route path="uso_de_credito" element={<UsoDeCredito />} />
            </Route>

            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.presencia_digital} />}>
              <Route path="presencia_digital" element={<PresenciaDigitalPm />} />
            </Route>

            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.datos_accionista} />}>
              <Route path="datos_accionista" element={<DatosAccionista />} />
            </Route>

            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.rfc_curp} />}>
              <Route path="rfc_curp" element={<RfcCurp />} />
            </Route>

            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.domicilio_accionista} />}>
              <Route path="domicilio_accionista" element={<DomicilioAccionista />} />
            </Route>

            <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_TOFU_BY_SCREEN_PM.consulta_buro} />}>
              <Route path="consulta_buro" element={<ConsultaBuro />} />
            </Route>
          </React.Fragment>
        )}

        <Route path="oferta" element={<Oferta />} />
        <Route path="en_revision" element={<ZonaGris />} />
        <Route path="waiting_room_revision" element={<WaitingRoom type="WaitingRoomRevision" />} />
        <Route path="en_revision_cc" element={<WaitingRoom type="WaitingRoomRevisionCC" />} />

        <React.Fragment>
          {application.tax_system_type === TAX_SYSTEM_TYPE.PFAE.code && (
            <React.Fragment>
              <Route path="informacion_documentos_pfae" element={<InfoDocumentosPFAE />} />
              <Route path="avance_expediente_digitalPFAE" element={<AvanceExpedienteDigitalPFAE />} />
              <Route path="solicitud_credito_1PFAE" element={<SolicitudCreditoPart1PFAE />} />
              <Route path="solicitud_credito_2PFAE" element={<SolicitudCreditoPart2PFAE />} />
              <Route path="solicitud_credito_3PFAE" element={<SolicitudCreditoPart3PFAE />} />
              <Route path="referencias_clientes_pfae" element={<ReferenciasClientesPfae />} />
              <Route path="referencias_proveedores_pfae" element={<ReferenciasProveedoresPfae />} />
              <Route path="info_bancaria_pfae" element={<InformacionBancariaPfae />} />
              <Route path="agenda_visita_pfae" element={<AgendaVisitaOcularPFAE />} />
              <Route path="validacion_identidad_pfae" element={<ValidarIndentidadPfae />} />
              <Route path="validando_expediente_pfae" element={<ValidandoExpedientePFAE isValidExpediente={true} />} />
              <Route path="errores_expediente_pfae" element={<ValidandoExpedientePFAE isValidExpediente={false} />} />
            </React.Fragment>
          )}

          {application.tax_system_type === TAX_SYSTEM_TYPE.PM.code && (
            <React.Fragment>
              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.check_list} />}>
                <Route path="check_list" element={<ListaRevision />} />
              </Route>

              {/* tab dictamen */}
              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.dictamen} />}>
                <Route path="docs_empresa" element={<DocsEmpresa />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.llenado_expediente} />}>
                {/* tab Empresa */}
                <Route path="avance_expediente_digital" element={<AvanceExpedienteDigital />} />
                <Route path="solicitud_credito_1" element={<SolicitudCreditoPart1 />} />
                <Route path="solicitud_credito_2" element={<SolicitudCreditoPart2 />} />
                <Route path="solicitud_credito_3" element={<SolicitudCreditoPart3 />} />
                <Route path="referencias_clientes" element={<ReferenciasClientes />} />
                <Route path="referencias_proveedores" element={<ReferenciasProveedores />} />
                <Route path="info_bancaria_empresa" element={<InformacionBancaria type="EMPRESA" />} />
                <Route path="validacion_representante_legal" element={<ValidacionIdentidad person="REP_LEGAL" />} />

                {/* tab Aval */}
                <Route path="docs_aval" element={<DocsAval />} />
                <Route path="informacion_datos_aval" element={<InformacionAval />} />
                <Route path="informacion_datos_aval_2" element={<InformacionAvalP2 />} />
                <Route path="info_bancaria_aval" element={<InformacionBancaria type="AVAL" />} />
                <Route path="validacion_identidad_aval" element={<ValidacionIdentidad person="AVAL" />} />

                {/* tab Propietario real */}
                <Route path="propietario_real_pm" element={<PropietarioRealPM />} />
                <Route path="datos_propietario_real_1" element={<DatosPropietarioRealPart1 />} />
                <Route path="datos_propietario_real_2" element={<DatosPropietarioRealPart2 />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.validando_expediente} />}>
                <Route path="validando_expediente_pm" element={<ValidandoExpediente />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.errores_expediente_pm} />}>
                <Route path="errores_expediente_pm" element={<ErrorExpediente />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.waiting_room} />}>
                <Route path="waiting_room" element={<WaitingRoom type="WaitingRoom" />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.completado} />}>
                <Route path="completado" element={<ExpedienteDigitalCompleto />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.agendar_visita} />}>
                <Route path="agenda_visita" element={<AgendaVisita />} />
                <Route path="agenda_visita_pm" element={<AgendaVisitaOcularPM />} />
                <Route path="confirmacion_domicilio" element={<ConfirmacionDomicilio />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.visita_agendada} />}>
                <Route path="confirmacion_visita_agendada" element={<ConfirmacionVisitaAgendada />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.visita_concluida} />}>
                <Route path="visita_ocular_concluida" element={<VisitaOcularConcluida />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.firma_documentos} />}>
                <Route path="firma_documentos" element={<FirmaDocumentosFisicos />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.bofu_completado} />}>
                <Route path="bofu_completado" element={<BofuCompletado />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.rechazado_onboarding} />}>
                <Route path="credito_rechazado" element={<Rechazo />} />
              </Route>

              <Route path="/" element={<ProtectByStatus allowedStatus={STATUS_BOFU_PM.rechazado_underwritting} />}>
                <Route path="rechazado_documentacion" element={<RechazoManual />} />
              </Route>
            </React.Fragment>
          )}
        </React.Fragment>

        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
