import React, { useEffect } from "react";
import { LenditRoute } from "./routes/LenditRoute";
import { useAppDispatch } from "@store/store";
import { setConfigName } from "@store/slices/registerSlice";

interface LenditAppProps {
  configName: string;
}

export const LenditApp: React.FC<LenditAppProps> = ({ configName }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setConfigName(configName));

    (() => {
      console.log(`[Lendia] App version: ${import.meta.env.APP_VERSION}`);
    })();
  }, []);

  return <LenditRoute></LenditRoute>;
};
