/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, IconButton, Modal, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "@store/store";
import CloseIcon from "@mui/icons-material/Close";
import tol from "@assets/img/tooltip.svg";
import { UUID } from "crypto";
import { usePatchPersonMutation } from "@api/personasApi";
import { usePostDocumentGenarateMutation } from "@api/documents";
import { TabStepBofuPm } from "@components/index";
import { PersonaStructure } from "@interfaces/index";
import { usePostValidateNextStageMutation } from "@api/applications";
import { InactivityHandler } from "@components/InactivityHandler";
import CustomButtonExpediente from "@components/CustomButtonExpediente";
import { DOCUMENTS_CATALOG, STATUS_CATALOG } from "@helpers/constantes";
import CustomUploadFileV2 from "@components/CustomUploadFileV2";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import { setSectionAvalDocument } from "@store/slices/bofuSlice";

const DocsAval = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));

  const shareholder = useSelector((state: RootState) => state.register.shareholder);
  const application = useSelector((state: RootState) => state.register.application);
  const clientDetails = useSelector((state: RootState) => state.register.personal_data);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const bufuLoading = useSelector((state: RootState) => state.bofu.isLoading);
  const sectionAval = useSelector((state: RootState) => state.bofu.section_aval);
  const personRepresentante = useSelector((state: RootState) => state.bofu.representante_legal);
  const personAval = useSelector((state: RootState) => state.bofu.aval);
  const personPropietariosReales = useSelector((state: RootState) => state.bofu.propietarios_reales);
  const sectionCompany = useSelector((state: RootState) => state.bofu.section_company);

  const [createDocument] = usePostDocumentGenarateMutation();
  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [patchPerson] = usePatchPersonMutation();

  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showIdentityValidation, setShowIdentityValidation] = useState<boolean>(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const generateAnexo1 = async (aval_selected: PersonaStructure) => {
    try {
      const { doc_anexo_1 } = sectionAval ?? {};
      const all_forms_completed =
        personAval?.information_person_completed && personAval.bank_references_completed ? true : false;

      // descartar si no se han completado todos los formularios
      if (!all_forms_completed) {
        return;
      }

      // descartar si ya se ha validado
      if (doc_anexo_1?.validated === null || doc_anexo_1?.validated === true) {
        return;
      }

      const payload_anexo_1 = {
        loan_application_id: application.id,
        document_type: "anexo_1",
        person_id: aval_selected.id,
      };

      await createDocument(payload_anexo_1);

      const payload_person = {
        completed: true,
      };

      await patchPerson({
        personId: shareholder.id,
        body: payload_person,
      }).unwrap();
    } catch (error) {
      console.error("Ha ocurrido un error al generar el anexo 1", error);
    }
  };

  const validateCompany = () => {
    const company_forms =
      application?.bank_information_completed &&
      application?.references_completed &&
      application?.loan_application_completed &&
      application?.identity_validation_completed
        ? true
        : false;
    if (!company_forms) {
      return false;
    }

    if (
      sectionCompany?.doc_comprobante_domicilio?.validated === undefined ||
      sectionCompany?.doc_comprobante_domicilio?.validated === false
    ) {
      return false;
    }

    return true;
  };

  const validateAval = () => {
    const aval_forms =
      personAval?.information_person_completed &&
      personAval?.identity_validation_completed &&
      personAval?.bank_references_completed
        ? true
        : false;

    if (!aval_forms) {
      return false;
    }

    if (
      sectionAval?.doc_constancia_sf?.validated === undefined ||
      sectionAval?.doc_constancia_sf?.validated === false
    ) {
      return false;
    }

    if (
      sectionAval.doc_comprobante_domicilio?.validated === undefined ||
      sectionAval.doc_comprobante_domicilio?.validated === false
    ) {
      return false;
    }

    personAval?.information_person_completed;
    personAval?.bank_references_completed;
    if (personAval?.information_person_completed !== true) {
      return false;
    }

    if (personAval?.bank_references_completed !== true) {
      return false;
    }

    if (personAval.bank_references_completed !== true) {
      return false;
    }

    return true;
  };

  const handleClickContinue = async () => {
    try {
      setIsLoading(true);

      if (personPropietariosReales.length) {
        navigate("/producto/creditosimple/propietario_real_pm");
      } else {
        if (!validateCompany() || !validateAval()) {
          return;
        }

        const { code: next_status, url: next_url } = STATUS_CATALOG.validando_expediente_pm;

        await triggerPostNextStage({
          applicationId: application.id,
          statusCode: next_status,
          username: `BOFU - ${accountUser.email}`,
        }).unwrap();
        navigate(next_url);
      }
    } catch (error) {
      dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!personAval || !personRepresentante) {
        navigate("/producto/creditosimple/docs_empresa");
        return;
      }

      const is_same_person = personAval?.id === personRepresentante.id;

      generateAnexo1(personAval);
      setShowIdentityValidation(is_same_person ? false : true);
    })();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} md={8}>
          <InactivityHandler />
          <Box textAlign={"center"} mx={4}>
            <Typography mt={4} mb={3} fontSize={"20px"} fontWeight={600} color={theme?.palette?.primary?.dark}>
              Expediente digital
            </Typography>

            <TabStepBofuPm />

            <Typography
              mt={4}
              variant={isMobileOrTablet ? "body2" : "body1"}
              fontWeight={700}
              color={theme?.palette?.primary?.dark}
            >
              Ahora es momento de llenar los formatos relacionados al aval <img src={tol} alt="" onClick={handleOpen} />
            </Typography>

            {bufuLoading ? (
              <Box mt={3}>
                <Skeleton variant="rectangular" height={100} sx={{ borderRadius: 2 }} />
              </Box>
            ) : (
              <Box>
                <Typography mt={2} variant={isMobileOrTablet ? "body2" : "body1"} color={theme?.palette?.primary?.main}>
                  {`${personAval?.name || ""} ${personAval?.last_name || ""} ${personAval?.last_name_2 || ""}`}
                </Typography>

                <Box
                  mt={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  color={theme?.palette?.primary?.dark}
                >
                  <Typography variant={isMobileOrTablet ? "body2" : "body1"} sx={{ flex: 1, textAlign: "left" }}>
                    Información del aval
                  </Typography>

                  <CustomButtonExpediente
                    onClick={() => navigate("/producto/creditosimple/informacion_datos_aval")}
                    formStatus={personAval?.information_person_completed}
                    documentStatus={sectionAval?.doc_anexo_1?.validated}
                  />
                </Box>

                {showIdentityValidation && (
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography
                      variant={isMobileOrTablet ? "body2" : "body1"}
                      sx={{ flex: 1, textAlign: "left" }}
                      color={theme?.palette?.primary?.dark}
                    >
                      Validación de identidad
                    </Typography>

                    <CustomButtonExpediente
                      onClick={() => navigate("/producto/creditosimple/validacion_identidad_aval")}
                      formStatus={personAval?.identity_validation_completed}
                      documentStatus={sectionAval?.doc_validacion_identidad?.validated}
                      type="validation"
                    />
                  </Box>
                )}

                <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                  <Typography
                    variant={isMobileOrTablet ? "body2" : "body1"}
                    sx={{ flex: 1, textAlign: "left" }}
                    color={theme?.palette?.primary?.dark}
                  >
                    Información bancaria
                  </Typography>

                  <CustomButtonExpediente
                    onClick={() => navigate("/producto/creditosimple/info_bancaria_aval")}
                    formStatus={personAval?.bank_references_completed}
                    documentStatus={sectionAval?.doc_anexo_1?.validated}
                  />
                </Box>

                <Typography
                  variant={isMobileOrTablet ? "body2" : "body1"}
                  fontWeight={600}
                  color={theme?.palette?.primary?.dark}
                  align="center"
                  mt={5}
                  mb={4}
                >
                  Ahora carga los documentos relacionados al aval
                </Typography>

                <CustomUploadFileV2
                  mt={2}
                  inputName="constancia_situacion_fiscal"
                  uploadedFile={sectionAval?.doc_constancia_sf}
                  setUploadedFile={(newUpload) => {
                    dispatch(
                      setSectionAvalDocument({
                        name: "doc_constancia_sf",
                        file: newUpload,
                      }),
                    );
                  }}
                  metadata={{
                    fileCode: DOCUMENTS_CATALOG.constancia_situacion_fiscal.code,
                    uploadType: "person",
                    personId: personAval?.id as UUID,
                    clientDetailsId: clientDetails.id,
                    personType: "accionista_mayoritario",
                  }}
                  disabled={isLoading}
                  required
                />

                <CustomUploadFileV2
                  mt={2}
                  inputName="comprobante_domicilio"
                  uploadedFile={sectionAval?.doc_comprobante_domicilio}
                  setUploadedFile={(newUpload) => {
                    dispatch(
                      setSectionAvalDocument({
                        name: "doc_comprobante_domicilio",
                        file: newUpload,
                      }),
                    );
                  }}
                  optionalDescription="No deberá ser mayor a 3 meses"
                  metadata={{
                    fileCode: DOCUMENTS_CATALOG.comprobante_domicilio.code,
                    uploadType: "person",
                    personId: personAval?.id as UUID,
                    clientDetailsId: clientDetails.id,
                    personType: "accionista_mayoritario",
                  }}
                  disabled={isLoading}
                  required
                />

                <Box display="flex" justifyContent="center" mt={2} mb={4}>
                  <Button
                    sx={{ width: "130px" }}
                    type="button"
                    variant="default"
                    disabled={
                      isLoading ||
                      personAval?.information_person_completed !== true ||
                      personAval.bank_references_completed !== true ||
                      (showIdentityValidation === true && personAval?.identity_validation_completed !== true)
                    }
                    onClick={handleClickContinue}
                  >
                    Continuar
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
        {!isMobileOrTablet && (
          <Grid item xs={12} md={4} textAlign={"center"}>
            <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.light }}>
              <CustomRightImage imageName="img_bofu_9" />
            </Box>
          </Grid>
        )}
      </Grid>

      <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "250px", sm: "300px", md: "60vw", lg: "40vw" },
            maxWidth: 500,
            bgcolor: "background.paper",
            border: "2px solid #002652",
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
            textAlign: "center",
          }}
        >
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: 10,
              right: 10,
              color: "#76A1FF",
            }}
          >
            <CloseIcon />
          </IconButton>

          <Typography
            id="modal-title"
            variant="h6"
            component="h2"
            fontWeight={600}
            color={theme?.palette?.primary?.dark}
          >
            Aval
          </Typography>

          <Typography id="modal-description" sx={{ mt: 2, color: theme?.palette?.primary?.dark }}>
            Es el accionista mayoritario de la empresa, que a su vez, será la persona encargada de asegurar que se
            saldará la deuda.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default DocsAval;
