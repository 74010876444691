/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  useMediaQuery,
  useTheme,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography,
  Button,
  Skeleton,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch } from "@store/store";
import { useLazyGetLoanUseQuery } from "@api/catalogs";
import {
  useLazyGetLoanCreditUseQuery,
  useLazyPostLoanUseQuery,
  usePostValidateNextStageMutation,
} from "@api/applications";
import { ProgressBar } from "@components/ProgressBar";
import { setModalErrorVisible } from "@store/slices/appSlice";
import { InactivityHandler } from "@components/InactivityHandler";
import { setLoanStatus } from "@store/slices/registerSlice";
import { STATUS_CATALOG, TAX_SYSTEM_TYPE } from "@helpers/constantes";
import { ApiError } from "@interfaces/response";
import CustomRightImage from "@components/CustomRightImage";
import * as Sentry from "@sentry/react";

const UsoDeCredito = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useAppDispatch();

  const [listCreditUse, setListCreditUse] = useState<any>([]);
  const [idOtherUse, setIdOtherUse] = useState<string>("");

  const [triggerPostNextStage] = usePostValidateNextStageMutation();
  const [getLoanCreditUse] = useLazyGetLoanCreditUseQuery();
  const [triggerSaveUseSelected] = useLazyPostLoanUseQuery();
  const [getListCreditUse] = useLazyGetLoanUseQuery();

  const applicationData = useSelector((state: RootState) => state.register.application);
  const accountUser = useSelector((state: RootState) => state.register.account_user);

  const formik = useFormik({
    initialValues: {
      uses: [] as string[],
      reason: "",
    },
    validationSchema: yup.object().shape({
      uses: yup.array().min(1, "").required(""),
      reason: yup.string().when("uses", (values) => {
        const is_other_use = values[0].includes(idOtherUse);
        if (is_other_use) {
          return yup.string().trim().min(5, "La razón debe tener al menos 5 caracteres").required("Campo requerido");
        } else {
          return yup.string().notRequired();
        }
      }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      if (formik.isValid) {
        try {
          setSubmitting(true);

          const resources = values.uses.map((id) => {
            return {
              resources_use_id: id,
              reason: id === idOtherUse ? values.reason : "",
            };
          });

          await triggerSaveUseSelected({
            loanId: applicationData.id,
            body: {
              resource_uses: resources,
            },
          }).unwrap();

          const isPFAE = applicationData.tax_system_type === TAX_SYSTEM_TYPE.PFAE.code;
          const next_status = isPFAE
            ? STATUS_CATALOG.presencia_digital_pfae.code
            : STATUS_CATALOG.presencia_digital.code;
          const next_url = isPFAE ? STATUS_CATALOG.presencia_digital_pfae.url : STATUS_CATALOG.presencia_digital.url;

          await triggerPostNextStage({
            applicationId: applicationData.id,
            statusCode: next_status,
            username: `TOFU - ${accountUser.email}`,
          }).unwrap();

          dispatch(setLoanStatus(next_status));

          navigate(next_url);
        } catch (error) {
          Sentry.captureException(error, {
            extra: {
              component: "UsoDeCredito",
              values: values,
            },
          });
          dispatch(setModalErrorVisible({ open: true, error: error as ApiError }));
        } finally {
          setSubmitting(false);
        }
      }
    },
    validateOnMount: true,
  });

  useEffect(() => {
    const fetchData = async () => {
      const [list_credit_use, loan_credit_use] = await Promise.all([
        getListCreditUse().unwrap(),
        getLoanCreditUse(applicationData.id).unwrap(),
      ]);
      const id_other = list_credit_use.find((item: any) => item.other)?.id;
      setListCreditUse(list_credit_use);
      setIdOtherUse(id_other ?? "");

      if (loan_credit_use.length) {
        const other_use = loan_credit_use.find((item: any) => item.resources_use_id === id_other);
        const uses_selected = loan_credit_use.map((item: any) => item.resources_use_id);
        formik.setFieldValue("uses", uses_selected);
        formik.setFieldValue("reason", other_use?.reason ?? "");

        setTimeout(() => {
          formik.validateForm();
        }, 200);
      }
    };

    fetchData();
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} md={8} mt={4}>
        <InactivityHandler />
        <Box
          sx={{
            mt: isMobileOrTablet ? 2 : 4,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant={isMobileOrTablet ? "h4" : "h3"}
            fontWeight={500}
            textAlign="center"
            color={theme?.palette?.primary?.dark}
          >
            Selección de uso de crédito
          </Typography>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <ProgressBar progress={applicationData.tax_system_type === TAX_SYSTEM_TYPE.PM.code ? 5 : 6} />
          </Container>

          <Container maxWidth="sm" sx={{ mt: isMobileOrTablet ? 2 : 3 }}>
            <form onSubmit={formik.handleSubmit} noValidate>
              {listCreditUse.length > 0
                ? listCreditUse.map((item: any, index: number) => {
                    return (
                      <Box mt={1} key={index}>
                        <FormControlLabel
                          sx={{ color: theme?.palette?.primary?.dark }}
                          control={
                            <Checkbox
                              id={item.name.replace(" ", "_").toLowerCase()}
                              name="uses"
                              value={item.id}
                              checked={formik.values.uses.includes(
                                item.id as `${string}-${string}-${string}-${string}-${string}`,
                              )}
                              onChange={formik.handleChange}
                              disabled={formik.isSubmitting}
                            />
                          }
                          label={item.name}
                        />
                        {item.id === idOtherUse && formik.values.uses.includes(item.id) && (
                          <TextField
                            id="reason"
                            name="reason"
                            label="Especifica otro"
                            margin="normal"
                            required
                            fullWidth
                            autoComplete="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.reason}
                            error={formik.touched.reason && Boolean(formik.errors.reason)}
                            helperText={formik.touched.reason && formik.errors.reason}
                            disabled={formik.isSubmitting}
                            inputProps={{ maxLength: 150 }}
                          />
                        )}
                      </Box>
                    );
                  })
                : [...Array(6).keys()].map((_, index) => (
                    <Box mt={2} key={index} py={1}>
                      <Skeleton variant="text" animation={"wave"} />
                    </Box>
                  ))}

              <Box display={"flex"} justifyContent={"center"} mt={isMobileOrTablet ? 2 : 3} mb={2}>
                <Button type="submit" variant="default" disabled={!formik.isValid || formik.isSubmitting}>
                  Continuar
                </Button>
              </Box>
            </form>
          </Container>
        </Box>
      </Grid>

      {!isMobileOrTablet && (
        <Grid item xs={12} md={4} textAlign={"center"}>
          <Box sx={{ width: "100%", height: "100%", backgroundColor: theme?.palette?.primary?.dark }}>
            <CustomRightImage imageName="img_tofu_7" />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default UsoDeCredito;
